import React, { useRef, useState } from "react";
import { Form, Input, Button, Alert, Row } from "antd";

// Icons
import { UserOutlined, LockOutlined, KeyOutlined } from "@ant-design/icons";

// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";
import Title from "antd/es/typography/Title";

function errorMessage(errorCode) {
  switch (errorCode) {
    case "auth/too-many-requests":
      return 'Le compte à été temporairement bloqué suite à de nombreux essais erronés. Veuillez réessayer ultérieurement ou ré-initialisez votre mot de passe via la page "Mot de passe oublié ?"';
    case "auth/user-disabled":
      return "Ce compte à été désactivé. Veuillez contactez votre administrateur afin de regagner l'accès à votre compte.";
    default:
      return "Email ou mot de passe incorrect";
  }
}

export default function () {
  const [userNotFound, setUserNotFound] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [validationCode, setValidationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [resolver, setResolver] = useState(null);

  const ref = useRef();

  const [step, setStep] = useState("login"); // login |  reset-password | verify-code

  const onFinish = (values) => {
    setUserNotFound(null);
    firebase
      .auth()
      .signInWithEmailAndPassword(values.email, values.password)
      .then((user) => {
        console.log("Signed in!", user);
        // dispatch({ type: 'LOGIN_USER', payload: user });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;

        console.log(errorCode, error.message);
        if (error.code === "auth/multi-factor-auth-required") {
          let recaptchaVerifier = new firebase.auth.RecaptchaVerifier(ref.current, {
            size: "invisible",
            "error-callback": () => {
              recaptchaVerifier = new firebase.auth.RecaptchaVerifier(ref.current);
            },
          });
          const resolver = error.resolver;
          setResolver(resolver);
          // Ask user which second factor to use.

          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          };
          const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          // Send SMS verification code
          return phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (verificationId) {
              setVerificationId(verificationId);
              setStep("verify-code");
              // Ask user for the SMS verification code.
            })
            .then(function (userCredential) {
              // User successfully signed in with the second factor phone number.
            });
        } else {
          setUserNotFound(errorCode);
        }
      });
  };

  const verifyCode = (values) => {
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, values.validationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    return resolver.resolveSignIn(multiFactorAssertion);
  };

  const resetPassword = (values) => {
    const reset = firebase.app().functions("europe-west1").httpsCallable("resetPassword");

    reset(values)
      .then((r) => {
        setResetEmailSent(true);
        console.log(r);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  console.log({ resolver });

  return (
    <>
      <div id="login_container">
        <div id="logo" />
        <Alert
          showIcon
          type="error"
          message={errorMessage(userNotFound)}
          style={{ marginBottom: 16, display: userNotFound ? "block" : "none" }}
        />
        {step === "reset-password" && (
          <Form
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={resetPassword}
          >
            <h3>Mot de passe oublié</h3>
            {resetEmailSent ? (
              <Alert
                message="Un email permettant de réinitialiser votre mot de passe va vous être envoyé sous peu. Pensez à vérifier vos courriers indésirables."
                type="info"
                showIcon
                style={{ marginBottom: 16 }}
              />
            ) : (
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Veuillez entrer un email" },
                  { type: "email", message: "Veuillez entrer un email valide" },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                />
              </Form.Item>
            )}

            <Form.Item style={{ margin: "16px 0 8px" }}>
              <Button
                type="secondary"
                className="login-form-button"
                style={{ marginRight: 8 }}
                onClick={(e) => {
                  e.preventDefault();
                  setStep("login");
                  setResetEmailSent(false);
                }}
              >
                Retour
              </Button>
              {!resetEmailSent && (
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Réinitialiser
                </Button>
              )}
            </Form.Item>
          </Form>
        )}
        {step === "login" && (
          <Form
            name="login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Veuillez entrer un email" },
                { type: "email", message: "Veuillez entrer un email valide" },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "Veuillez entrer un mot de passe" }]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Mot de passe"
              />
            </Form.Item>

            <Form.Item style={{ margin: "16px 0 8px" }}>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Se connecter
              </Button>
              <Button
                type="link"
                onClick={() => {
                  setStep("reset-password");
                }}
              >
                Mot de passé oublié ?
              </Button>
            </Form.Item>

            <div ref={ref} />
          </Form>
        )}
        {step === "verify-code" && (
          <Form onFinish={verifyCode}>
            <Title level={2} style={{ opacity: 0.65, textAlign: "center" }}>
              Solidoc Authenticator
            </Title>
            <Row>
              Entrez le code de vérification envoyé par SMS au numéro{" "}
              {resolver.hints[0].phoneNumber.replace("+*", "")}.
            </Row>
            <Form.Item
              name="validationCode"
              rules={[{ required: true, message: "Veuillez entrer un code" }]}
            >
              <Input placeholder="eg: 123456" />
            </Form.Item>

            <Form.Item style={{ margin: "16px 0 8px" }}>
              <Button type="primary" htmlType="submit" className="login-form-button">
                Se connecter
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </>
  );
}
