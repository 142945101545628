import React, { useEffect, useMemo, useState } from "react";
import { Table, Space, Button, Tag, Col, Row, Input, Switch as InputSwitch } from "antd";

import { Switch, Route, useParams, useHistory } from "react-router-dom";

// React-Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// Icons
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

// Firebase
import * as firebase from "firebase/app";
import "firebase/firestore";

// Lodash & Moment
import _ from "lodash";
import moment from "moment-timezone";

import DetailsComponent from "./Details";
import RecipientsTable from "./RecipientsTable";

const { Search } = Input;

function sorterFn(property) {
  return (a, b) => `${a[property]}`.toLowerCase().localeCompare(`${b[property]}`.toLowerCase());
}

export default function () {
  const [loadingTable, setLoadingTable] = useState(true);
  const [columns, setColumns] = useState([]);
  const [searchTerms, setSearchTerms] = useState("");
  const [displayedMessages, setDisplayedMessages] = useState([]);

  const { type: paramsType } = useParams();
  const type = useMemo(() => {
    switch (paramsType) {
      case "lettres-simples":
        return "lettres";
      case "lettres-recommandees":
        return "lettres";
      default:
        return paramsType;
    }
  }, [paramsType]);

  const user = useSelector((state) => state.user, shallowEqual);
  const messages = useSelector((state) => state.messagesHistory, shallowEqual);

  const dispatch = useDispatch();
  const history = useHistory();

  const db = firebase.firestore();

  const [showTeamMessage, setShowTeamMessages] = useState(
    user.is_account_admin || user.is_company_admin
  );

  useEffect(() => {
    if (searchTerms === "") {
      setDisplayedMessages([...messages]);
    } else {
      setDisplayedMessages(
        _.filter(
          messages,
          (message) =>
            (message.ref1 && message.ref1.indexOf(searchTerms) !== -1) ||
            (message.ref2 && message.ref2.indexOf(searchTerms) !== -1)
        )
      );
    }
  }, [searchTerms, messages]);

  function fetchMessages() {
    setLoadingTable(true);

    db.collectionGroup("recipients")
      .where("company", "==", user.company)
      .get()
      .then((recipientsSnapshot) => {
        const recipientsObj = {};
        if (recipientsSnapshot.docs.length) {
          recipientsSnapshot.docs.forEach((recipientDoc) => {
            const recipient = recipientDoc.data();
            if (recipientsObj[recipient.message.id]) {
              recipientsObj[recipient.message.id].push(recipient);
            } else {
              recipientsObj[recipient.message.id] = [recipient];
            }
          });

          let documentType = paramsType;
          if (paramsType === "lettres-simples") {
            documentType = "simple";
          }
          if (paramsType === "lettres-recommandees") {
            documentType = "recommande";
          }

          let query = user.company.collection("messages").where("type", "==", documentType);

          if (!showTeamMessage) {
            query = query.where("user", "==", user.userRef);
          }

          query.get().then((snapshot) => {
            const results = snapshot.docs.map((message) => ({
              ...message.data(),
              key: message.id,
              recipients: recipientsObj[message.id],
            }));

            dispatch({
              type: "FETCH_MESSAGES_HISTORY",
              payload: results,
            });

            setLoadingTable(false);
          });
        } else {
          setLoadingTable(false);
        }
      });
  }

  const defaultColumns = [
    {
      title: "Objet",
      dataIndex: "subject",
    },
    {
      title: "Ref 1",
      dataIndex: "ref1",
      sorter: sorterFn("ref1"),
    },
    {
      title: "Ref 2",
      dataIndex: "ref2",
      sorter: sorterFn("ref2"),
    },
    {
      title: "Pièces jointes",
      dataIndex: "attachments",
      render: (attachment) => <div>{attachment.length}</div>,
    },
    {
      title: "Détails",
      key: "action",
      render: (text, record) => (
        <Space size="small">
          <Button
            onClick={() => {
              history.push(`/historique/${type}/${record.key}`);
            }}
            type="primary"
            title="Details"
            size="small"
            shape="circle"
            icon={<InfoCircleOutlined />}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    dispatch({ type: "CLEAR_MESSAGES_HISTORY" });
    setColumns(
      type === "electronique" // TODO
        ? [
            {
              title: "Date de dépôt",
              dataIndex: "date",
              sorter: sorterFn("date"),
              render: (date) =>
                moment(date.toDate()).tz("Europe/Paris").format("DD/MM/YYYY à HH:mm"),
              sortOrder: "descend",
            },
            ...defaultColumns,
          ]
        : [
            {
              title: "Date de dépôt",
              dataIndex: "date",
              sorter: sorterFn("date"),
              render: (date) =>
                moment(date.toDate()).tz("Europe/Paris").format("DD/MM/YYYY à HH:mm"),

              sortOrder: "descend",
            },
            {
              title: "Date d'envoi",
              dataIndex: "recipients",
              render: (recipients) =>
                recipients && recipients[0] && recipients[0].send_date
                  ? moment(recipients[0].send_date.toDate()).format("DD/MM/YYYY")
                  : "",
              sortOrder: "descend",
            },
            {
              title: "Ref 1",
              dataIndex: "ref1",
              sorter: sorterFn("ref1"),
            },
            {
              title: "Ref 2",
              dataIndex: "ref2",
              sorter: sorterFn("ref2"),
            },
            {
              title: "Type",
              dataIndex: "type",
              render: (t, o) => (t === "simple" ? "Lettre simple" : `Recommandé ${o.level}`),
            },
            {
              title: "Destinataires",
              dataIndex: "recipients_type",
              render: (recipientsType) => (recipientsType === "multiple" ? "Multiples" : "Unique"),
            },
            {
              title: "Avec accusé de réception",
              dataIndex: "receipt_aknowledge",
              render: (receipt, o) => (
                <div>{o.type === "simple" ? "-" : receipt === "yes" ? "Oui" : "Non"}</div>
              ),
            },
            {
              title: "Pièces jointes",
              dataIndex: "attachments",
              render: (attachment) => <div>{attachment.length}</div>,
            },
            {
              title: "Status",
              dataIndex: "recipients",
              render: (recipients) => {
                switch (recipients[0].status) {
                  case "sent":
                    return (
                      <Tag icon={<CheckCircleOutlined />} color="cyan">
                        Envoyé
                      </Tag>
                    );
                  case "error_address":
                    return (
                      <Tag icon={<CloseCircleOutlined />} color="red">
                        Erreur d'adressage
                      </Tag>
                    );

                  case "error_file":
                    return (
                      <Tag icon={<CloseCircleOutlined />} color="red">
                        Erreur fichier
                      </Tag>
                    );
                  case "error_print":
                    return (
                      <Tag icon={<CloseCircleOutlined />} color="red">
                        Erreur d'impression
                      </Tag>
                    );
                  case "error_print_param":
                    return (
                      <Tag icon={<CloseCircleOutlined />} color="red">
                        Erreur paramètres d’impression
                      </Tag>
                    );
                  case "prepare":
                  default:
                    return (
                      <Tag icon={<SyncOutlined />} color="processing">
                        En préparation
                      </Tag>
                    );
                }
              },
            },
            {
              title: "Détails",
              key: "action",
              render: (text, record) => (
                <Space size="small">
                  <Button
                    onClick={() => {
                      history.push(`/historique/${type}/${record.key}`);
                    }}
                    type="primary"
                    title="Details"
                    size="small"
                    shape="circle"
                    icon={<InfoCircleOutlined />}
                  />
                </Space>
              ),
            },
          ]
    );
    fetchMessages();
  }, [paramsType, showTeamMessage]);
  console.log({ displayedMessages });
  return (
    <>
      <Switch>
        <Route path="/historique/:type/:id">
          <DetailsComponent />
        </Route>
        <Route>
          <h1>Vos envois {type === "electronique" ? "électroniques" : "papiers"}</h1>

          <Row>
            <Col span={24} style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <Search
                  placeholder="Rechercher par référence"
                  onSearch={(value) => {
                    setSearchTerms(value);
                  }}
                  onChange={(e) => {
                    if (e.target.value === "") setSearchTerms("");
                  }}
                  style={{ paddingRight: 32, maxWidth: 500 }}
                  enterButton
                  allowClear
                />
              </div>
              {(user.is_account_admin || user.is_company_admin) && (
                <div>
                  <InputSwitch
                    checked={showTeamMessage}
                    onChange={setShowTeamMessages}
                    style={{ marginRight: ".5rem" }}
                  />
                  <span>Voir les dépôts de mon équipe</span>
                </div>
              )}
            </Col>
          </Row>

          <Table
            bordered
            style={{ marginTop: 16 }}
            columns={columns}
            dataSource={displayedMessages}
            size="small"
            scroll={{ x: true }}
            loading={loadingTable}
            pagination={{
              pageSize: 30,
              position: ["bottomCenter"],
            }}
            expandable={{
              expandedRowRender: type === "electronique" && RecipientsTable,
              expandIcon: ({ expanded, onExpand, record }) =>
                type === "electronique" &&
                record.recipients && (
                  <Button
                    size="small"
                    style={{ fontSize: 12, fontWeight: 600 }}
                    onClick={(e) => onExpand(record, e)}
                  >
                    {record.recipients.length} Destinataire{record.recipients.length > 1 && "s"}
                  </Button>
                ),
            }}
          />
        </Route>
      </Switch>
    </>
  );
}
