import React, { useCallback, useMemo, useRef, useState } from "react";
import * as firebase from "firebase";
import { Button, Input, Modal, notification, Row } from "antd";
import Title from "antd/es/typography/Title";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

function Activate2FAModal({ visible, onCancel: onCancelProp, onEnrollment }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validationCode, setValidationCode] = useState("");
  const [userProvidedPassword, setUserProvidedPassword] = useState("");
  const [step, setStep] = useState("phone"); // phone | validation | reauth
  const [verificationId, setVerificationId] = useState(""); //  to confirm enrollment
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user, shallowEqual);

  const db = firebase.firestore();

  const reset = useCallback(() => {
    setPhoneNumber("");
    setValidationCode("");
    setUserProvidedPassword("");
    setStep("phone");
    setVerificationId("");
  }, []);

  const onCancel = useCallback(() => {
    onCancelProp();
    reset();
  }, [onCancelProp, reset]);

  const isPhoneNumberValid = useMemo(() => {
    return phoneNumber.length === 10;
  }, [phoneNumber]);
  const ref = useRef();

  const sendSMSValidationCode = useCallback(() => {
    let recaptchaVerifier = new firebase.auth.RecaptchaVerifier(ref.current, {
      size: "invisible",
      "error-callback": () => {
        console.log("error");
      },
    });
    firebase
      .auth()
      .currentUser.multiFactor.getSession()
      .then((multiFactorSession) => {
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: `+33${phoneNumber}`,
          session: multiFactorSession,
        };

        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        // Send SMS verification code.
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
      })

      .then((vId) => {
        setVerificationId(vId);
        setStep("validation");
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          // ask user to reauth
          setStep("reauth");
        }
      });
  }, [ref, phoneNumber]);

  const confirmReAuth = useCallback(() => {
    const user = firebase.auth().currentUser;

    // Now you can use that to reauthenticate
    user
      .reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(user.email, userProvidedPassword)
      )
      .then(() => firebase.auth().currentUser.multiFactor.getSession())
      .then((multiFactorSession) => {
        let recaptchaVerifier = new firebase.auth.RecaptchaVerifier(ref.current, {
          size: "invisible",
          "error-callback": () => {
            console.log("error");
          },
        });

        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: `+33${phoneNumber}`,
          session: multiFactorSession,
        };
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        // Send SMS verification code.
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
      })

      .then((vId) => {
        setVerificationId(vId);

        setStep("validation");
      })
      .catch((err) => {
        console.error(err);
      });
  }, [userProvidedPassword, phoneNumber, ref]);

  const confirmEnrollment = useCallback(() => {
    // Ask user for the verification code.
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, validationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete enrollment.
    return firebase
      .auth()
      .currentUser.multiFactor.enroll(multiFactorAssertion)
      .then(() => {
        db.collection("users").doc(firebase.auth().currentUser.uid).update({
          has2FAActivated: true,
          phone2Fa: phoneNumber,
        });
      })
      .then(() => {
        notification.success({
          message: "Solidoc Authenticator activé!",
        });
        const data = {
          type: "LOGIN_USER",
          payload: {
            ...currentUser,
            has2FAActivated: true,
          },
        };
        dispatch(data);

        onEnrollment();
      });
  }, [validationCode, verificationId, currentUser]);

  const footer = useMemo(() => {
    if (step === "phone") {
      return [
        <Button key="cancel" onClick={onCancel}>
          Annuler
        </Button>,
        <Button
          key="code"
          disabled={!isPhoneNumberValid}
          type="primary"
          onClick={sendSMSValidationCode}
        >
          Envoyer code
        </Button>,
      ];
    }
    if (step === "reauth") {
      return [
        <Button key="cancel" onClick={onCancel}>
          Annuler
        </Button>,
        <Button key="code" type="primary" onClick={confirmReAuth}>
          Valider
        </Button>,
      ];
    }
    if (step === "validation") {
      return [
        <Button key="cancel" onClick={onCancel}>
          Annuler
        </Button>,
        <Button key="code" type="primary" onClick={confirmEnrollment} disabled={!validationCode}>
          Valider
        </Button>,
      ];
    }
    return [];
  }, [step, isPhoneNumberValid, onCancel, confirmReAuth, confirmEnrollment, validationCode]);

  return (
    <Modal visible={visible} onCancel={onCancel} title="Solidoc Authenticator" footer={footer}>
      {step === "phone" && (
        <>
          <Title level={5}>Etape 1: Entrez votre numéro de téléphone (06/07 xx xx xx xx)</Title>
          <Row>
            <Input
              style={{
                width: "17rem",
              }}
              min={0}
              max={999999999}
              autoFocus={true}
              controls={false}
              value={phoneNumber}
              placeholder="eg: 0612345678"
              onInput={(v) => {
                setPhoneNumber(v.target.value.replace(/\D/g, ""));
              }}
            />
          </Row>

          <div ref={ref} />
        </>
      )}
      {step === "validation" && (
        <>
          <Title level={5}>Etape 2: Entrez le code de vérification envoyé par SMS.</Title>
          <Row>
            <Input
              style={{
                width: "17rem",
              }}
              addonBefore="Code"
              autoFocus={true}
              value={validationCode}
              placeholder="Ex: 123456"
              onInput={(e) => setValidationCode(e.target.value)}
            />
          </Row>

          <div ref={ref} />
        </>
      )}
      {step === "reauth" && (
        <>
          <Title level={5}>Entrez votre mot de passe</Title>
          <Row>Pour des mesures de sécurité, merci de vous identifier à nouveau</Row>
          <Row>
            <Input
              disabled={true}
              style={{
                width: "17rem",
              }}
              placeholder="Email"
              value={firebase.auth().currentUser.email}
            />
          </Row>
          <Row>
            <Input
              style={{
                width: "17rem",
              }}
              placeholder="Mot de passe"
              type="password"
              autoFocus={true}
              value={userProvidedPassword}
              onInput={(e) => setUserProvidedPassword(e.target.value)}
            />
          </Row>

          <div ref={ref} />
        </>
      )}
    </Modal>
  );
}

export default Activate2FAModal;
