import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import Contacts from './Contacts';
import Lists from './Lists';

export default function () {
  return (
    <Switch>
      <Route exact path="/carnet/contacts">
        <Contacts />
      </Route>
      <Route exact path="/carnet/listes">
        <Lists />
      </Route>
    </Switch>
  );
}
