import React, { useEffect } from "react";
import { Steps, Button, Result } from "antd";
import { useSelector, shallowEqual } from "react-redux";
import { useRouteMatch, useHistory, Redirect } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

import { ClockCircleOutlined } from "@ant-design/icons";

import Configuration from "./Steps/Configuration";
import Recipients from "./Steps/Recipients";
import Attachments from "./Steps/Attachments";
import Summary from "./Steps/Summary";

const STEPS = {
  configuration: {
    key: 0,
    content: (config) => <Configuration config={config} />,
  },
  recipients: {
    key: 1,
    content: (config) => <Recipients config={config} />,
  },
  attachments: {
    key: 2,
    content: (config) => <Attachments config={config} />,
  },
  summary: {
    key: 3,
    content: (config) => <Summary config={config} />,
  },
  success: {
    key: 4,
    content: (config, formValues, data, history) => (
      <Result
        status="success"
        icon={data[1] ? <ClockCircleOutlined /> : null}
        title={
          config.printed
            ? data[1]
              ? `${
                  config.type === "simple" ? "Lettre programmée" : "Recommandé programmé"
                } avec succés !`
              : "Commande confirmée !"
            : data[1]
            ? "Recommandé programmé avec succés !"
            : "Recommandé envoyé !"
        }
        subTitle={
          config.printed
            ? data[1]
              ? `${
                  config.type === "simple"
                    ? `Lettre N°${data[0].toUpperCase()} programmée`
                    : `Recommandé N°${data[0].toUpperCase()} programmé`
                } au ${moment(data[1]).format("dddd DD MMMM YYYY")}`
              : config.type === "simple"
              ? `Votre lettre N°${data[0].toUpperCase()} sera préparée et envoyée dans les plus brefs délais`
              : `Votre recommandé N°${data[0].toUpperCase()} sera préparé et envoyé dans les plus brefs délais`
            : data[1]
            ? `Recommandé N°${data[0].toUpperCase()} programmé au ${moment(data[1]).format(
                "dddd DD MMMM YYYY"
              )}`
            : `Recommandé N°${data[0].toUpperCase()} envoyé`
          // formValues.date_type === 'deferred' ? `Recommandé N°${data[0].toUpperCase()} programmé au ${formValues.date.format('dddd DD MMMM YYYY')}` : `Recommandé N°${data[0].toUpperCase()} envoyé`
        }
        extra={[
          <Button
            type="primary"
            key="details"
            onClick={() => {
              history.push(`/historique/${config.printed ? "lettres" : "electronique"}/${data[0]}`);
            }}
          >
            Voir l&apos;envoi
          </Button>,
          <Button
            key="new"
            onClick={() => {
              history.push("/envoi");
            }}
          >
            Nouvel envoi
          </Button>,
        ]}
      />
    ),
  },
};

export default function ({ config }) {
  const formValues = useSelector((state) => state.messagePayload, shallowEqual);
  const route = useRouteMatch("/envoi/:type/:step/:id?");
  const history = useHistory();
  let currentStep;
  console.log({ config });

  // need more information on how it works...
  if (config.type === "electronique") {
    STEPS.recipients.key = 1;
    STEPS.attachments.key = 2;
    STEPS.summary.key = 3;
  } else if (config.type === "recommande") {
    STEPS.attachments.key = 1;
    STEPS.summary.key = 2;
  } else {
    STEPS.attachments.key = 1;
    STEPS.summary.key = 2;
  }

  if (route && route.params) {
    currentStep = STEPS[route.params.step] || {};
  }

  // Check if form has been completed correctly up until here
  let shouldRedirect = null;
  const nStepsVerif = (config.stepVerifications && config.stepVerifications.length) || 0;

  for (let i = 0; !shouldRedirect && i < Math.min(currentStep.key, nStepsVerif); i++) {
    if (!config.stepVerifications[i].fn(formValues)) {
      shouldRedirect = config.stepVerifications[i].path;
    }
  }

  // we either redirect the user or display the form (weird way to do it, but legacy)
  return shouldRedirect && currentStep.key !== 4 ? (
    <Redirect to={`/envoi/${config.type}/${shouldRedirect}`} />
  ) : (
    <div>
      <Steps
        current={currentStep.key}
        progressDot
        style={{ marginTop: 20, marginBottom: 32, padding: "10px 0", overflowX: "auto" }}
      >
        <Steps.Step key={0} title="Configuration d'envoi" />
        {config.type === "electronique" && <Steps.Step key={1} title="Destinataires" />}
        <Steps.Step key={2} title={config.printed ? "Documents" : "Pièces jointes"} />
        <Steps.Step key={3} title="Récapitulatif" />
        {currentStep.key === 4 && (
          <Steps.Step
            key={4}
            title={formValues.date_type === "now" ? "Envoyé" : "Envoi programmé"}
          />
        )}
      </Steps>
      {currentStep &&
        (currentStep.key === 4
          ? currentStep.content(
              config,
              formValues,
              route && route.params && atob(route.params.id).split(":"),
              history
            )
          : currentStep.content(config))}
    </div>
  );
}
