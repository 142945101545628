import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "antd";

import SendComponent from "../Send";
import ContactsComponent from "../Contacts";
import HistoryComponent from "../History";
import StatisticsComponent from "../Statistics";
import SettingsComponent from "../Settings";
import AdminComponent from "../Admin";
import SolidocSecure from "../SolidocSecure";

const { Content } = Layout;

const Body = () => {
  return (
    <Content id="content" style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/envoi" />
        </Route>
        <Route path="/solidoc-secure">
          <SolidocSecure />
        </Route>
        <Route path="/envoi">
          <SendComponent />
        </Route>
        <Route path="/historique/:type">
          <HistoryComponent />
        </Route>
        <Route path="/carnet">
          <ContactsComponent />
        </Route>
        <Route path="/statistiques">
          <StatisticsComponent />
        </Route>
        <Route path="/parametres">
          <SettingsComponent />
        </Route>
        <Route path="/admin">
          <AdminComponent />
        </Route>
      </Switch>
    </Content>
  );
};

export default Body;
