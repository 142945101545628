import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Descriptions, message, Row, Form, Input, Modal, notification } from "antd";

// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";

import { shallowEqual, useSelector } from "react-redux";
import { LockOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import Activate2FAModal from "./Activate2FAModal";

function UserForm({ values, form }) {
  return (
    <Form layout="vertical" form={form} initialValues={values}>
      <Form.Item
        name="last_name"
        label="Nom"
        rules={[{ required: true, message: "Veuillez entrer un nom" }]}
      >
        <Input placeholder="Ex: Dupont" />
      </Form.Item>

      <Form.Item
        name="first_name"
        label="Prénom"
        rules={[{ required: true, message: "Veuillez entrer un prénom" }]}
      >
        <Input placeholder="Ex: Jean" />
      </Form.Item>

      <Form.Item name="email" label="Adresse email">
        <Input placeholder="Ex: jean.dupont@gmail.com" disabled />
      </Form.Item>

      <Form.Item name="address_line_1" label="Adresse ligne 1" rules={[{ required: false }]}>
        <Input placeholder="Adresse postale, boîte postale..." />
      </Form.Item>

      <Form.Item name="address_line_2" label="Adresse ligne 2" rules={[{ required: false }]}>
        <Input placeholder="Appartement, suite, unité, immeuble, étage, etc." />
      </Form.Item>

      <Form.Item name="city" label="Ville" rules={[{ required: false }]}>
        <Input placeholder="Ex: Paris" />
      </Form.Item>

      <Form.Item name="postal_code" label="Code postal" rules={[{ required: false }]}>
        <Input placeholder="Ex: 75008" />
      </Form.Item>

      <Form.Item name="phone" label="Téléphone">
        <Input placeholder="Ex: 0123456789" />
      </Form.Item>
    </Form>
  );
}

function PasswordForm({ values, form }) {
  const [password, setPassword] = useState("");

  const validatePassword = (rule, value, callback) => {
    if (value && value !== password) {
      callback("Les mots de passes sont différents");
    } else {
      callback();
    }
  };

  return (
    <Form layout="vertical" form={form} initialValues={values}>
      <Form.Item
        name="current_password"
        label="Mot de passe actuel"
        rules={[{ required: true, message: "Veuillez entrer votre mot de passe" }]}
      >
        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" />
      </Form.Item>

      <Form.Item
        name="new_password"
        label="Nouveau mot de passe"
        rules={[
          { min: 6, message: "Votre mot de passe doit contenir au moins 6 caractères" },
          { required: true, message: "Veuillez entrer un nouveau mot de passe" },
        ]}
      >
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          name="new_password"
        />
      </Form.Item>

      <Form.Item
        name="confirm_new_password"
        label="Confirmer votre nouveau mot de passe"
        rules={[
          { required: true, message: "Veuillez confirmer votre nouveau mot de passe" },
          { validator: validatePassword },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          name="confirm_new_password"
        />
      </Form.Item>
    </Form>
  );
}

function Deactivate2FAModal({ visible, onCancel: onCancelProp, onConfirm }) {
  const [step, setStep] = useState("validation"); // validation | reauth
  const [userProvidedPassword, setUserProvidedPassword] = useState("");
  const [validationCode, setValidationCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [resolver, setResolver] = useState(null);
  const ref = useRef();

  const db = firebase.firestore();
  const reset = useCallback(() => {
    setStep("validation");
    setUserProvidedPassword("");
    setValidationCode("");
    setVerificationId("");
    setResolver(null);
  }, []);

  const onCancel = useCallback(() => {
    reset();
    onCancelProp();
  }, [onCancelProp, reset]);

  const disableTwoStepAuth = useCallback(() => {
    const options = firebase.auth().currentUser.multiFactor.enrolledFactors;
    // Present user the option to unenroll.
    return firebase
      .auth()
      .currentUser.multiFactor.unenroll(options[0])
      .then(() => {
        db.collection("users").doc(firebase.auth().currentUser.uid).update({
          has2FAActivated: false,
          phone2Fa: null,
        });
      })
      .then(() => {
        // User successfully unenrolled selected factor.
        notification.success({
          message: "Solidoc Authenticator désactivé",
        });
        onConfirm();
      })
      .catch(function (error) {
        console.error(error);
        if (error.code === "auth/requires-recent-login") {
          // ask user to reauth
          setStep("reauth");
        }
        // Handler error.
      });
  }, []);

  const confirmReAuth = useCallback(() => {
    const user = firebase.auth().currentUser;

    // Now you can use that to reauthenticate
    user
      .reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(user.email, userProvidedPassword)
      )
      .then(() => disableTwoStepAuth())
      .catch((error) => {
        if (error.code === "auth/multi-factor-auth-required") {
          let recaptchaVerifier = new firebase.auth.RecaptchaVerifier(ref.current, {
            size: "invisible",
            "error-callback": () => {
              console.log("error");
            },
          });
          setResolver(error.resolver);
          // Ask user which second factor to use.

          const phoneInfoOptions = {
            multiFactorHint: error.resolver.hints[0],
            session: error.resolver.session,
          };
          const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          // Send SMS verification code
          return phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (vId) {
              setVerificationId(vId);
              setStep("code-verification");
              // Ask user for the SMS verification code.
            })
            .then(function (userCredential) {
              // User successfully signed in with the second factor phone number.
            });
        }
        console.error(error);
      });
  }, [userProvidedPassword, ref, disableTwoStepAuth]);

  const validateTwoStepAuth = useCallback(() => {
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, validationCode);
    var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    return resolver.resolveSignIn(multiFactorAssertion).then(() => {
      return disableTwoStepAuth();
    });
  }, [verificationId, validationCode, resolver, disableTwoStepAuth]);
  const footer = useMemo(() => {
    if (step === "validation") {
      return [
        <Button key="cancel" onClick={onCancel}>
          Annuler
        </Button>,
        <Button key="confirm" type="primary" onClick={disableTwoStepAuth}>
          Valider
        </Button>,
      ];
    }
    if (step === "reauth") {
      return [
        <Button key="cancel" onClick={onCancel}>
          Annuler
        </Button>,
        <Button key="confirm" type="primary" onClick={confirmReAuth}>
          Valider
        </Button>,
      ];
    }
    if (step === "code-verification") {
      return [
        <Button key="cancel" onClick={onCancel}>
          Annuler
        </Button>,
        <Button key="confirm" type="primary" onClick={validateTwoStepAuth}>
          Confirmer
        </Button>,
      ];
    }
    return [];
  }, [step, disableTwoStepAuth, confirmReAuth, validateTwoStepAuth]);
  return (
    <Modal visible={visible} onCancel={onCancel} title="Solidoc Authenticator" footer={footer}>
      {step === "validation" && (
        <>
          <Row>Vous êtes sur le point de retirer Solidoc Authenticator de votre compte.</Row>
          <Row>Veuillez confirmer pour valider</Row>
        </>
      )}
      {step === "reauth" && (
        <>
          <Title level={5}>Entrez votre mot de passe</Title>
          <Row>Pour des mesures de sécurité, merci de vous identifier à nouveau</Row>
          <Row>
            <Input
              disabled={true}
              style={{
                width: "17rem",
              }}
              addonBefore="Email"
              value={firebase.auth().currentUser.email}
            />
          </Row>
          <Row>
            <Input
              style={{
                width: "17rem",
              }}
              addonBefore="Mot de passe"
              type="password"
              autoFocus={true}
              value={userProvidedPassword}
              onInput={(e) => setUserProvidedPassword(e.target.value)}
            />
          </Row>

          <div ref={ref} />
        </>
      )}
      {step === "code-verification" && (
        <>
          <Title level={5}>Code reçu</Title>
          <Row>Veuillez entrer le code reçu par SMS</Row>
          <Row>
            <Input
              style={{
                width: "17rem",
              }}
              addonBefore="Code"
              autoFocus={true}
              value={validationCode}
              placeholder="eg: 123456"
              onInput={(e) => setValidationCode(e.target.value)}
            />
          </Row>

          <div ref={ref} />
        </>
      )}
    </Modal>
  );
}

function EditModal({
  visible,
  user,
  onSubmit,
  onCancel,
  isFormLoading,
  setIsFormLoading,
  options,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible === true) {
      setIsFormLoading(false);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title={options.title}
      okText={options.okText}
      canceltext="Annuler"
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsFormLoading(true);
            onSubmit(values, form.resetFields);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ loading: isFormLoading }}
    >
      {options.type === "PASSWORD_RESET" ? (
        <PasswordForm form={form} values={user} />
      ) : (
        <UserForm form={form} values={user} />
      )}
    </Modal>
  );
}

const MODAL_OPTIONS = {
  PASSWORD_RESET: {
    title: "Modifier mon mot de passe",
    okText: "Modifier",
    type: "PASSWORD_RESET",
  },
  EDIT_USER: {
    title: "Modifier mes informations",
    okText: "Modifier",
    type: "EDIT_USER",
  },
};

export default function () {
  const db = firebase.firestore();
  const authUser = useSelector((state) => state.user, shallowEqual);

  const [user, setUser] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [disableAuthModalVisible, setDisableAuthModalVisible] = useState(false);
  const [modalType, setModalType] = useState("EDIT_USER");
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [hasTwoFactorEnabled, setHasTwoFactorEnabled] = useState(false);

  // property auth is not reactive, so we call the update manually on that case
  const updateHasTwoFactorEnabled = useCallback(() => {
    const auth = firebase.auth();
    setHasTwoFactorEnabled(!!auth.currentUser.multiFactor.enrolledFactors.length);
  }, []);

  useEffect(() => {
    updateHasTwoFactorEnabled();
  }, []);

  useEffect(() => {
    db.collection("users")
      .doc(authUser.uid)
      .get()
      .then((snapshot) => {
        const userData = snapshot.data();

        setUser(userData);
      });
  }, []);

  return (
    <>
      <h1>Mon compte</h1>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          {!hasTwoFactorEnabled ? (
            <Button
              type="primary"
              onClick={() => {
                setAuthModalVisible(true);
              }}
            >
              Activer Solidoc Authenticator
            </Button>
          ) : (
            <Button type="primary" onClick={() => setDisableAuthModalVisible(true)}>
              Désactiver Solidoc Authenticator
            </Button>
          )}
          <Button
            style={{ marginLeft: 4 }}
            type="primary"
            onClick={() => {
              setModalVisible(true);
              setModalType("PASSWORD_RESET");
            }}
          >
            Modifier mon mot de passe
          </Button>
          <Button
            type="primary"
            style={{ marginLeft: 4 }}
            onClick={() => {
              setModalVisible(true);
              setModalType("EDIT_USER");
            }}
          >
            Modifier mes informations
          </Button>
        </Col>
      </Row>

      {user && (
        <Descriptions bordered column={1} style={{ marginTop: 16 }}>
          <Descriptions.Item label="Nom">{user.last_name}</Descriptions.Item>
          <Descriptions.Item label="Prénom">{user.first_name}</Descriptions.Item>
          <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
          <Descriptions.Item label="Adresse">
            {user.address_line_1}
            <br />
            {user.address_line_2}
          </Descriptions.Item>
          <Descriptions.Item label="Ville">{user.city}</Descriptions.Item>
          <Descriptions.Item label="Code postal">{user.postal_code}</Descriptions.Item>
          <Descriptions.Item label="Numéro de téléphone">{user.phone}</Descriptions.Item>
          <Descriptions.Item label="Numéro Solidoc Authenticator">
            {user.phone2Fa}
          </Descriptions.Item>
        </Descriptions>
      )}

      {/* Modal */}
      <Activate2FAModal
        visible={authModalVisible}
        onCancel={() => {
          setAuthModalVisible(false);
        }}
        onEnrollment={() => {
          setAuthModalVisible(false);
          updateHasTwoFactorEnabled();
        }}
      />
      <Deactivate2FAModal
        visible={disableAuthModalVisible}
        onCancel={() => setDisableAuthModalVisible(false)}
        onConfirm={() => {
          setDisableAuthModalVisible(false);
          updateHasTwoFactorEnabled();
        }}
      />
      <EditModal
        options={MODAL_OPTIONS[modalType || "EDIT_USER"]}
        visible={modalVisible}
        user={user}
        onCancel={() => {
          setModalVisible(false);
        }}
        isFormLoading={isFormLoading}
        setIsFormLoading={setIsFormLoading}
        onSubmit={(values, resetFields) => {
          if (modalType === "PASSWORD_RESET") {
            const u = firebase.auth().currentUser;
            const credential = firebase.auth.EmailAuthProvider.credential(
              u.email,
              values.current_password
            );

            u.reauthenticateWithCredential(credential)
              .then(() => {
                u.updatePassword(values.new_password)
                  .then(() => {
                    resetFields();
                    message.success("Nouveau mot de passe enregistré.");
                    setModalVisible(false);
                  })
                  .catch((e) => {
                    console.error(e);
                    message.error("Une erreur est survenue. Veuillez réessayer.");
                  });
                setIsFormLoading(false);
              })
              .catch((e) => {
                console.error(e);
                if (e && e.code === "auth/wrong-password") {
                  message.error("Le mot de passe actuel est incorrect.");
                } else {
                  message.error("Une erreur est survenue. Veuillez réessayer.");
                }
                setIsFormLoading(false);
              });
          } else {
            db.collection("users")
              .doc(authUser.uid)
              .set({ ...values }, { merge: true })
              .then(() => {
                resetFields();
                setModalVisible(false);
                message.success("Modifications enregistrées");
                setUser(values);
              })
              .catch((e) => {
                console.error(e);
                setIsFormLoading(false);
                message.error("Une erreur est survenue. Veuillez réessayer.");
              });
          }
        }}
      />
    </>
  );
}
