import React, { useEffect, useState } from "react";

import { Table, Button, message, Select, Modal } from "antd";

import { Chart } from "@antv/g2";

// React-Redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// Firebase
import * as firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import _ from "lodash";

// Table configuration
const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Type",
    children: [
      {
        title: "Electroniques",
        dataIndex: "electronique",
        key: "electronique",
      },
      {
        title: "Envois simples",
        dataIndex: "simple",
        key: "simple",
      },
      {
        title: "Envois recommandés",
        dataIndex: "recommande",
        key: "recommande",
      },
    ],
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
  },
];

const yearOptions = [];
const currentYear = moment().year();
for (let i = 2020; i <= currentYear; i++) {
  yearOptions.push(i);
}

const Statistics = () => {
  const [loadingTable, setLoadingTable] = useState(true);

  const [stackedBarData, setStackedBarData] = useState([]);
  const [tableStatsData, setTableStatsData] = useState([]);
  const [stackBarPlot, setStackBarPlot] = useState(null);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedFields, setSelectedFields] = useState([
    "Type d'envoi",
    "Date d'envoi",
    "Type de destinataire",
    "Nom du destinataire",
    "Prénom du destinataire",
    "Email du destinataire",
    "Statut de l'envoi",
    "Date de dernier status",
  ]);
  const [exportedYears, setExportedYears] = useState([currentYear]);
  const [modalVisible, setModalVisible] = useState(false);

  const user = useSelector((state) => state.user, shallowEqual);

  // const getUserStats = firebase.functions().httpsCallable('getUserStatsCSV');
  const getUserStats = firebase.app().functions("europe-west1").httpsCallable("getUserStatsCSV");

  const dispatch = useDispatch();

  // Get contacts
  const db = firebase.firestore();
  const messageTypes = {
    recommande: "Envois recommandés",
    simple: "Envois simples",
    electronique: "Electroniques",
  };

  function fetchMessages() {
    db.collectionGroup("recipients")
      .where("company", "==", user.company)
      .get()
      .then((snapshot) => {
        const messageStats = {};

        snapshot.docs.forEach((recipient) => {
          const data = recipient.data();

          const date = moment(data.created_at.toDate());
          const month = date.month();
          const year = date.year();

          if (!messageStats[year]) {
            messageStats[year] = {};
          }

          if (!messageStats[year][month]) {
            messageStats[year][month] = {};
          }

          // const amount = data.contacts.length;
          const amount = 1;
          const type = data.send_type || "electronique";

          messageStats[year][month][type] = messageStats[year][month][type] + amount || amount;
          messageStats[year][month].total = messageStats[year][month].total + amount || amount;
        });

        // Populate chart and table data
        const chartData = [];
        const tableData = [];

        const monthLimit = 11;

        for (let i = monthLimit; i >= 0; i--) {
          const dateTitle = `${_.capitalize(moment(i + 1, "MM").format("MMMM"))} ${selectedYear}`;

          chartData.push({
            Date: dateTitle,
            Type: "Total",
            Total:
              messageStats[selectedYear] &&
              messageStats[selectedYear][i] &&
              messageStats[selectedYear][i].total
                ? messageStats[selectedYear][i].total
                : 0,
          });
        }
        for (const t in messageTypes) {
          for (let i = monthLimit; i >= 0; i--) {
            const dateTitle = `${_.capitalize(moment(i + 1, "MM").format("MMMM"))} ${selectedYear}`;

            chartData.push({
              Date: dateTitle,
              Type: messageTypes[t],
              Nb:
                messageStats[selectedYear] &&
                messageStats[selectedYear][i] &&
                messageStats[selectedYear][i][t]
                  ? messageStats[selectedYear][i][t]
                  : 0,
            });

            if (tableData.length <= monthLimit) {
              tableData.push({
                key: i,
                date: dateTitle,
                recommande:
                  messageStats[selectedYear] &&
                  messageStats[selectedYear][i] &&
                  messageStats[selectedYear][i].recommande
                    ? messageStats[selectedYear][i].recommande
                    : 0,
                simple:
                  messageStats[selectedYear] &&
                  messageStats[selectedYear][i] &&
                  messageStats[selectedYear][i].simple
                    ? messageStats[selectedYear][i].simple
                    : 0,
                electronique:
                  messageStats[selectedYear] &&
                  messageStats[selectedYear][i] &&
                  messageStats[selectedYear][i].electronique
                    ? messageStats[selectedYear][i].electronique
                    : 0,
                total:
                  messageStats[selectedYear] &&
                  messageStats[selectedYear][i] &&
                  messageStats[selectedYear][i].total
                    ? messageStats[selectedYear][i].total
                    : 0,
              });
            }
          }
        }

        setStackedBarData(chartData.reverse());
        setTableStatsData(tableData.reverse());
        setLoadingTable(false);
      });
  }

  useEffect(() => {
    if (user && user.company) {
      fetchMessages();
    }
  }, [user, selectedYear]);

  useEffect(() => {
    if (stackedBarData.length) {
      buildChart(stackedBarData);
    }
  }, [stackedBarData]);

  function buildChart(data) {
    if (stackBarPlot) {
      stackBarPlot.destroy();
    }

    const maxValue = _.maxBy(data, "Nb").Nb;

    const chart = new Chart({
      container: "chart-stacked-bar",
      autoFit: true,
      height: 300,
    });

    chart.data(data);
    chart.scale("Nb", {
      alias: "Nombre d'envois",
      nice: true,
      tickCount: maxValue < 10 ? maxValue + 1 : 10,
    });

    chart.axis("Date", {
      tickLine: null,
    });

    chart.tooltip({
      showMarkers: false,
      shared: true,
    });

    chart.legend(false);

    chart.interaction("active-region");
    chart
      .interval()
      .adjust("stack")
      .position({ fields: ["Date", "Nb"] })
      .tooltip("Type*Nb*Total", (type, nb, total) => {
        return {
          name: type,
          value: nb || total || 0,
        };
      })
      .color("Type", ["#40a9ff", "#1890ff", "#096dd9", "#0050b3"]);

    chart.render();

    setStackBarPlot(chart);
  }

  return (
    <>
      <h1>Statistiques</h1>
      <h2>
        Envois sur l&apos;année&nbsp;
        <Select
          placeholder="Type"
          onChange={(value) => setSelectedYear(value)}
          value={selectedYear}
        >
          <Select.Option value="2022">2022</Select.Option>
          <Select.Option value="2021">2021</Select.Option>
          <Select.Option value="2020">2020</Select.Option>
        </Select>
      </h2>

      <Modal
        visible={modalVisible}
        title="Télécharger en CSV"
        okText="Télécharger"
        canceltext="Annuler"
        maskClosable={false}
        onCancel={() => {
          setModalVisible(false);
        }}
        onOk={() => {
          if (!exportedYears.length) {
            message.error("Veuillez sélectionner des dates à exporter");
            return;
          }

          if (!selectedFields.length) {
            message.error("Veuillez sélectionner des champs à exporter");
            return;
          }

          dispatch({ type: "SHOW_LOADER", payload: "Création du fichier CSV..." });
          setModalVisible(false);
          const sortedDates = exportedYears.sort();

          getUserStats({ fields: selectedFields, dates: sortedDates }).then((response) => {
            const { data } = response;
            dispatch({ type: "HIDE_LOADER" });

            if (data) {
              if (data.error) {
                message.error(data.error);
              } else {
                window.location.href = `https://storage.googleapis.com/solidoc-proofs/${data}`;
              }
            } else {
              message.error("Une erreur est survenue. Veuillez réessayer.");
            }
          });
        }}
        // okButtonProps={{ loading: isFormLoading }}
      >
        <h4>Années à exporter</h4>
        <Select
          mode="multiple"
          size="middle"
          placeholder="Sélectionnez les champs à exporter"
          value={exportedYears}
          style={{ width: "100%", marginBottom: 16 }}
          onChange={(value) => {
            setExportedYears(value);
          }}
        >
          {yearOptions.map((year) => (
            <Select.Option value={year} key={year}>
              {year}
            </Select.Option>
          ))}
        </Select>

        <h4>Champs à exporter</h4>
        <Select
          mode="multiple"
          size="middle"
          placeholder="Sélectionnez les champs à exporter"
          value={selectedFields}
          style={{ width: "100%" }}
          onChange={(value) => {
            setSelectedFields(value);
          }}
        >
          <Select.Option value="Type d'envoi">Type d&apos;envoi</Select.Option>
          <Select.Option value="Date d'envoi">Date d&apos;envoi</Select.Option>
          <Select.Option value="Type de destinataire">Type de destinataire</Select.Option>
          <Select.Option value="Nom du destinataire">Nom du destinataire</Select.Option>
          <Select.Option value="Prénom du destinataire">Prénom du destinataire</Select.Option>
          <Select.Option value="Email du destinataire">Email du destinataire</Select.Option>
          <Select.Option value="Statut de l'envoi">Statut de l&apos;envoi</Select.Option>
          <Select.Option value="Date de dernier status">Date de dernier status</Select.Option>
        </Select>
      </Modal>

      <div id="chart-stacked-bar" />
      {tableStatsData && tableStatsData.length && (
        <>
          <Table
            style={{ marginTop: 16 }}
            columns={columns}
            loading={loadingTable}
            dataSource={tableStatsData}
            bordered
            size="middle"
            pagination={false}
          />

          <Button
            type="default"
            style={{ float: "right", marginTop: 16 }}
            onClick={() => {
              setModalVisible(true);
            }}
          >
            Télécharger en CSV
          </Button>
        </>
      )}
    </>
  );
};

export default Statistics;
