import React, { useEffect, useMemo, useState } from "react";
import { Table, Space, Button, Modal, Row, DatePicker, Col, Tag } from "antd";
import { Switch, Route, useHistory } from "react-router-dom";

// React-Redux
import { useDispatch } from "react-redux";

// Icons
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  SyncOutlined,
  WarningOutlined,
} from "@ant-design/icons";

// Firebase

import firebase from "firebase/app";
import "firebase/firestore";

// Lodash & Moment
import moment from "moment";

import DetailsComponent from "./Details";
import ChangeStatusDropdown from "./ChangeStatusDropdown";
import Search from "antd/es/input/Search";

const { RangePicker } = DatePicker;

function sorterFn(property) {
  return (a, b) => `${a[property]}`.toLowerCase().localeCompare(`${b[property]}`.toLowerCase());
}

const { confirm } = Modal;

export default function () {
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingUrgentTable, setUrgentLoadingTable] = useState(true);
  const [columns, setColumns] = useState([]);
  const [messages, setMessages] = useState([]);
  const [urgentMessages, setUrgentMessages] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(null);

  const [urgentStartDate, setUrgentStartDate] = useState(moment().subtract(7, "days"));

  const [search, setSearch] = useState("");

  // search by date, today and 7 days before
  const [searchRange, setSearchRange] = useState([moment().subtract(1, "months"), moment()]);

  // const user = useSelector((state) => state.user, shallowEqual);
  // const messages = useSelector((state) => state.messagesHistory, shallowEqual);

  const dispatch = useDispatch();
  const history = useHistory();

  const db = firebase.firestore();

  function fetchUrgentMessages() {
    setUrgentLoadingTable(true);

    db.collectionGroup("recipients")
      .where("send_type", "in", ["recommande", "simple"])
      .where("status", "==", "prepare")
      .where("created_at", "<=", urgentStartDate.toDate())
      .get()
      .then(async (recipientsSnapshot) => {
        const recipientsObj = {};
        console.log("LENGTH", recipientsSnapshot.docs.length);
        const companiesSnapshot = await db.collection("companies").get();
        const companies = companiesSnapshot.docs.map((d) => ({
          ...d.data(),
          id: d.id,
        }));
        if (recipientsSnapshot.docs.length) {
          recipientsSnapshot.docs.forEach((recipientDoc) => {
            const recipient = { ...recipientDoc.data(), key: recipientDoc.id };
            if (recipientsObj[recipient.message.id]) {
              recipientsObj[recipient.message.id].push(recipient);
            } else {
              recipientsObj[recipient.message.id] = [recipient];
            }
          });

          const results = await Promise.all(
            recipientsSnapshot.docs.map((recipientDoc) => {
              // fetch message
              return recipientDoc.ref.parent.parent.get().then((message) => {
                return {
                  ...message.data(),
                  key: message.id,
                  companyId: message.ref.parent.parent.id,
                  company: {
                    ...companies.find((c) => c.id === message.ref.parent.parent.id),
                    id: message.ref.parent.parent.id,
                  },
                  recipients: recipientsObj[message.id],
                };
              });
            })
          );

          setUrgentMessages(results);
          setUrgentLoadingTable(false);
        } else {
          setUrgentLoadingTable(false);
        }
      });
  }

  function fetchMessages() {
    setLoadingTable(true);

    db.collectionGroup("recipients")
      .where("send_type", "in", ["recommande", "simple"])
      .where("created_at", ">=", searchRange[0].toDate())
      .where("created_at", "<=", searchRange[1].toDate())
      .get()
      .then(async (recipientsSnapshot) => {
        const recipientsObj = {};
        if (recipientsSnapshot.docs.length) {
          const companiesSnapshot = await db.collection("companies").get();
          const companies = companiesSnapshot.docs.map((d) => ({
            ...d.data(),
            id: d.id,
          }));
          recipientsSnapshot.docs.forEach((recipientDoc) => {
            const recipient = { ...recipientDoc.data(), key: recipientDoc.id };
            if (recipientsObj[recipient.message.id]) {
              recipientsObj[recipient.message.id].push(recipient);
            } else {
              recipientsObj[recipient.message.id] = [recipient];
            }
          });
          // different fetching strategy here, since we might need to fetch many messages at once and we can't spam our backend

          db.collectionGroup("messages")
            .where("type", "in", ["recommande", "simple"])
            .where("created_at", ">=", searchRange[0].toDate())
            .where("created_at", "<=", searchRange[1].toDate())
            .get()
            .then(async (snapshot) => {
              const results = [];
              for (let i = 0; i < snapshot.docs.length; i++) {
                const message = snapshot.docs[i];

                results.push({
                  ...message.data(),
                  key: message.id,
                  companyId: message.ref.parent.parent.id,
                  company: {
                    ...companies.find((c) => c.id === message.ref.parent.parent.id),
                    id: message.ref.parent.parent.id,
                  },
                  recipients: recipientsObj[message.id],
                });
              }

              setMessages(results);
              setLoadingTable(false);
            });
        } else {
          setLoadingTable(false);
        }
      });
  }

  useEffect(() => {
    dispatch({ type: "CLEAR_MESSAGES_HISTORY" });
    setColumns([
      {
        title: "Date de dépôt",
        dataIndex: "date",
        sorter: sorterFn("date"),
        render: (date) => moment(date.toDate()).tz("Europe/Paris").format("DD/MM/YYYY HH:mm"),
        sortOrder: "descend",
      },
      {
        title: "Date d'envoi",
        dataIndex: "recipients",
        render: (recipients) =>
          recipients && recipients[0] && recipients[0].send_date
            ? moment(recipients[0].send_date.toDate()).tz("Europe/Paris").format("DD/MM/YYYY")
            : "",
        sortOrder: "descend",
      },
      {
        title: "Nom du compte client",
        dataIndex: "company",
        render: (company) => company?.name || "",
        sortOrder: "descend",
      },
      {
        title: "Type",
        dataIndex: "type",
        render: (t, o) => (t === "simple" ? "Lettre simple" : `Recommandé ${o.level}`),
      },
      {
        title: "Destinataires",
        dataIndex: "recipients_type",
        render: (recipientsType) => (recipientsType === "multiple" ? "Multiples" : "Unique"),
      },
      {
        title: "Avec accusé de réception",
        dataIndex: "receipt_aknowledge",
        render: (receipt, o) => (
          <div>{o.type === "simple" ? "-" : receipt === "yes" ? "Oui" : "Non"}</div>
        ),
      },
      {
        title: "Pièces jointes",
        dataIndex: "attachments",
        render: (attachment) => <div>{attachment.length}</div>,
      },
      {
        title: "Status",
        dataIndex: "recipients",
        filters: [
          {
            text: "En préparation",
            value: "prepare",
          },
          {
            text: "Envoyé",
            value: "sent",
          },
          {
            text: "Erreur d'adressage",
            value: "error_address",
          },
          {
            text: "Erreur fichier",
            value: "error_file",
          },
          {
            text: "Erreur d'impression",
            value: "error_print",
          },
          {
            text: "Erreur paramètres d’impression",
            value: "error_print_param",
          },
        ],
        onFilter: (value, record) =>
          record && record.recipients && record.recipients[0].status === value,
        render: (recipients, record) => {
          if (!recipients) {
            return;
          }
          return {
            props: {
              style: { padding: 0, backgroundColor: "#fafafa" }, // remove the padding around the dropdown
            },
            children: (
              <ChangeStatusDropdown
                record={record}
                onConfirm={() => setLastUpdate(Date.now())}
                style={{ height: "100%" }}
              />
            ),
          };
        },
      },
      {
        title: "Détails",
        key: "action",
        render: (text, record) => (
          <Space size="small">
            {record && record.recipients && record.recipients[0].status !== "sent" && (
              <Button
                type="primary"
                title="Marquer comme envoyer"
                size="small"
                shape="circle"
                icon={<CheckCircleOutlined />}
                onClick={() => {
                  confirm({
                    title: "Marquer comme envoyé",
                    icon: <ExclamationCircleOutlined />,
                    content: (
                      <div>Êtes-vous sûr de vouloir marquer l'envoi comme étant "Envoyé" ?</div>
                    ),
                    okText: "Oui",
                    okType: "primary",
                    onOk: () => {
                      return db
                        .collection("companies")
                        .doc(record.companyId)
                        .collection("messages")
                        .doc(record.key)
                        .collection("recipients")
                        .doc(record.recipients[0].key)
                        .set(
                          {
                            status: "sent",
                            send_date: firebase.firestore.FieldValue.serverTimestamp(),
                          },
                          { merge: true }
                        )
                        .then(() => {
                          setLastUpdate(+new Date());
                        });
                    },
                  });
                }}
              />
            )}
            <Button
              onClick={() => {
                // history.push(`/admin/sent/${record.companyId}/${record.key}`);
                window.open(`/admin/sent/${record.companyId}/${record.key}`, "_blank");
              }}
              type="primary"
              title="Details"
              size="small"
              shape="circle"
              icon={<InfoCircleOutlined />}
            />
          </Space>
        ),
      },
    ]);
    fetchUrgentMessages();
    fetchMessages();
  }, [lastUpdate]);

  useEffect(() => {
    fetchMessages();
    //fetchUrgentMessages();
  }, [searchRange]);

  const filteredMessages = useMemo(() => {
    if (!search) return messages;
    return messages.filter((m) => m.company.name.toLowerCase().includes(search.toLowerCase()));
  }, [messages, search]);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <Switch>
        <Route path="/admin/sent/:company/:id">
          <DetailsComponent />
        </Route>
        <Route>
          {urgentMessages.length ? (
            <>
              <h1>Envois prioritaires</h1>
              <Row>
                <Tag icon={<WarningOutlined />} color="error">
                  Ces envois sont en attente depuis plus de 7 jours
                </Tag>
              </Row>
              <Table
                bordered
                style={{ marginTop: 16 }}
                columns={columns}
                dataSource={urgentMessages}
                size="small"
                scroll={urgentMessages.length ? { x: true } : undefined}
                loading={loadingUrgentTable}
                pagination={{
                  pageSize: 10,
                  position: ["bottomCenter"],
                }}
              />
            </>
          ) : null}

          <h1>Tous les envois papiers</h1>
          <Row>
            <Col span={8}>
              <Search
                style={{ maxWidth: "30rem" }}
                placeholder="Recherche par compte client"
                onSearch={(v) => {
                  setSearch(v);
                }}
                enterButton
              />
            </Col>
            <Col
              span={8}
              offset={8}
              style={{
                textAlign: "right",
              }}
            >
              <Space>
                <div>Filter par date de dépôt</div>
                <RangePicker
                  value={searchRange}
                  disabledDate={disabledDate}
                  onChange={setSearchRange}
                />
              </Space>
            </Col>
          </Row>

          <Table
            bordered
            style={{ marginTop: 16 }}
            columns={columns}
            dataSource={filteredMessages}
            size="small"
            scroll={filteredMessages.length ? { x: true } : undefined}
            loading={loadingTable}
            pagination={{
              pageSize: 30,
              position: ["bottomCenter"],
            }}
          />
        </Route>
      </Switch>
    </>
  );
}
