import React, { useState } from "react";
import { Button, Modal, Space, Table, message } from "antd";

// Firebase
import "firebase/auth";

import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import getHighlightedText from "../../../modules/helpers/utils";

import UsersTable from "./UsersTable";

const { confirm } = Modal;

function sorterFn(property) {
  return (a, b) => `${a[property]}`.toLowerCase().localeCompare(`${b[property]}`.toLowerCase());
}

const deleteCompanyModal = function (company, onSubmit) {
  confirm({
    title: "Supprimer un compte",
    icon: <ExclamationCircleOutlined />,
    content: (
      <div>
        Êtes-vous sûr de vouloir supprimer <strong>{company.name}</strong>
      </div>
    ),
    okText: "Supprimer",
    okType: "danger",
    onOk: onSubmit,
  });
};

export default function ({
  allCompanies,
  companies,
  setSelectedCompany,
  setCompanyModalVisible,
  setLastUpdate,
  searchTerms,
}) {
  const [loadingTable, setLoadingTable] = useState(false);

  const columns = [
    {
      title: "Nom",
      dataIndex: "name",
      sorter: sorterFn("name"),
      defaultSortOrder: "ascend",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (email) => getHighlightedText(email, searchTerms),
    },
    { title: "Téléphone", dataIndex: "phone" },
    {
      title: "Adresse",
      key: "address",
      render: (text, record) => (
        <p style={{ marginBottom: 0 }}>
          {record.address_line_1}
          {record.address_line_2 && <br />}
          {record.address_line_2}
        </p>
      ),
    },
    { title: "Ville", dataIndex: "city" },
    { title: "Code postal", dataIndex: "postal_code" },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="small">
          <Button
            onClick={() => {
              setSelectedCompany(record);
              setCompanyModalVisible(true);
            }}
            type="primary"
            title="Modifier"
            size="small"
            shape="circle"
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => {
              if (record.users && record.users.length > 0) {
                message.error("Vous ne pouvez pas supprimer un compte contenant des utilisateurs.");
              } else {
                deleteCompanyModal(record, () => {
                  setLoadingTable(true);

                  record.ref.delete().then(() => {
                    setLoadingTable(false);
                    setLastUpdate(new Date().getTime());
                    message.success("Compte supprimé.");
                  });
                });
              }
            }}
            type="danger"
            title="Supprimer"
            size="small"
            shape="circle"
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        bordered
        style={{ marginTop: 16 }}
        columns={columns}
        dataSource={companies}
        size="small"
        scroll={{ x: true }}
        loading={!companies.length || loadingTable}
        pagination={{ defaultPageSize: 50 }}
        expandable={{
          expandedRowRender: (company) => (
            <UsersTable
              company={company}
              companies={allCompanies}
              setLastUpdate={setLastUpdate}
              searchTerms={searchTerms}
            />
          ),
          expandIcon: ({ expanded, onExpand, record }) => {
            return record.users && record.users.length > 0 ? (
              <Button
                size="small"
                style={{ fontSize: 12, fontWeight: 600 }}
                onClick={(e) => onExpand(record, e)}
              >
                {record.users.length} Utilisateur{record.users.length > 1 && "s"}
              </Button>
            ) : (
              <Button
                size="small"
                style={{ fontSize: 12, fontWeight: 600 }}
                onClick={(e) => onExpand(record, e)}
              >
                Aucun utilisateur
              </Button>
            );
          },
        }}
      />
    </>
  );
}
