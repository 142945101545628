const defaultState = {
  contacts: [],
  lists: [],
};

const contactReducer = {
  contacts: (state = defaultState, { type, payload }) => {
    console.log(type, payload);
    switch (type) {
      case "ADD_CONTACT":
        return {
          ...state,
          contacts: [...state.contacts, payload],
        };
      case "ADD_LIST":
        return {
          ...state,
          lists: [...state.lists, payload],
        };
      case "UPDATE_CONTACTS":
        console.log(state, payload);
        return {
          ...state,
          contacts: [...payload],
        };
      case "UPDATE_LISTS":
        console.log("UPDATE LIST", payload);
        return {
          ...state,
          lists: [
            ...payload.map((list) => ({
              ...list,
              contactsMerged: list.contacts.map((contact) => contact.email).join(" "),
            })),
          ],
        };
      case "DELETE_CONTACT":
        return {
          ...state,
          contacts: [...payload],
        };
      default:
        return state;
    }
  },
};

export default contactReducer;
