import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Row, Col } from "antd";

import { PaperClipOutlined } from "@ant-design/icons";

// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";

export default function ({ setLoader }) {
  const { id, token } = useParams();

  const [lre, setLre] = useState(null);
  const [status, setStatus] = useState(null);
  const readMessage = firebase.app().functions("europe-west1").httpsCallable("readMessage");

  useEffect(() => {
    console.log("Reading message...");

    readMessage({ recipientId: id, token })
      .then((message) => {
        if (message && message.data) {
          setLoader(false);

          if (message.data === "refused" || message.data === "neglected") {
            setStatus(message.data);
          } else {
            console.log(message);
            setLre(message.data);
          }
        }
      })
      .catch((e) => console.error(e));
  }, []);

  return lre ? (
    <div id="recommande">
      <Row>
        <div id="logo" />
      </Row>
      <Row className="header-text">
        <h3>Partenaire pour tous vos envois de recommandés électroniques</h3>
        <p>
          Conforme à l’article 93 de la loi 2016-1321 du 7 octobre 2016 pour une république
          numérique.
        </p>
      </Row>
      <Row className="users-informations">
        <Col span={12} style={{ paddingLeft: 0 }}>
          <div className="info-container">
            <h2>EXPEDITEUR</h2>
            <div style={{ fontSize: "1.1em" }}>
              <b>{lre.sender.name}</b>
              <p>{lre.sender.email}</p>
            </div>
            <div className="separator" />
            <div className="address" style={{ fontSize: "1.1em" }}>
              <p>
                {lre.sender.address_line_1 && (
                  <>
                    {lre.sender.address_line_1}
                    <br />
                  </>
                )}
                {lre.sender.address_line_2 && (
                  <>
                    {lre.sender.address_line_2}
                    <br />
                  </>
                )}
                {lre.sender.postal_code || ""} {lre.sender.city || ""}
              </p>
            </div>
          </div>
        </Col>
        <Col span={12} style={{ paddingRight: 0 }}>
          <div className="info-container" style={{ fontSize: "1.1em" }}>
            <h2>DESTINATAIRE</h2>
            <div>
              <b>
                {lre.recipient.first_name} {lre.recipient.last_name}
              </b>
              <p>{lre.recipient.email}</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} id="lre-infos">
          <b>Numéro d&apos;identification certifié de l&apos;envoi :</b>{" "}
          {lre.recipient.id.toUpperCase()}
          <br />
          <b>Date et heure du dépôt électronique de votre LRE :</b> {lre.recipient.sendDate}
        </Col>
      </Row>
      <Row>
        <Col span={24} id="lre-content">
          <p>
            Bonjour,
            <br />
            <br />
            Nous vous prions de trouver ci-joint, le contenu de votre email recommandé :
          </p>
          <div id="subject">
            <b>Objet</b>
            <p>{lre.message.subject}</p>
          </div>
          <div id="content">
            <b>Message</b>
            <p style={{ whiteSpace: "pre-line" }}>{lre.message.content}</p>
          </div>
          <div id="attachments">
            <b>Pièces jointes à télécharger</b>
            <div>
              {lre.message.attachments.map((attachment, index) => (
                <div key={index}>
                  <a href={attachment.url} target="_blank" rel="noopener noreferrer">
                    <PaperClipOutlined /> {attachment.filename}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    status && (
      <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
        <h2>
          {status === "refused" ? "Votre message à déjà été refusé" : "Votre message à été négligé"}
        </h2>
      </div>
    )
  );
}
