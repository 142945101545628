import React, { useEffect, useState } from 'react';
import {
  Form,
  Modal,
  Radio,
  Table,
  Input,
  Space,
  Button,
  Row,
  Col,
  Select, List, Popconfirm, Card,
} from 'antd';

// React-Redux
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Icons
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';

// Firebase
import * as firebase from 'firebase/app';
import 'firebase/firestore';

// Lodash
import _ from 'lodash';
import moment from 'moment';

import ContactComponent from './Contacts';

const { Search } = Input;
const { confirm } = Modal;
const { Option } = Select;

function RecipientsModal({ visible, values, onOk, onCancel }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    setSelectedRowKeys(values);
  }, [values]);

  return (
    <Modal
      title="Ajouter un destinataire"
      visible={visible}
      onOk={() => {
        // dispatch({ type: 'EMAIL_FORM_SUBMITTED', payload: { contacts: selectedRowKeys } });
        onOk(selectedRowKeys);
      }}
      onCancel={onCancel}
      width="90%"
    >
      <ContactComponent
        selectionMode
        selectedRows={selectedRowKeys.map((s) => s && s.key)}
        onChange={(keys, rows) => {
          console.log(keys, rows);
          setSelectedRowKeys(rows);
        }}
      />
    </Modal>
  );
}

function sorterFn(property) {
  return (a, b) => (`${a[property]}`).toLowerCase().localeCompare((`${b[property]}`).toLowerCase());
}

function ContactForm({ values, form }) {
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [contactsList, setContactsList] = useState([]);

  useEffect(() => {
    setContactsList(values && values.contacts || []);
  }, [values]);

  useEffect(() => {
    form.setFields([{ name: 'contacts', value: contactsList }]);
  }, [contactsList]);

  return (
    <>
      <RecipientsModal
        visible={contactModalVisible}
        values={contactsList}
        onOk={(values) => {
          console.log('Recipients Values', values);
          setContactsList(values);
          setContactModalVisible(false);
          console.log(form);
        }}
        onCancel={() => setContactModalVisible(false)}
      />

      <Form layout="vertical" form={form} initialValues={values}>
        <Form.Item
          name="list_name"
          label="Nom de la liste"
          rules={[{ required: true, message: 'Veuillez entrer un nom' }]}
        >
          <Input placeholder="Ex: Dupont" />
        </Form.Item>

        <Form.Item
          name="contacts"
          label="Contacts"
          rules={[{ required: true, message: 'Veuillez selectionner au moins 1 contact' }]}
        >
          <Card
            type="inner"
            style={{ width: '100%' }}
          >
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <List
                  locale={{ emptyText: 'Aucun destinataire' }}
                  className="demo-loadmore-list"
                  itemLayout="horizontal"
                  dataSource={contactsList}
                  renderItem={(item) => (
                    <List.Item
                      actions={[(
                        <Popconfirm
                          title="Retirer ce contact ?"
                          okText="Oui"
                          onConfirm={() => {
                            setContactsList(_.reject(contactsList, item));
                          }}
                        >
                          <Button danger>
                            Retirer
                          </Button>
                        </Popconfirm>
                      )]}
                    >
                      <List.Item.Meta
                        title={`${item.first_name} ${item.last_name}`}
                        description={item.email}
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Button type="primary" onClick={() => setContactModalVisible(true)}>Ajouter un destinataire</Button>
              </Col>
            </Row>
          </Card>

        </Form.Item>
      </Form>
    </>
  );
}

function ContactModal({ visible, contact, onSubmit, onCancel }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [form, contact]);

  useEffect(() => {
    if (visible === true) {
      setIsLoading(false);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title={`${contact ? 'Modifier' : 'Créer'} une liste`}
      okText={contact ? 'Modifier' : 'Créer'}
      canceltext="Annuler"
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsLoading(true);
            form.resetFields();
            console.log('formValues', values);
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      okButtonProps={{ loading: isLoading }}
    >
      <ContactForm form={form} values={contact} />
    </Modal>
  );
}

const deleteListModal = function (list, onSubmit) {
  confirm({
    title: 'Supprimer un contact',
    icon: <ExclamationCircleOutlined />,
    content: (
      <div>
        Êtes-vous sûr de vouloir supprimer la liste de diffusion <strong>{list.list_name}</strong> ?
      </div>
    ),
    okText: 'Supprimer',
    okType: 'danger',
    onOk: onSubmit,
  });
};

export default function ({ selectionMode, selectedRows, onChange }) {
  const [visible, setVisible] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [selectedList, setSelectedList] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(moment());

  const user = useSelector((state) => state.user, shallowEqual);
  const contacts = useSelector((state) => state.contacts, shallowEqual);

  const dispatch = useDispatch();

  // Get contacts
  const db = firebase.firestore();

  // Search
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder="Recherche"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Chercher
            </Button>
            <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
              Réinitialiser
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
    onFilterDropdownVisibleChange(v) {
      if (v && this && this.searchInput) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  useEffect(() => {
    user
      .company
      .collection('lists')
      .get()
      .then((snapshot) => {
        setLoadingTable(false);

        dispatch({
          type: 'UPDATE_LISTS',
          payload: snapshot.docs.map((list) => ({ ...list.data(), key: list.id })),
        });
      });
  }, [lastUpdated]);

  const columns = [
    {
      title: 'Nom de la liste',
      dataIndex: 'list_name',
      sorter: sorterFn('list_name'),
      ...getColumnSearchProps('list_name'),
    },
    {
      title: 'Contacts',
      key: 'contacts',
      render: (text, record) => {
        console.log(record.contacts);
        return record.contacts && record.contacts.map((contact) => (
          <div key={contact.key}>{contact.email}</div>
        ));
      },
    },
  ];

  if (!selectionMode) {
    columns.push({
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="small">
          <Button
            onClick={() => {
              setSelectedList(record);
              setVisible(true);
            }}
            type="primary"
            title="Modifier"
            size="small"
            shape="circle"
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => {
              deleteListModal(record, () => {
                setLoadingTable(true);

                // Clone & Mutate
                // const listsClone = _.clone(contacts.contacts);
                // _.remove(listsClone, { key: record.key });
                // dispatch({ type: 'UPDATE_LISTS', payload: listsClone });

                user
                  .company
                  .collection('lists')
                  .doc(record.key)
                  .delete()
                  .then(() => {
                    setLoadingTable(false);
                    setLastUpdated(moment());
                  });
              });
            }}
            type="danger"
            title="Supprimer"
            size="small"
            shape="circle"
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    });
  }

  return (
    <>
      {!selectionMode && <h1>Listes de diffusion</h1>}

      <Row>
        {/*
        <Col span={14}>
          <Radio.Group style={{ marginBottom: 16 }} defaultValue="now">
            <Radio.Button value="now">Contacts</Radio.Button>
            <Radio.Button value="deferred">Listes&nbsp;de&nbsp;diffusion</Radio.Button>
          </Radio.Group>
        </Col>
        */}
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            onClick={() => {
              setSelectedList(null);
              setVisible(true);
            }}
          >
            Créer une liste
          </Button>
        </Col>
      </Row>

      {/*
      <Form>
        <Search placeholder="Trouver un contact" />
      </Form>
      */}

      <Table
        bordered
        style={{ marginTop: 16 }}
        columns={columns}
        dataSource={contacts.lists}
        size="small"
        scroll={{ x: true }}
        loading={loadingTable}
        rowSelection={
          selectionMode
            ? {
              selectedRowKeys: selectedRows,
              onChange,
            }
            : null
        }
      />

      <ContactModal
        visible={visible}
        contact={selectedList}
        onCancel={() => { setVisible(false); }}
        onSubmit={(values) => {
          const dbCollection = user.company.collection('lists');

          if (selectedList) {
            // Clone & Mutate
            // const listsClone = _.clone(contacts.lists);
            // _.remove(listsClone, { key: selectedList.key });
            // dispatch({ type: 'UPDATE_LISTS', payload: [...listsClone, { ...selectedList, ...values }] });

            dbCollection
              .doc(selectedList.key)
              .set({ ...values }, { merge: true })
              .then(() => {
                setVisible(false);
                setLastUpdated(moment());
              });
          } else {
            dispatch({ type: 'ADD_LIST', payload: { ...values, key: _.uniqueId() } });
            dbCollection.add({ ...values }).then(() => {
              setVisible(false);
              setLastUpdated(moment());
            });
          }
        }}
      />
    </>
  );
}
