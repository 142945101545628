import React, { useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Alert, Button, Card, Col, DatePicker, Form, Input, Radio, Row, Select } from "antd";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";

const { Option } = Select;

function ErrorsComponent({ errors, fields }) {
  const errArr = _.transform(
    _.pick(errors, fields),
    (componentArr, fieldError, fieldName) => {
      componentArr.push(<div key={fieldName}>{fieldError}</div>);
    },
    []
  );

  return errArr.length ? <Alert message={errArr} type="error" /> : [];
}

// Internal components
function disabledDate(current) {
  // Can not select days before today (including today)
  return current && current < moment().endOf("day");
}

export default function StepConfiguration({ config }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(
    useSelector((state) => state.messagePayload, shallowEqual)
  );

  function formValuesChangeHandle(newFormValues) {
    setFormValues({
      ...formValues,
      ...newFormValues,
    });
  }

  function onFormSubmitted(values) {
    dispatch({ type: "EMAIL_FORM_SUBMITTED", payload: values });
    if (config.type === "recommande") {
      history.push(`/envoi/${config.type}/attachments`); // we push directly to summary
    } else {
      history.push(`/envoi/${config.type}/attachments`);
    }
  }

  const checkErrors = useCallback(() => {
    const errors = {};

    form
      .getFieldsError()
      .filter((el) => el.errors.length)
      .forEach((el) => {
        errors[el.name[0]] = el.errors;
      });

    setFormErrors(errors);
  }, [form]);

  const debouncedCheckErrors = _.debounce(checkErrors, 500);

  function checkDateError(e) {
    if (e && e.target && e.target.value === "now") {
      setFormErrors(_.omit(formErrors, "date"));
    } else {
      form.validateFields(["date"]);
      debouncedCheckErrors();
    }
  }

  return (
    <Form
      form={form}
      onValuesChange={formValuesChangeHandle}
      onFinishFailed={debouncedCheckErrors}
      onFinish={onFormSubmitted}
      initialValues={formValues}
    >
      {config.type !== "electronique" && (
        <Col style={{ marginBottom: "1rem" }}>
          <Alert
            type="warning"
            message="Après 15h nous ne pouvons garantir une expédition à J de vos envois"
          />
        </Col>
      )}

      {config.printed && (
        <Card title="Paramètres d'impression" type="inner" style={{ width: "100%" }}>
          <ErrorsComponent
            errors={formErrors}
            fields={[
              "color_option",
              "face_option",
              "recipients_type",
              "receipt_aknowledge",
              "level",
              "envelope",
            ]}
          />
          <Form.Item
            label="Couleur"
            name="color_option"
            data-field-name="color_option"
            rules={[
              {
                required: true,
                message: "Veuillez sélectionner un paramètre de couleur",
              },
            ]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="black_white">Noir/Blanc</Radio.Button>
              <Radio.Button value="color" defaultChecked>
                Couleur
              </Radio.Button>
            </Radio.Group>
            {/*
              <Select placeholder="Paramètres de couleur">
                <Option value="black_white">Noir/Blanc</Option>
                <Option value="color">Couleur</Option>
              </Select>
              */}
          </Form.Item>
          <Form.Item
            label="Impression recto/verso"
            name="face_option"
            data-field-name="face_option"
            rules={[
              {
                required: true,
                message: "Veuillez sélectionner un paramètre d'impression",
              },
            ]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="one_face" defaultChecked>
                Recto seulement
              </Radio.Button>
              <Radio.Button value="double_face">Recto/verso</Radio.Button>
            </Radio.Group>
            {/*
              <Select placeholder="Recto ou Recto/Verso">
                <Option value="one_face">Recto seulement</Option>
                <Option value="double_face">Recto/Verso</Option>
              </Select>
              */}
          </Form.Item>
          {config.type === "simple" && (
            <Form.Item
              label="Type de destinataires"
              name="recipients_type"
              data-field-name="recipients_type"
              rules={[
                {
                  required: true,
                  message: "Veuillez sélectionner un type de destinataires",
                },
              ]}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="unique" defaultChecked>
                  Destinataire unique
                </Radio.Button>
                <Radio.Button value="multiple">Destinataires multiples</Radio.Button>
              </Radio.Group>
              {/*
              <Select placeholder="Agrafage">
                <Option value="yes">Avec agrafage</Option>
                <Option value="no">Sans agrafage</Option>
              </Select>
              */}
            </Form.Item>
          )}
          {
            /* Simple only */
            config.type === "simple" && (
              <Form.Item
                label="Délai de distribution"
                name="envelope"
                data-field-name="envelope"
                rules={[
                  {
                    required: true,
                    message: "Veuillez sélectionner un délai de distribution",
                  },
                ]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value="J+1" defaultChecked>
                    J+1
                  </Radio.Button>
                  <Radio.Button value="J+2">J+2</Radio.Button>
                  <Radio.Button value="J+4">J+4</Radio.Button>
                </Radio.Group>
              </Form.Item>
            )
          }

          {
            /* Recommande only */
            config.type === "recommande" && (
              <>
                <Form.Item
                  label="Accusé de réception"
                  name="receipt_aknowledge"
                  data-field-name="receipt_aknowledge"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez sélectionner une option d'accusé de réception",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="yes" defaultChecked>
                      Avec accusé de réception
                    </Radio.Button>
                    <Radio.Button value="no">Sans accusé de réception</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Niveau de recommandation"
                  name="level"
                  data-field-name="level"
                  rules={[
                    {
                      required: true,
                      message: "Veuillez sélectionner un niveau de recommandation",
                    },
                  ]}
                >
                  <Radio.Group buttonStyle="solid">
                    <Radio.Button value="R1" defaultChecked>
                      R1
                    </Radio.Button>
                    <Radio.Button value="R2">R2</Radio.Button>
                    <Radio.Button value="R3">R3</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {/*
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <Form.Item
                          name="receipt_aknowledge"
                          data-field-name="receipt_aknowledge"
                          rules={
                          [
                          { 
                          required: true,
                          message: 'Veuillez sélectionner une option d\'accusé de réception'
                           }
                          ]
                          }
                        >
                          <Select placeholder="Accusé de réception">
                            <Option value="yes">Avec accusé de réception</Option>
                            <Option value="no">Sans accusé de réception</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="level"
                          data-field-name="level"
                          rules={
                          [
                          { 
                          required: true,
                          message: 'Veuillez sélectionner un niveau de recommandation' 
                          }
                          ]
                          }
                        >
                          <Select placeholder="Niveau de recommandation">
                            <Option value="R1">R1</Option>
                            <Option value="R2">R2</Option>
                            <Option value="R3">R3</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  */}
              </>
            )
          }
        </Card>
      )}

      {/* Message parameters*/}
      <Card title="Paramètres d'envoi" type="inner" style={{ width: "100%", marginTop: 16 }}>
        {config.type === "electronique" && (
          <>
            <ErrorsComponent errors={formErrors} fields={["date"]} />
            {/*<div className="sending-type">*/}
            {/* Type ~ Radio box selector */}
            <Form.Item
              label="Type d'envoi"
              name="date_type"
              data-field-name="date_type"
              className="type-selector"
              rules={[{ required: true }]}
            >
              <Radio.Group onChange={checkDateError} buttonStyle="solid">
                <Radio.Button value="now" defaultChecked>
                  Envoi&nbsp;immédiat
                </Radio.Button>
                <Radio.Button value="deferred">Envoi&nbsp;différé</Radio.Button>
              </Radio.Group>
            </Form.Item>

            {
              /* Date ~ Datepicker */
              formValues.date_type === "deferred" && (
                <Form.Item
                  name="date"
                  data-field-name="date"
                  className={`date-picker ${formValues.date_type === "deferred" ? "visible" : ""}`}
                  rules={[
                    {
                      required: formValues.date_type === "deferred",
                      message: "Veuillez sélectionner une date d'envoi",
                    },
                  ]}
                >
                  <DatePicker
                    showToday={false}
                    placeholder="Date d'envoi"
                    disabledDate={disabledDate}
                    onChange={checkDateError}
                  />
                </Form.Item>
              )
            }
          </>
        )}

        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item name="ref1" data-field-name="ref1">
              <Input placeholder="Référence 1" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="ref2" data-field-name="ref2">
              <Input placeholder="Référence 2" />
            </Form.Item>
          </Col>
        </Row>
      </Card>

      {
        /* Object & Message */
        config.type === "electronique" && (
          <Card title="Message" type="inner" style={{ width: "100%", marginTop: 16 }}>
            <ErrorsComponent errors={formErrors} fields={["subject", "content"]} />
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Form.Item
                  name="subject"
                  rules={[
                    {
                      required: !config.printed,
                      message: "Veuillez renseigner le champ objet",
                    },
                  ]}
                >
                  <Input placeholder="Objet" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Form.Item
                  name="content"
                  rules={[
                    {
                      required: !config.printed,
                      message: "Le contenu du message ne peut pas être vide",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Contenu du message"
                    autoSize={{ minRows: 6, maxRows: 12 }}
                    style={{ resize: "none", transition: "none" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        )
      }
      <Card
        title="Vos instructions spécifiques"
        type="inner"
        style={{ width: "100%", marginTop: 16 }}
      >
        <Col>
          <Row style={{ opacity: 0.5 }}>
            ex : Agrafer des documents, inclure une référence dans une liasse recommandée, pièce
            jointe à insérer dans chaque pli.
          </Row>
          <Form.Item name="comment">
            <TextArea rows={4} />
          </Form.Item>
        </Col>
      </Card>

      <Row gutter={[8, 8]} style={{ marginTop: 8 }}>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Suivant
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
