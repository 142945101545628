import _ from "lodash";

//*
const defaultState = {
  date_type: "now",
  recipients: {
    contacts: [],
    lists: [],
  },
  attachments: [],
};
/*
  const defaultState = {
    date_type: 'now',
    recipients: {
      contacts: [
        {
          type: 'professional',
          first_name: 'LAURENT',
          email: 'laurent.lachaud@atelierducourrier.fr',
          phone: '0141952849 ',
          postal_code: '94100',
          last_name: 'LACHAUD',
          city: 'SAINT MAUR DES FOSSES',
          address_line_1: '125 BIS RUE GARIBALDI',
          key: 'aHzCleCJjNo4KmtW3eL9',
        },
      ],
      lists: [
        {
          list_name: 'ABC',
          contacts: [
            {
              last_name: 'Aboudalcabourou',
              city: 'Pontault-Combault',
              type: 'individual',
              postal_code: '77340',
              first_name: 'Haris',
              email: 'haris.aboudal@gmail.com',
              phone: '0634872645',
              address_line_1: '13 Rue de la Paix',
              key: '61N8B8N5rBhwBSifsF6p',
            },
            {
              email: 'karoline@atelierducourrier.fr',
              type: 'individual',
              city: 'Saint-Maur-des-Fossés',
              last_name: 'KAMPF',
              key: 'FeAQc4uhPR68gUUHqE8q',
              first_name: 'Karoline',
              postal_code: '94100',
              address_line_1: '11 avenue Auguste Gross',
              phone: '0638871615',
            },
            {
              phone: '0715468975',
              key: 'M1Na5zQUHRILGrFgGifD',
              email: 'nico.tournois@live.fr',
              first_name: 'Nicolas',
              address_line_1: '69 Avenue De La République',
              type: 'individual',
              address_line_2: 'Appartement 15, Porte 4',
              postal_code: '75008',
              city: 'Paris',
              last_name: 'Tournois',
            },
          ],
          key: 'UpOet4KKR1dLKPz3ljYb',
          contactsMerged: 'haris.aboudal@gmail.com karoline@atelierducourrier.fr nico.tournois@live.fr',
        },
      ],
    },
    attachments: [],
    color_option: 'color',
    face_option: 'double_face',
    stapling: 'yes',
    envelope: 'Lettre prioritaire',
  };
*/

const messageReducer = {
  messagePayload: (state = _.clone(defaultState), { type, payload }) => {
    switch (type) {
      case "EMAIL_FORM_SUBMITTED":
        console.log("EMAIL FORM SUBMITTED", state);
        return { ...state, ...payload };
      case "GET_EMAIL_FORM_VALUES":
        return state;
      case "RESET_FORM_VALUES":
        return _.clone(defaultState);
      default:
        return state;
    }
  },
};

export default messageReducer;
