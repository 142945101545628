import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { shallowEqual, useSelector } from 'react-redux';

// Firebase
import 'firebase/firestore';

import AccountsScreen from './Accounts';
import UsersScreen from './Users';
import StatisticsScreen from './Statistics';
import SentScreen from './Sent';

export default function () {
  const user = useSelector((state) => state.user, shallowEqual);

  return user.isAdmin || true
    ? (
      <Switch>
        <Route path="/admin/comptes">
          <AccountsScreen />
        </Route>
        <Route path="/admin/utilisateurs">
          <UsersScreen />
        </Route>
        <Route path="/admin/statistiques">
          <StatisticsScreen />
        </Route>
        <Route path="/admin/sent">
          <SentScreen />
        </Route>
      </Switch>
    )
    : <Redirect to="/" />;
}
