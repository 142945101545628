import React from "react";
import { Layout } from "antd";

const { Footer: LayoutFooter } = Layout;

const Footer = () => {
  return (
    <LayoutFooter style={{ textAlign: "center" }}>Solidoc ©{new Date().getFullYear()}</LayoutFooter>
  );
};

export default Footer;
