import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Firebase
import 'firebase/firestore';

import AccountScreen from './Account';
import BusinessScreen from './Business';
import TeamScreen from './Team';

export default function () {
  return (
    <Switch>
      <Route path="/parametres/compte">
        <AccountScreen />
      </Route>
      <Route path="/parametres/entreprise">
        <BusinessScreen />
      </Route>
      <Route path="/parametres/equipe">
        <TeamScreen />
      </Route>
    </Switch>
  );
}
