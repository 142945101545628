const layoutReducer = {
  sideMenuCollapsed: (state = false, action) => {
    switch (action.type) {
      case "SET_SIDE_MENU_VISIBILITY":
        return action.payload;
      case "TOGGLE_SIDE_MENU_VISIBILITY":
        return !state;
      default:
        return state;
    }
  },
  generalLoading: (state = { isLoading: false, message: null }, action) => {
    switch (action.type) {
      case "SHOW_LOADER":
        return { isLoading: true, message: action.payload || null };
      case "HIDE_LOADER":
        return { isLoading: false, message: null };
      default:
        return state;
    }
  },
};

export default layoutReducer;
