import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  EditFilled,
  ContactsFilled,
  LaptopOutlined,
  LoginOutlined,
  HistoryOutlined,
  PieChartFilled,
  ToolFilled,
  UserOutlined,
  TeamOutlined,
  ShopOutlined,
  SettingFilled,
  MailFilled,
} from "@ant-design/icons";

import { Layout, Menu } from "antd";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideMenu = () => {
  // Local state
  const [broken, setBroken] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState();

  // Redux state
  const sideMenuCollapsed = useSelector((state) => state.sideMenuCollapsed);
  const user = useSelector((state) => state.user, shallowEqual);

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    if (location && location.pathname) {
      const split = location.pathname.split("/");
      const keys = [];

      split.reduce((acc, subpath) => {
        const path = `${acc}/${subpath}`;
        keys.push(path);

        return path;
      });

      setSelectedKeys(keys);
    }
  }, [location]);

  function breakpointHandler(isBroken) {
    setBroken(isBroken);
    dispatch({ type: "SET_SIDE_MENU_VISIBILITY", payload: isBroken });
  }

  function backdropClickHandle() {
    dispatch({ type: "TOGGLE_SIDE_MENU_VISIBILITY" });
  }

  function menuItemClickHandle() {
    if (broken) {
      dispatch({ type: "SET_SIDE_MENU_VISIBILITY", payload: true });
    }
  }

  return (
    <>
      <Sider
        id="side-menu"
        trigger={null}
        breakpoint="md"
        onBreakpoint={breakpointHandler}
        collapsible
        collapsedWidth={broken ? 0 : 80}
        collapsed={sideMenuCollapsed}
        theme="dark"
      >
        <div id="logo" className={sideMenuCollapsed ? "small" : ""} />
        <Menu theme="dark" mode="inline" selectedKeys={selectedKeys} onClick={menuItemClickHandle}>
          <Menu.Item key="/envoi">
            <Link to="/envoi">
              <EditFilled />
              <span>Nouvel envoi</span>
            </Link>
          </Menu.Item>
          <SubMenu key="/historique" icon={<HistoryOutlined />} title="Vos envois">
            <Menu.Item key="/historique/electronique">
              <Link to="/historique/electronique">
                <LaptopOutlined />
                <span>Electroniques</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/historique/lettres-simples">
              <Link to="/historique/lettres-simples">
                <MailFilled />
                <span>Lettres simples</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/historique/lettres-recommandees">
              <Link to="/historique/lettres-recommandees">
                <MailFilled />
                <span>Lettres recommandées</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu key="/carnet" icon={<ContactsFilled />} title="Carnet d'adresses">
            <Menu.Item key="/carnet/contacts">
              <Link to="/carnet/contacts">
                <ContactsFilled />
                <span>Contacts</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/carnet/listes">
              <Link to="/carnet/listes">
                <ContactsFilled />
                <span>Listes de diffusion</span>
              </Link>
            </Menu.Item>
          </SubMenu>

          <Menu.Item
            id="sign-off"
            key="/signoff"
            onClick={() => {
              firebase.auth().signOut();
            }}
          >
            <LoginOutlined />
            <span>Se déconnecter</span>
          </Menu.Item>
          <Menu.Item key="/statistiques">
            <Link to="/statistiques">
              <PieChartFilled />
              <span>Statistiques</span>
            </Link>
          </Menu.Item>
          <SubMenu key="/parametres" icon={<ToolFilled />} title="Paramètres">
            <Menu.Item key="/parametres/compte">
              <Link to="/parametres/compte">
                <UserOutlined />
                <span>Mon compte</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/parametres/entreprise">
              <Link to="/parametres/entreprise">
                <ShopOutlined />
                <span>Mon entreprise</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/parametres/equipe">
              <Link to="/parametres/equipe">
                <TeamOutlined />
                <span>Mon équipe</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          {(user.isAdmin || user.isOperator) && (
            <SubMenu key="/admin" icon={<SettingFilled />} title="Administration">
              {user.isAdmin && (
                <Menu.Item key="/admin/comptes">
                  <Link to="/admin/comptes">
                    <TeamOutlined />
                    <span>Comptes</span>
                  </Link>
                </Menu.Item>
              )}
              {user.isAdmin && (
                <Menu.Item key="/admin/utilisateurs">
                  <Link to="/admin/utilisateurs">
                    <TeamOutlined />
                    <span>Utilisateurs</span>
                  </Link>
                </Menu.Item>
              )}
              {(user.isAdmin || user.isOperator) && (
                <Menu.Item key="/admin/sent">
                  <Link to="/admin/sent">
                    <MailFilled />
                    <span>Envois papiers</span>
                  </Link>
                </Menu.Item>
              )}
              {user.isAdmin && (
                <Menu.Item key="/admin/statistiques">
                  <Link to="/admin/statistiques">
                    <PieChartFilled />
                    <span>Statistiques</span>
                  </Link>
                </Menu.Item>
              )}
            </SubMenu>
          )}
        </Menu>
      </Sider>

      {/* Side menu backdrop */}
      <div id="menu-backdrop" onClick={backdropClickHandle} />
    </>
  );
};

export default SideMenu;
