// React and ReactDOM
import React from "react";
import ReactDOM from "react-dom";

// Firebase
import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

// CSS
import "antd/dist/antd.css";
import "./stylesheet.scss";

// Components and 3rd party
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

import moment from "moment-timezone";

moment.tz.setDefault("Europe/Paris");

console.log(process.env);
// Configure Firebase
const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

console.log(process.env.NODE_ENV);

// DEV ONLY
// firebase.functions().useFunctionsEmulator('http://localhost:5001');

firebaseApp.functions("europe-west1");

firebaseApp.analytics();

// Get a reference to the Firebase Firestore service
const db = firebase.firestore();
db.settings({ ignoreUndefinedProperties: true });

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
