import React from 'react';
import { Avatar } from 'antd';

// Icons
import {
  FileOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileWordOutlined,
} from '@ant-design/icons';

const iconStyle = {
  marginRight: 0,
  color: 'rgba(0,0,0,0.65)',
  backgroundColor: 'transparent',
};

export default function getFileIcon(mimetype) {
  let icon = <FileOutlined />;

  if (/^image/.test(mimetype)) {
    icon = <FileImageOutlined />;
  } else if (/^application\/pdf/.test(mimetype)) {
    icon = <FilePdfOutlined />;
  } else if (/^application\/msword/.test(mimetype)) {
    icon = <FileWordOutlined />;
  }

  return <Avatar icon={icon} size={50} style={iconStyle} />;
}
