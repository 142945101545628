const defaultState = [];

const historyReducer = {
  messagesHistory: (state = defaultState, { type, payload }) => {
    switch (type) {
      case "FETCH_MESSAGES_HISTORY":
        return payload;
      case "CLEAR_MESSAGES_HISTORY":
        return [];
      default:
        return state;
    }
  },
};

export default historyReducer;
