import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
} from 'antd';

import { Link, useLocation, useHistory } from 'react-router-dom';

// Icons
import { LockOutlined } from '@ant-design/icons';

// Firebase
import * as firebase from 'firebase/app';
import 'firebase/auth';

// Lodash
import qs from 'qs';

export default function () {
  const [password, setPassword] = useState('');
  const [passwordReset, setPasswordReset] = useState(false);
  const query = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = qs.parse(query.search);

    firebase
      .auth()
      .verifyPasswordResetCode(params.oobCode)
      .then((email) => {
        console.log('EMAIL:', email);
      })
      .catch((e) => {
        console.error(e);
        history.replace('/login');
      });
  }, []);

  const resetPassword = (values) => {
    const params = qs.parse(query.search);

    firebase
      .auth()
      .confirmPasswordReset(params.oobCode, values.password)
      .then(() => {
        setPasswordReset(true);
      });
  };

  const validatePassword = (rule, value, callback) => {
    if (value && value !== password) {
      callback('Les mots de passes sont différents');
    } else {
      callback();
    }
  };

  return (
    <>
      <div id="login_container">
        <div id="logo" />
        <Form
          name="login"
          initialValues={{
            remember: true,
          }}
          onFinish={resetPassword}
        >
          <h3>Réinitialisation du mot de passe</h3>
          {
            passwordReset
              ? (
                <>
                  <Alert
                    message="Votre mot de passe à bien été réinitialisé"
                    type="info"
                    showIcon
                    style={{ marginBottom: 16 }}
                  />
                  <Link to="/login">
                    <Button
                      type="secondary"
                      className="login-form-button"
                    >
                      Se connecter
                    </Button>
                  </Link>
                </>
              )
              : (
                <>
                  <Form.Item
                    name="password"
                    rules={[
                      { min: 6, message: 'Votre mot de passe doit contenir au moins 6 caractères' },
                      { required: true, message: 'Veuillez entrer un mot de passe' },
                    ]}
                  >
                    <Input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Mot de passe"
                      type="password"
                      name="password"
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    rules={[
                      { required: true, message: 'Veuillez confirmer votre mot de passe' },
                      { validator: validatePassword },
                    ]}
                  >
                    <Input
                      prefix={<LockOutlined className="site-form-item-icon" />}
                      placeholder="Confirmer mot de passe"
                      type="password"
                      name="confirm"
                    />
                  </Form.Item>

                  <Form.Item style={{ margin: '16px 0 8px' }}>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                      Réinitialiser
                    </Button>
                  </Form.Item>
                </>
              )
          }
        </Form>
      </div>
    </>
  );
}
