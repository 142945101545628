import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Spin } from 'antd';

import AcceptMessage from './AcceptMessage';
import RefuseMessage from './RefuseMessage';

export default function () {
  const { action } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  console.log(useParams());

  return (
    <Spin tip="Récupération du recommandé..." spinning={isLoading} wrapperClassName="general-loading" id="message-loader" style={{ width: '100%', paddingTop: 16 }}>
      {
        // eslint-disable-next-line no-nested-ternary
        action === 'accepter'
          ? (<AcceptMessage setLoader={setIsLoading} />)
          : (
            action === 'refuser'
              ? (<RefuseMessage setLoader={setIsLoading} />)
              : null
          )
      }

    </Spin>
  );
}
