import { combineReducers } from 'redux';

import layoutReducer from './reducers/layoutReducer';
import messageReducer from './reducers/messageReducer';
import contactReducer from './reducers/contactReducer';
import userReducer from './reducers/userReducer';
import historyReducer from './reducers/historyReducer';

export default combineReducers({
  sideMenuCollapsed: layoutReducer.sideMenuCollapsed,
  generalLoading: layoutReducer.generalLoading,
  messagePayload: messageReducer.messagePayload,
  contacts: contactReducer.contacts,
  user: userReducer.user,
  messagesHistory: historyReducer.messagesHistory,
});
