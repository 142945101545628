import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Upload,
  message,
  Descriptions,
  List,
  Avatar,
  Checkbox,
} from "antd";

import { useHistory } from "react-router-dom";

import _ from "lodash";
import moment from "moment";

// Firebase
import * as firebase from "firebase";

import getFileIcon from "../../../../modules/helpers/fileIcon";

export default function Attachments({ config }) {
  const [accept, setAccept] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [normalizedRecipients, setNormalizedRecipients] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();

  const formValues = useSelector((state) => state.messagePayload, shallowEqual);
  const user = useSelector((state) => state.user, shallowEqual);

  useEffect(() => {
    setNormalizedRecipients(
      _.uniqBy(
        [..._.flatMap(formValues.recipients.lists, "contacts"), ...formValues.recipients.contacts],
        "key"
      )
    );
  }, [formValues.recipients]);

  // Configure Firestore
  const db = firebase.firestore();

  function clickBack() {
    history.goBack();
  }

  return (
    <>
      {/*<div>{JSON.stringify(formValues)}</div>*/}
      {config.printed && (
        <Descriptions bordered title="Options d'impression" size="small" column={6}>
          <Descriptions.Item label="Paramètre couleur" span={3}>
            {formValues.color_option === "black_white" ? "Noir/Blanc" : "Couleur"}
          </Descriptions.Item>
          <Descriptions.Item label="Recto ou Recto/Verso" span={3}>
            {formValues.face_option === "one_face" ? "Recto seulement" : "Recto/Verso"}
          </Descriptions.Item>
          {formValues.recipients_type && (
            <Descriptions.Item label="Type de destinataires" span={3}>
              {formValues.recipients_type === "unique"
                ? "Destinataire unique"
                : "Destinataires multiples"}
            </Descriptions.Item>
          )}
          {config.type === "simple" ? (
            <Descriptions.Item label="Délai de distribution" span={3}>
              {formValues.envelope}
            </Descriptions.Item>
          ) : (
            <>
              <Descriptions.Item label="Accusé de réception" span={3}>
                {formValues.receipt_aknowledge === "yes"
                  ? "Avec accusé de réception"
                  : "Sans accusé de réception"}
              </Descriptions.Item>
              <Descriptions.Item label="Niveau de recommandation" span={6}>
                {formValues.level}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      )}

      {config.type === "electronique" && (
        <>
          <Descriptions
            bordered
            style={config.printed ? { marginTop: 32 } : {}}
            title="Options d'envoi"
            size="small"
            column={4}
          >
            <Descriptions.Item label="Type d'envoi" span={formValues.date_type === "now" ? 4 : 2}>
              {formValues.date_type === "now" ? "Immédiat" : "Différé"}
            </Descriptions.Item>
            {formValues.date_type !== "now" && formValues.date && (
              <Descriptions.Item label="Date d'envoi" span={2}>
                <span style={{ textTransform: "capitalize" }}>
                  {formValues.date.format("dddd D MMMM YYYY")}
                </span>
                &nbsp;({formValues.date.fromNow()})
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Référence 1" span={2}>
              {formValues.ref1 || "Aucune"}
            </Descriptions.Item>
            <Descriptions.Item label="Référence 2" span={2}>
              {formValues.ref2 || "Aucune"}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions style={{ marginTop: 32 }} title="Destinataires" />
          <Card type="inner" style={{ width: "100%" }} bodyStyle={{ padding: "8px 24px 0" }}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                {(() => {
                  console.log("recipients", normalizedRecipients);

                  return "";
                })()}
                <List
                  itemLayout="horizontal"
                  dataSource={normalizedRecipients}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={`${item.first_name} ${item.last_name}`}
                        description={
                          <div>
                            <div>
                              {item.type === "individual" ? "Particulier" : "Professionnel"}
                            </div>
                            {config.printed ? (
                              <>
                                <div>{item.address_line_1}</div>
                                {item.address_line_2 && <div>{item.address_line_2}</div>}
                                <div>
                                  {item.city}, {item.postal_code}
                                </div>
                              </>
                            ) : (
                              <div>{item.email}</div>
                            )}
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Card>
        </>
      )}

      <Descriptions
        style={{ marginTop: 32 }}
        title={!config.printed ? "Pièces jointes" : "Documents"}
      />
      <Card type="inner" style={{ width: "100%" }} bodyStyle={{ padding: "8px 24px 0" }}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={
                formValues.attachments.length
                  ? formValues.attachments
                  : [{ filename: "Aucune pièce jointe" }]
              }
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={formValues.attachments.length ? getFileIcon(item.mimetype) : null}
                    title={item.filename}
                    description={
                      formValues.attachments.length ? (
                        <a href={item.url} rel="noopener noreferrer" target="_blank">
                          Ouvrir le fichier
                        </a>
                      ) : null
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>

      {!config.printed && (
        <Descriptions
          bordered
          title="Contenu du message"
          size="small"
          column={4}
          style={{ marginTop: 32 }}
        >
          <Descriptions.Item label="Objet" span={4}>
            {formValues.subject}
          </Descriptions.Item>
          <Descriptions.Item label="Message" span={4} style={{ whiteSpace: "pre-line" }}>
            {formValues.content}
          </Descriptions.Item>
        </Descriptions>
      )}

      <Row gutter={[8, 8]} style={{ marginTop: 16 }}>
        <Col span={24} style={{ textAlign: "right" }}>
          <Checkbox
            onChange={(e) => {
              setAccept(e.target.checked);
            }}
          >
            Je confirme avoir bien pris connaissance des informations et des{" "}
            <a target="_blank" href="https://s3.eu-west-3.amazonaws.com/www.solidoc.fr/cgv.pdf">
              conditions générales de vente
            </a>
          </Checkbox>
        </Col>
        <Col span={24} style={{ textAlign: "right" }}>
          <Form.Item>
            <Button disabled={isSubmitting} onClick={clickBack} style={{ marginRight: "8px" }}>
              Précédent
            </Button>
            <Button
              disabled={!accept}
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              onClick={() => {
                setIsSubmitting(true);

                const initialStatus = formValues.date_type === "now" ? "prepare" : "scheduled";

                console.log({ formValues });
                const payload = {
                  ..._.pick(formValues, config.pickedKeys),
                  pages:
                    formValues.attachments.reduce((total, a) => total + (a.pages || 1), 0) || 1,
                  type: config.type,
                  user: user.userRef,
                  created_at: firebase.firestore.FieldValue.serverTimestamp(),
                  date:
                    formValues.date_type === "now"
                      ? firebase.firestore.FieldValue.serverTimestamp()
                      : formValues.date &&
                        firebase.firestore.Timestamp.fromDate(formValues.date.toDate()),
                };
                console.log({ payload });

                user.company
                  .collection("messages")
                  .add(payload)
                  .then((messageRef) => {
                    console.log("Message ref", messageRef);
                    const batch = db.batch();

                    let recipients = [];

                    if (config.type === "electronique") {
                      recipients = _.map(normalizedRecipients, (contact) => {
                        const cloned = _.clone(contact);

                        cloned.last_updated = firebase.firestore.FieldValue.serverTimestamp();
                        cloned.status = initialStatus;
                        cloned.company = user.company;
                        cloned.message = messageRef;
                        cloned.created_at = firebase.firestore.FieldValue.serverTimestamp();
                        cloned.user = user.userRef;
                        cloned.send_date =
                          formValues.date_type === "now"
                            ? null
                            : formValues.date &&
                              firebase.firestore.Timestamp.fromDate(formValues.date.toDate());

                        return cloned;
                      });
                    } else {
                      recipients.push({
                        last_updated: firebase.firestore.FieldValue.serverTimestamp(),
                        status: initialStatus,
                        company: user.company,
                        message: messageRef,
                        created_at: firebase.firestore.FieldValue.serverTimestamp(),
                        user: user.userRef,
                        send_date: null,
                        send_type: config.type,
                      });
                    }

                    recipients.forEach((recipient) => {
                      batch.set(messageRef.collection("recipients").doc(), recipient);
                    });

                    batch.commit().then(() => {
                      const b64 = btoa(
                        formValues.date_type === "now"
                          ? messageRef.id
                          : `${messageRef.id}:${formValues.date.format("YYYY-MM-DD")}`
                      );

                      dispatch({ type: "RESET_FORM_VALUES" });
                      history.push(`/envoi/${config.type}/success/${b64}`);
                    });
                  });
              }}
            >
              Envoyer
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}
