import React, { useEffect, useState } from "react";

import { Table, Button, message, Select, Tabs, Modal } from "antd";

import { Chart } from "@antv/g2";

// React-Redux
import { useDispatch } from "react-redux";

// Firebase
import * as firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import _ from "lodash";

const { TabPane } = Tabs;

function sorterFn(property) {
  return (a, b) => `${a[property]}`.toLowerCase().localeCompare(`${b[property]}`.toLowerCase());
}

function sorterNumFn(property) {
  return (a, b) => +a[property] - +b[property];
}

// Table configuration
const individualColumns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Type",
    children: [
      {
        title: "Electroniques",
        dataIndex: "electronique",
        key: "electronique",
      },
      {
        title: "Envois simples",
        dataIndex: "simple",
        key: "simple",
      },
      {
        title: "Envois recommandés",
        dataIndex: "recommande",
        key: "recommande",
      },
    ],
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
  },
];

const yearOptions = [];
const currentYear = moment().year();
for (let i = 2020; i <= currentYear; i++) {
  yearOptions.push(i);
}

export default function () {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loadingTable, setLoadingTable] = useState(true);

  const [stackedBarData, setStackedBarData] = useState([]);
  const [tableStatsData, setTableStatsData] = useState([]);
  const [stackBarPlot, setStackBarPlot] = useState(null);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const [statsType, setStatsType] = useState("global");
  const [selectedFields, setSelectedFields] = useState([
    "Type d'envoi",
    "Date d'envoi",
    "Type de destinataire",
    "Nom du destinataire",
    "Prénom du destinataire",
    "Email du destinataire",
    "Statut de l'envoi",
    "Date de dernier status",
  ]);
  const [exportedYears, setExportedYears] = useState([currentYear]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAmountType, setSelectedAmountType] = useState("send"); // send || pages

  const getUserStats = firebase.app().functions("europe-west1").httpsCallable("getUserStatsCSV");

  const dispatch = useDispatch();

  // Get contacts
  const db = firebase.firestore();
  const messageTypes = {
    recommande: "Envois recommandés",
    simple: "Envois simples",
    electronique: "Electroniques",
  };

  const globalColumns = [
    {
      title: "Compte",
      dataIndex: "company",
      key: "company",
      sorter: sorterFn("company"),
      render: (company, record) => (
        <Button
          style={{ color: "#000", fontWeight: "bold" }}
          type="link"
          onClick={() => {
            setStatsType("individual");
            setSelectedCompany(_.find(companies, { id: record.key }));
          }}
        >
          {company}
        </Button>
      ),
    },
    {
      title: "Type",
      children: [
        {
          title: "Electroniques",
          dataIndex: "electronique",
          key: "electronique",
          sorter: sorterNumFn("electronique"),
        },
        {
          title: "Envois simples",
          dataIndex: "simple",
          key: "simple",
          sorter: sorterNumFn("simple"),
        },
        {
          title: "Envois recommandés",
          dataIndex: "recommande",
          key: "recommande",
          sorter: sorterNumFn("recommande"),
        },
      ],
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      sorter: sorterNumFn("total"),
      defaultSortOrder: "descend",
    },
  ];

  useEffect(() => {
    db.collection("companies")
      .get()
      .then((companiesSnapshot) => {
        setCompanies(
          companiesSnapshot.docs.map((snap) => {
            const company = snap.data();

            return {
              ...company,
              ref: snap.ref,
              key: snap.id,
              id: snap.id,
            };
          })
        );
      });
  }, []);

  async function fetchMessages(company) {
    if (statsType === "global") {
      const msgCache = {};
      const messageStats = {};
      const total = {};
      const companies = {};
      console.log(selectedYear);
      const msgSnapShot = await db
        .collectionGroup("messages")
        .where("created_at", ">", new Date(`${selectedYear}-01-01`))
        .where("created_at", "<", new Date(`${selectedYear}-12-31`))
        .get();
      msgSnapShot.forEach((m) => {
        console.log(m.data());
        msgCache[m.id] = {
          ...m.data(),
          id: m.id,
        };
      });

      console.log({ msgCache });

      db.collectionGroup("recipients")
        .get()
        .then((recipientsSnapshot) => {
          db.collectionGroup("companies")
            .get()
            .then((companiesSnapshot) => {
              companiesSnapshot.docs.forEach((companySnap) => {
                companies[companySnap.id] = companySnap.data().name;
              });

              recipientsSnapshot.docs.forEach((recipient) => {
                const data = recipient.data();
                if (data.created_at) {
                  const date = data.created_at ? moment(data.created_at.toDate()) : null;
                  const month = date.month();
                  const year = date.year();
                  console.log({ date });

                  if (!messageStats[data.company.id]) {
                    messageStats[data.company.id] = {
                      name: companies[data.company.id],
                    };
                  }

                  if (!total[year]) {
                    total[year] = {
                      0: {},
                      1: {},
                      2: {},
                      3: {},
                      4: {},
                      5: {},
                      6: {},
                      7: {},
                      8: {},
                      9: {},
                      10: {},
                      11: {},
                    };
                  }

                  if (!messageStats[data.company.id][year]) {
                    messageStats[data.company.id][year] = {};
                  }

                  if (!messageStats[data.company.id][year][month]) {
                    messageStats[data.company.id][year][month] = {};
                  }

                  // const amount = data.contacts.length;
                  //console.log(data.message.id);
                  const amount =
                    selectedAmountType === "send" ? 1 : msgCache[data.message.id]?.pages || 1;
                  // console.log({ msg: msgCache[data.message.id], id: data.message.id });
                  const type = data.send_type || "electronique";

                  total[year][month][type] = total[year][month][type] + amount || amount;
                  total[year][month].total = total[year][month].total + amount || amount;

                  messageStats[data.company.id][year][month][type] =
                    messageStats[data.company.id][year][month][type] + amount || amount;
                  messageStats[data.company.id][year][month].total =
                    messageStats[data.company.id][year][month].total + amount || amount;
                }
              });

              // Populate chart and table data
              const chartData = [];
              const tableData = [];

              const monthLimit = 11;

              for (let i = monthLimit; i >= 0; i--) {
                const dateTitle = `${_.capitalize(
                  moment(i + 1, "MM").format("MMMM")
                )} ${selectedYear}`;
                chartData.push({
                  Date: dateTitle,
                  Type: "Total",
                  Total:
                    (total[selectedYear] &&
                      total[selectedYear][i] &&
                      total[selectedYear][i].total) ||
                    0, // (messageStats[selectedYear] && messageStats[selectedYear][i] && messageStats[selectedYear][i][t]) ? messageStats[selectedYear][i][t] : 0,
                });
              }

              for (const t in messageTypes) {
                for (let i = monthLimit; i >= 0; i--) {
                  const dateTitle = `${_.capitalize(
                    moment(i + 1, "MM").format("MMMM")
                  )} ${selectedYear}`;
                  chartData.push({
                    Date: dateTitle,
                    Type: messageTypes[t],
                    Nb:
                      (total[selectedYear] &&
                        total[selectedYear][i] &&
                        total[selectedYear][i][t]) ||
                      0, // (messageStats[selectedYear] && messageStats[selectedYear][i] && messageStats[selectedYear][i][t]) ? messageStats[selectedYear][i][t] : 0,
                  });
                }
              }

              console.log({ messageStats });

              for (const c in messageStats) {
                tableData.push({
                  key: c,
                  company: messageStats[c].name,
                  recommande:
                    messageStats[c][selectedYear] &&
                    messageStats[c][selectedYear][selectedMonth] &&
                    messageStats[c][selectedYear][selectedMonth].recommande
                      ? messageStats[c][selectedYear][selectedMonth].recommande
                      : 0,
                  simple:
                    messageStats[c][selectedYear] &&
                    messageStats[c][selectedYear][selectedMonth] &&
                    messageStats[c][selectedYear][selectedMonth].simple
                      ? messageStats[c][selectedYear][selectedMonth].simple
                      : 0,
                  electronique:
                    messageStats[c][selectedYear] &&
                    messageStats[c][selectedYear][selectedMonth] &&
                    messageStats[c][selectedYear][selectedMonth].electronique
                      ? messageStats[c][selectedYear][selectedMonth].electronique
                      : 0,
                  total:
                    messageStats[c][selectedYear] &&
                    messageStats[c][selectedYear][selectedMonth] &&
                    messageStats[c][selectedYear][selectedMonth].total
                      ? messageStats[c][selectedYear][selectedMonth].total
                      : 0,
                });
              }

              setStackedBarData(chartData.reverse());
              setTableStatsData(tableData.reverse());
              setLoadingTable(false);
            });
        });
    } else {
      if (!company) {
        return;
      }

      db.collectionGroup("recipients")
        .where("company", "==", company.ref)
        .get()
        .then((snapshot) => {
          const messageStats = {};

          snapshot.docs.forEach((recipient) => {
            const data = recipient.data();

            const date = moment(data.created_at.toDate());
            const month = date.month();
            const year = date.year();

            if (!messageStats[year]) {
              messageStats[year] = {};
            }

            if (!messageStats[year][month]) {
              messageStats[year][month] = {};
            }

            // const amount = data.contacts.length;
            console.log({ data });
            const amount = 1;
            const type = data.send_type;

            messageStats[year][month][type] = messageStats[year][month][type] + amount || amount;
            messageStats[year][month].total = messageStats[year][month].total + amount || amount;
          });

          // Populate chart and table data
          const chartData = [];
          const tableData = [];

          const monthLimit = 11;
          for (let i = monthLimit; i >= 0; i--) {
            const dateTitle = `${_.capitalize(moment(i + 1, "MM").format("MMMM"))} ${selectedYear}`;

            chartData.push({
              Date: dateTitle,
              Type: "Total",
              Total:
                messageStats[selectedYear] &&
                messageStats[selectedYear][i] &&
                messageStats[selectedYear][i].total
                  ? messageStats[selectedYear][i].total
                  : 0,
            });
          }
          for (const t in messageTypes) {
            for (let i = monthLimit; i >= 0; i--) {
              const dateTitle = `${_.capitalize(
                moment(i + 1, "MM").format("MMMM")
              )} ${selectedYear}`;

              chartData.push({
                Date: dateTitle,
                Type: messageTypes[t],
                Nb:
                  messageStats[selectedYear] &&
                  messageStats[selectedYear][i] &&
                  messageStats[selectedYear][i][t]
                    ? messageStats[selectedYear][i][t]
                    : 0,
              });

              if (tableData.length <= monthLimit) {
                tableData.push({
                  key: i,
                  date: dateTitle,
                  recommande:
                    messageStats[selectedYear] &&
                    messageStats[selectedYear][i] &&
                    messageStats[selectedYear][i].recommande
                      ? messageStats[selectedYear][i].recommande
                      : 0,
                  simple:
                    messageStats[selectedYear] &&
                    messageStats[selectedYear][i] &&
                    messageStats[selectedYear][i].simple
                      ? messageStats[selectedYear][i].simple
                      : 0,
                  electronique:
                    messageStats[selectedYear] &&
                    messageStats[selectedYear][i] &&
                    messageStats[selectedYear][i].electronique
                      ? messageStats[selectedYear][i].electronique
                      : 0,
                  total:
                    messageStats[selectedYear] &&
                    messageStats[selectedYear][i] &&
                    messageStats[selectedYear][i].total
                      ? messageStats[selectedYear][i].total
                      : 0,
                });
              }
            }
          }

          setStackedBarData(chartData.reverse());
          setTableStatsData(tableData.reverse());
          setLoadingTable(false);
        });
    }
  }

  useEffect(() => {
    fetchMessages(selectedCompany);
  }, [selectedCompany, selectedYear, selectedMonth, selectedAmountType]);

  useEffect(() => {
    if (stackedBarData.length) {
      buildChart(stackedBarData);
    }
  }, [stackedBarData]);

  function buildChart(data) {
    if (stackBarPlot) {
      stackBarPlot.destroy();
    }

    const maxValue = _.maxBy(data, "Nb").Nb;

    const chart = new Chart({
      container: "chart-stacked-bar",
      autoFit: true,
      height: 300,
    });

    chart.data(data);
    chart.scale("Nb", {
      alias: "Nombre d'envois",
      nice: true,
      tickCount: maxValue < 10 ? maxValue + 1 : 10,
    });

    chart.axis("Date", {
      tickLine: null,
    });

    chart.tooltip({
      showMarkers: false,
      shared: true,
    });
    chart.legend(false);

    chart.interaction("active-region");
    chart
      .interval()
      .adjust("stack")
      .position({ fields: ["Date", "Nb"] })
      .tooltip("Type*Nb*Total", (type, nb, total) => {
        return {
          name: type,
          value: nb || total || 0,
        };
      })
      .color("Type", ["#40a9ff", "#1890ff", "#096dd9", "#0050b3"]);

    /*.style("Date", (val) => {
        const selectedDate = `${moment().month(selectedMonth).format("MMMM")} ${selectedYear}`;
        return {
          fill: val.toLowerCase() === selectedDate ? "#ffc107" : "#1890ff",
        };
      });*/

    chart.render();

    setStackBarPlot(chart);
  }

  return (
    <>
      <h1>Statistiques</h1>
      <h2>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div>
            Envois sur l&apos;année&nbsp;
            <Select
              placeholder="Type"
              onChange={(value) => setSelectedYear(value)}
              value={selectedYear}
            >
              <Select.Option value="2022">2022</Select.Option>
              <Select.Option value="2021">2021</Select.Option>
              <Select.Option value="2020">2020</Select.Option>
            </Select>
          </div>
          <div>
            Trier par&nbsp;
            <Select
              placeholder="Type"
              onChange={(value) => setSelectedAmountType(value)}
              value={selectedAmountType}
            >
              <Select.Option value="send">Envois</Select.Option>
              <Select.Option value="pages">Pages</Select.Option>
            </Select>
          </div>
        </div>
      </h2>

      <Tabs
        activeKey={statsType}
        onChange={(value) => {
          if (value === "global") {
            setSelectedCompany(null);
          }
          setStatsType(value);
        }}
      >
        <TabPane tab="Globaux" key="global" />
        <TabPane tab="Individuels" key="individual" />
      </Tabs>

      {statsType === "individual" && (
        <Select
          style={{ marginBottom: 16, width: "100%" }}
          showSearch
          placeholder="Sélectionnez un compte"
          optionFilterProp="children"
          onChange={(value) => {
            setSelectedCompany(_.find(companies, { id: value }));
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={selectedCompany && selectedCompany.key}
        >
          {_.sortBy(companies, "name").map((company) => (
            <Select.Option value={company.id} key={company.key}>
              {company.name}
            </Select.Option>
          ))}
        </Select>
      )}

      {(statsType === "global" || (statsType === "individual" && selectedCompany)) && (
        <div id="chart-stacked-bar" />
      )}

      {statsType === "global" && (
        <h3 style={{ marginTop: 16 }}>
          Envois du mois de&nbsp;
          <Select onChange={(value) => setSelectedMonth(value)} value={selectedMonth.toString()}>
            <Select.Option value="0">Janvier</Select.Option>
            <Select.Option value="1">Février</Select.Option>
            <Select.Option value="2">Mars</Select.Option>
            <Select.Option value="3">Avril</Select.Option>
            <Select.Option value="4">Mai</Select.Option>
            <Select.Option value="5">Juin</Select.Option>
            <Select.Option value="6">Juillet</Select.Option>
            <Select.Option value="7">Août</Select.Option>
            <Select.Option value="8">Septembre</Select.Option>
            <Select.Option value="9">Octobre</Select.Option>
            <Select.Option value="10">Novembre</Select.Option>
            <Select.Option value="11">Décembre</Select.Option>
          </Select>
        </h3>
      )}

      {((statsType === "individual" && selectedCompany) || statsType === "global") && (
        <>
          <Table
            style={{ marginTop: 16 }}
            columns={statsType === "global" ? globalColumns : individualColumns}
            loading={loadingTable}
            dataSource={tableStatsData}
            bordered
            size="middle"
            pagination={false}
          />

          {statsType === "individual" && (
            <Button
              type="default"
              style={{ float: "right", marginTop: 16 }}
              onClick={() => {
                setModalVisible(true);
              }}
            >
              Télécharger en CSV
            </Button>
          )}
        </>
      )}

      <Modal
        visible={modalVisible}
        title="Télécharger en CSV"
        okText="Télécharger"
        canceltext="Annuler"
        maskClosable={false}
        onCancel={() => {
          setModalVisible(false);
        }}
        onOk={() => {
          if (!exportedYears.length) {
            message.error("Veuillez sélectionner des dates à exporter");
            return;
          }

          if (!selectedFields.length) {
            message.error("Veuillez sélectionner des champs à exporter");
            return;
          }

          dispatch({ type: "SHOW_LOADER", payload: "Création du fichier CSV..." });
          setModalVisible(false);
          const sortedDates = exportedYears.sort();

          getUserStats({
            company: selectedCompany.id,
            fields: selectedFields,
            dates: sortedDates,
          }).then((response) => {
            const { data } = response;
            dispatch({ type: "HIDE_LOADER" });

            if (data) {
              if (data.error) {
                message.error(data.error);
              } else {
                window.location.href = `https://storage.googleapis.com/solidoc-proofs/${data}`;
              }
            } else {
              message.error("Une erreur est survenue. Veuillez réessayer.");
            }
          });
        }}
        // okButtonProps={{ loading: isFormLoading }}
      >
        <h4>Années à exporter</h4>
        <Select
          mode="multiple"
          size="middle"
          placeholder="Sélectionnez les champs à exporter"
          value={exportedYears}
          style={{ width: "100%", marginBottom: 16 }}
          onChange={(value) => {
            setExportedYears(value);
          }}
        >
          {yearOptions.map((year) => (
            <Select.Option value={year} key={year}>
              {year}
            </Select.Option>
          ))}
        </Select>

        <h4>Champs à exporter</h4>
        <Select
          mode="multiple"
          size="middle"
          placeholder="Sélectionnez les champs à exporter"
          value={selectedFields}
          style={{ width: "100%" }}
          onChange={(value) => {
            setSelectedFields(value);
          }}
        >
          <Select.Option value="Type d'envoi">Type d&apos;envoi</Select.Option>
          <Select.Option value="Date d'envoi">Date d&apos;envoi</Select.Option>
          <Select.Option value="Type de destinataire">Type de destinataire</Select.Option>
          <Select.Option value="Nom du destinataire">Nom du destinataire</Select.Option>
          <Select.Option value="Prénom du destinataire">Prénom du destinataire</Select.Option>
          <Select.Option value="Email du destinataire">Email du destinataire</Select.Option>
          <Select.Option value="Statut de l'envoi">Statut de l&apos;envoi</Select.Option>
          <Select.Option value="Date de dernier status">Date de dernier status</Select.Option>
        </Select>
      </Modal>
    </>
  );
}
