import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Badge,
  Form,
  Input,
  Modal,
  Space,
  Table,
  message,
  Select,
  Tabs,
  Checkbox,
  Switch,
  Tooltip,
} from "antd";

// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ReloadOutlined,
  CloseOutlined,
  LockOutlined,
} from "@ant-design/icons";

import moment from "moment";
import _ from "lodash";

import getHighlightedText from "../../../modules/helpers/utils";

const { confirm } = Modal;
const { TabPane } = Tabs;

function sorterFn(property) {
  return (a, b) => `${a[property]}`.toLowerCase().localeCompare(`${b[property]}`.toLowerCase());
}

function PasswordForm({ form }) {
  const [password, setPassword] = useState("");

  const validatePassword = (rule, value, callback) => {
    if (value && value !== password) {
      callback("Les mots de passes sont différents");
    } else {
      callback();
    }
  };

  return (
    <Form layout="vertical" form={form}>
      <Form.Item
        name="new_password"
        label="Nouveau mot de passe"
        rules={[
          { min: 6, message: "Votre mot de passe doit contenir au moins 6 caractères" },
          { required: true, message: "Veuillez entrer un nouveau mot de passe" },
        ]}
      >
        <Input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          name="new_password"
          autoComplete="new-password"
        />
      </Form.Item>

      <Form.Item
        name="confirm_new_password"
        label="Confirmer votre nouveau mot de passe"
        rules={[
          { required: true, message: "Veuillez confirmer votre nouveau mot de passe" },
          { validator: validatePassword },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          name="confirm_new_password"
          autoComplete="new-password"
        />
      </Form.Item>
    </Form>
  );
}

function UserForm({ values, form, companies }) {
  const canEdit2FA = useMemo(() => {
    return values && values.has2FAActivated;
  }, [values]);

  return values ? (
    <Form layout="vertical" form={form} initialValues={values}>
      <Form.Item
        name="company"
        label="Compte / Entreprise"
        rules={[{ required: true, message: "Veuillez selectionner un compte" }]}
      >
        <Select
          showSearch
          placeholder="Sélectionnez un compte"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {companies.map((company) => (
            <Select.Option value={company.id} key={company.key}>
              {company.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="last_name"
        label="Nom"
        rules={[{ required: true, message: "Veuillez entrer un nom" }]}
      >
        <Input placeholder="Ex: Dupont" />
      </Form.Item>

      <Form.Item
        name="first_name"
        label="Prénom"
        rules={[{ required: true, message: "Veuillez entrer un prénom" }]}
      >
        <Input placeholder="Ex: Jean" />
      </Form.Item>

      <Form.Item name="address_line_1" label="Adresse ligne 1" rules={[{ required: false }]}>
        <Input placeholder="Adresse postale, boîte postale" />
      </Form.Item>

      <Form.Item name="address_line_2" label="Adresse ligne 2" rules={[{ required: false }]}>
        <Input placeholder="Appartement, suite, unité, immeuble, étage, etc." />
      </Form.Item>

      <Form.Item name="postal_code" label="Code postal" rules={[{ required: false }]}>
        <Input placeholder="Ex: 75008" />
      </Form.Item>

      <Form.Item name="city" label="Ville" rules={[{ required: false }]}>
        <Input placeholder="Ex: Paris" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Adresse email"
        // rules={[{ required: true, message: 'Veuillez entrer un email' }, { type: 'email', message: 'Veuillez entrer un email valide' }]}
      >
        <Input placeholder="Ex: jean.dupont@gmail.com" disabled />
      </Form.Item>

      <Form.Item name="phone" label="Téléphone">
        <Input placeholder="Ex: 0123456789" />
      </Form.Item>

      <Form.Item name="is_company_admin" valuePropName="checked">
        <Checkbox>Administrateur de compte</Checkbox>
      </Form.Item>

      <Form.Item name="is_account_admin" valuePropName="checked">
        <Checkbox>Administrateur d'équipe</Checkbox>
      </Form.Item>

      <Form.Item name="isOperator" valuePropName="checked">
        <Checkbox>Opérateur</Checkbox>
      </Form.Item>

      <Form.Item name="isAdmin" valuePropName="checked">
        <Checkbox>Super administrateur</Checkbox>
      </Form.Item>
      <Form.Item
        name="has2FAActivated"
        valuePropName="checked"
        label="Solidoc Authenticator"
        tooltip={canEdit2FA ? "" : "Seul l'utilisateur peut activer Solidoc Authenticator"}
      >
        <Switch disabled={!canEdit2FA} checkedChildren={"Activé"} unCheckedChildren={"Désactivé"} />
      </Form.Item>
    </Form>
  ) : (
    <Form layout="vertical" form={form} initialValues={values}>
      <Form.Item
        name="last_name"
        label="Nom"
        rules={[{ required: true, message: "Veuillez entrer un nom" }]}
      >
        <Input placeholder="Ex: Dupont" />
      </Form.Item>

      <Form.Item
        name="first_name"
        label="Prénom"
        rules={[{ required: true, message: "Veuillez entrer un prénom" }]}
      >
        <Input placeholder="Ex: Jean" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Adresse email"
        rules={[
          { required: true, message: "Veuillez entrer un email" },
          { type: "email", message: "Veuillez entrer un email valide" },
        ]}
      >
        <Input placeholder="Ex: jean.dupont@gmail.com" />
      </Form.Item>
    </Form>
  );
}

function UserModal({
  visible,
  user,
  onSubmit,
  onCancel,
  isFormLoading,
  setIsFormLoading,
  companies,
}) {
  const [form] = Form.useForm();
  const [selectedTab, setSelectedTab] = useState("informations");

  useEffect(() => {
    form.resetFields();
  }, [form, user]);

  useEffect(() => {
    if (visible === true) {
      setIsFormLoading(false);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title={user ? "Modifier un utilisateur" : "Inviter un nouvel utilisateur"}
      okText={user ? "Modifier" : "Inviter"}
      canceltext="Annuler"
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsFormLoading(true);
            onSubmit(selectedTab, values, form.resetFields);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ loading: isFormLoading }}
      bodyStyle={{ paddingTop: 0 }}
    >
      <Tabs
        activeKey={selectedTab}
        onChange={(value) => {
          setSelectedTab(value);
        }}
      >
        <TabPane tab="Informations" key="informations" />
        <TabPane tab="Mot de passe" key="password" />
      </Tabs>
      {selectedTab === "informations" ? (
        <UserForm form={form} values={user} companies={companies} />
      ) : (
        <PasswordForm form={form} />
      )}
    </Modal>
  );
}

export default function UsersTable({
  company,
  companies,
  setLastUpdate,
  searchTerms,
  allUsersView,
}) {
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState(null);

  const db = firebase.firestore();
  const resendInvite = firebase.app().functions("europe-west1").httpsCallable("resendInvite");
  const deleteUser = firebase.app().functions("europe-west1").httpsCallable("deleteUser");
  const disableUser = firebase.app().functions("europe-west1").httpsCallable("disableUser");
  const updateUserPassword = firebase
    .app()
    .functions("europe-west1")
    .httpsCallable("updateUserPassword");

  useEffect(() => {
    setUsers(company.users);
  }, [company]);

  const columns = [
    {
      title: "Nom",
      dataIndex: "last_name",
      sorter: sorterFn("last_name"),
      defaultSortOrder: "ascend",
      render: (text) => getHighlightedText(text, searchTerms),
    },
    {
      title: "Prénom",
      dataIndex: "first_name",
      sorter: sorterFn("first_name"),
      render: (text) => getHighlightedText(text, searchTerms),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: sorterFn("email"),
      render: (text) => getHighlightedText(text, searchTerms),
    },
    {
      title: "Téléphone",
      dataIndex: "phone",
    },
    {
      title: "Statut",
      key: "status",
      filters: [
        {
          text: "En attente",
          value: "awaiting",
        },
        {
          text: "Actif",
          value: "active",
        },
        {
          text: "Désactivé",
          value: "disabled",
        },
      ],
      sorter: (a, b) => a.last_invite.diff(b.last_invite),
      onFilter: (value, record) =>
        (value === "disabled" && record.disabled) ||
        (value === "awaiting" && !record.isActive) ||
        (value === "active" && record.isActive && !record.disabled),
      render: (text, record) => (
        <Space size="small">
          {record.isActive ? (
            record.disabled ? (
              <Badge status="error" text="Désactivé" />
            ) : (
              <Badge status="success" text="Actif" />
            )
          ) : (
            record.last_invite && (
              <Badge
                status="warning"
                text={`En attente depuis ${record.last_invite.fromNow(true)}`}
              />
            )
          )}
        </Space>
      ),
    },
    {
      title: "Dernière connexion",
      key: "last_connected",
      sorter: (a, b) => a.last_connected.diff(b.last_connected),
      render: (text, record) => (
        <Space size="small">
          {record.isActive && record.has_connected ? (
            <span>{_.capitalize(record.last_connected.fromNow())}</span>
          ) : (
            <span />
          )}
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="small">
          <Button
            onClick={() => {
              setSelectedUser(record);
              setModalVisible(true);
            }}
            type="primary"
            title="Modifier"
            size="small"
            shape="circle"
            icon={<EditOutlined />}
          />
          {record.isActive && !record.disabled && (
            <Button
              type="danger"
              title="Désactiver"
              size="small"
              shape="circle"
              icon={<CloseOutlined />}
              onClick={() => {
                confirm({
                  title: "Désactiver un utilisateur",
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <div>
                      Êtes-vous sûr de vouloir désactiver l'utilisateur{" "}
                      <strong>
                        {record.first_name} {record.last_name} ({record.email})
                      </strong>
                      ?
                    </div>
                  ),
                  okText: "Désactiver",
                  okType: "danger",
                  onOk: () => {
                    return disableUser({ uid: record.uid })
                      .then(() => {
                        message.success("Utilisateur désactivé");
                        const clonedUsers = users;
                        clonedUsers.splice(_.findIndex(users, { uid: record.uid }), 1, {
                          ...record,
                          disabled: true,
                        });

                        setUsers([...clonedUsers]);
                      })
                      .catch((e) => {
                        console.error(e);
                        message.error("Une erreur est survenue. Veuillez réessayer.");
                      });
                  },
                });
              }}
            />
          )}
          {!record.isActive && (
            <>
              <Button
                type="default"
                title=""
                size="small"
                shape="circle"
                icon={<ReloadOutlined />}
                onClick={() => {
                  confirm({
                    title: "Renvoyer une invitation",
                    content: (
                      <div>
                        Êtes-vous sûr de vouloir renvoyer une invitation à{" "}
                        <strong>
                          {record.first_name} {record.last_name} ({record.email})
                        </strong>{" "}
                        ?
                        <br />
                        <br />
                        Un nouveau mot de passe sera généré et envoyé par email.
                      </div>
                    ),
                    okText: "Renvoyer un invitation",
                    onOk: () => {
                      return resendInvite({ uid: record.uid })
                        .then(() => {
                          message.success("Invitation envoyée");
                        })
                        .catch((e) => {
                          console.error(e);
                          message.error("Une erreur est survenue. Veuillez réessayer.");
                        });
                    },
                  });
                }}
              />
              <Button
                type="danger"
                title="Supprimer"
                size="small"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => {
                  confirm({
                    title: "Supprimer un utilisateur",
                    icon: <ExclamationCircleOutlined />,
                    content: (
                      <div>
                        Êtes-vous sûr de vouloir supprimer l'utilisateur{" "}
                        <strong>
                          {record.first_name} {record.last_name} ({record.email})
                        </strong>
                        ?
                      </div>
                    ),
                    okText: "Supprimer",
                    okType: "danger",
                    onOk: () => {
                      return deleteUser({ uid: record.uid })
                        .then(() => {
                          message.success("Utilisateur supprimé");
                          setUsers([..._.reject(users, record)]);
                        })
                        .catch((e) => {
                          console.error(e);
                          message.error("Une erreur est survenue. Veuillez réessayer.");
                        });
                    },
                  });
                }}
              />
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        title={
          !allUsersView &&
          (() => (
            <h4>
              Utilisateurs du compte: <b>{company.name}</b>
            </h4>
          ))
        }
        bordered
        style={{ marginTop: 16 }}
        columns={columns}
        dataSource={users}
        size="small"
        scroll={{ x: true }}
        pagination={allUsersView && { defaultPageSize: 50 }}
        locale={{ emptyText: "Aucun utilisateur" }}
        loading={allUsersView && users && !users.length}
      />

      {/* Modal */}
      <UserModal
        companies={companies}
        visible={modalVisible}
        user={selectedUser}
        onCancel={() => {
          setModalVisible(false);
        }}
        isFormLoading={isFormLoading}
        setIsFormLoading={setIsFormLoading}
        onSubmit={(type, values, resetFields) => {
          console.log(selectedUser);

          if (selectedUser) {
            const shouldRefresh = selectedUser.company.id !== values.company;

            if (type === "informations") {
              db.collection("users")
                .doc(selectedUser.uid)
                .set(
                  {
                    ...values,
                    company: _.find(companies, { id: values.company }).ref,
                    phone2Fa: values.has2FAActivated ? values.phone2Fa : "",
                  },
                  { merge: true }
                )
                .then(() => {
                  if (shouldRefresh) {
                    setLastUpdate(new Date().getTime());
                  } else {
                    const clonedUsers = users;
                    clonedUsers.splice(_.findIndex(users, { uid: selectedUser.uid }), 1, {
                      ...selectedUser,
                      ...values,
                    });

                    setUsers([...clonedUsers]);
                  }

                  resetFields();
                  setModalVisible(false);
                  message.success("Modifications enregistrées");
                })
                .catch((e) => {
                  setIsFormLoading(false);
                  message.error("Une erreur est survenue. Veuillez réessayer.");
                  console.error(e);
                });
            } else {
              updateUserPassword({ userUid: selectedUser.uid, password: values.new_password })
                .then(() => {
                  resetFields();
                  setModalVisible(false);
                  message.success("Mot de passe modifié");
                })
                .catch((e) => {
                  resetFields();
                  setIsFormLoading(false);
                  message.error("Une erreur est survenue. Veuillez réessayer.");
                  console.error(e);
                });
            }
          }
        }}
      />
    </>
  );
}
