import React, { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Descriptions, List, PageHeader, Tag, Table } from "antd";

// Icons
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  NumberOutlined,
  SignalFilled,
} from "@ant-design/icons";

import { useHistory, useParams } from "react-router-dom";
import moment from "moment";

// Firebase
import * as firebase from "firebase";
import getFileIcon from "../../modules/helpers/fileIcon";

import RecipientsTable from "./RecipientsTable";
import {
  AiFillClockCircle,
  AiFillDatabase,
  BiBuildings,
  BsFillPersonFill,
  BsPeopleFill,
  IoIosColorPalette,
  IoMdMail,
  MdLocalPostOffice,
  RiFileCopyFill,
} from "react-icons/all";

function TagStatus({ status }) {
  switch (status) {
    case "scheduled":
      return (
        <Tag icon={<ClockCircleOutlined />} color="processing">
          Programmé
        </Tag>
      );
    default:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Envoyé
        </Tag>
      );
  }
}

const customLabelStyle = { fontWeight: "bold", opacity: 0.75 };

const LabelContainer = ({ ...props }) => {
  return <div style={{ display: "flex", alignItems: "center", gap: "4px" }} {...props} />;
};

export default function Attachments() {
  const history = useHistory();

  const [message, setMessage] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const user = useSelector((state) => state.user, shallowEqual);

  const { type, id } = useParams();

  // Configure Firestore
  const db = firebase.firestore();

  const columnsHead = [
    { title: "Nom", dataIndex: "last_name" },
    { title: "Prénom", dataIndex: "first_name" },
    {
      title: "Type",
      dataIndex: "type",
      render: (type) => (type === "individual" ? "Particulier" : "Professionnel"),
    },
  ];

  const columnsTail = [
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        switch (status) {
          case "prepare":
            return (
              <Tag icon={<ClockCircleOutlined />} color="processing">
                {message.date_type === "now" ? "En cours d'envoi" : "En préparation"}
              </Tag>
            );
          case "scheduled":
            return (
              <Tag icon={<ClockCircleOutlined />} color="processing">
                Programmé
              </Tag>
            );
          default:
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                Envoyé
              </Tag>
            );
        }
      },
    },
    {
      title: "Date de status",
      dataIndex: "last_updated",
      render: (lastUpdated) => moment(lastUpdated.toDate()).format("DD/MM/YYYY HH:mm"),
    },
  ];

  useEffect(() => {
    const messageRef = user.company.collection("messages").doc(id);

    messageRef.get().then((snapshot) => {
      const data = snapshot.data();

      if (data) {
        setMessage(data);

        messageRef
          .collection("recipients")
          .get()
          .then((recipientsSnapshot) => {
            setRecipients(recipientsSnapshot.docs.map((s) => s.data()));

            data.user.get().then((userSentSnapshot) => {
              user.company.get().then((companySnap) => {
                setMessage({
                  ...data,
                  sentBy: userSentSnapshot.data(),
                  userCompany: companySnap.data(),
                });
              });
            });
          });
      } else {
        history.replace("/historique");
      }
    });
  }, []);

  const trackingLink = useMemo(() => {
    if (message && message.trackingNumber && message.trackingType) {
      switch (message.trackingType) {
        case "laposte":
          return `https://www.laposte.fr/outils/suivre-vos-envois?code=${message.trackingNumber}`;
        default:
          return "";
      }
    }
    return "";
  }, [message]);

  return message === null ? (
    <div>Loading</div>
  ) : (
    <>
      <PageHeader
        onBack={() => {
          switch (message.type) {
            case "simple":
              history.push("/historique/lettres-simples");
              break;
            case "recommande":
              history.push("/historique/lettres-recommandees");
              break;
            case "electronique":
              history.push("/historique/electronique");
              break;
          }
        }}
        style={{ padding: 0, marginBottom: 24 }}
        title={`Détails de l'envoi N°${id.toUpperCase()}`}
      />

      <Descriptions
        bordered
        title="Informations sur l'envoi"
        size="small"
        column={1}
        labelStyle={customLabelStyle}
      >
        <Descriptions.Item
          label={
            <LabelContainer>
              <NumberOutlined />
              N° d'envoi
            </LabelContainer>
          }
        >
          {id.toUpperCase()}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <LabelContainer>
              <BsFillPersonFill />
              Déclenché par
            </LabelContainer>
          }
        >
          {message.sentBy &&
            `${message.sentBy.last_name} ${message.sentBy.first_name} (${message.sentBy.email})`}
        </Descriptions.Item>
        {message.userCompany && (
          <Descriptions.Item
            label={
              <LabelContainer>
                <BiBuildings />
                Entité émettrice
              </LabelContainer>
            }
          >
            {message.userCompany.name}
            <br />
            {message.userCompany.address_line_1}
            <br />
            {message.userCompany.address_line_2 && (
              <>
                {message.userCompany.address_line_2}
                <br />
              </>
            )}
            {message.userCompany.postal_code} {message.userCompany.city}
            <br />
          </Descriptions.Item>
        )}
        {message.type === "recommande" && (
          <Descriptions.Item
            label={
              <LabelContainer>
                <MdLocalPostOffice />
                N° de suivi
              </LabelContainer>
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: 1 }}>
                {trackingLink && (
                  <span>
                    <span style={{ marginRight: ".5rem" }}>{message.trackingNumber}</span>
                    <a href={trackingLink} target="_blank">
                      Lien de suivi
                    </a>
                  </span>
                )}
              </div>
              {message.trackingFile && (
                <a target="_blank" href={message.trackingFile} style={{ marginRight: "1rem" }}>
                  Preuve de dépôt
                </a>
              )}
            </div>
          </Descriptions.Item>
        )}
      </Descriptions>

      {(message.type === "simple" || message.type === "recommande") && (
        <Descriptions
          bordered
          title="Options d'impression"
          size="small"
          style={{ marginTop: 32 }}
          column={6}
          labelStyle={customLabelStyle}
        >
          <Descriptions.Item
            label={
              <LabelContainer>
                <IoIosColorPalette />
                Paramètre couleur
              </LabelContainer>
            }
            span={3}
          >
            {message.color_option === "black_white" ? "Noir/Blanc" : "Couleur"}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <LabelContainer>
                <RiFileCopyFill />
                Recto ou Recto/Verso
              </LabelContainer>
            }
            span={3}
          >
            {message.face_option === "one_face" ? "Recto seulement" : "Recto/Verso"}
          </Descriptions.Item>
          {message.type === "simple" && (
            <Descriptions.Item
              label={
                <LabelContainer>
                  <BsPeopleFill />
                  Type de destinataires
                </LabelContainer>
              }
              span={3}
            >
              {message.recipients_type === "unique"
                ? "Destinataire unique"
                : "Destinataires multiples"}
            </Descriptions.Item>
          )}
          {message.type === "simple" ? (
            <Descriptions.Item
              label={
                <LabelContainer>
                  <AiFillClockCircle />
                  Délai de distribution
                </LabelContainer>
              }
              span={3}
            >
              {message.envelope}
            </Descriptions.Item>
          ) : (
            <>
              <Descriptions.Item
                label={
                  <LabelContainer>
                    <IoMdMail />
                    Accusé de réception
                  </LabelContainer>
                }
                span={3}
              >
                {message.receipt_aknowledge === "yes"
                  ? "Avec accusé de réception"
                  : "Sans accusé de réception"}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <LabelContainer>
                    <SignalFilled />
                    Niveau de recommandation
                  </LabelContainer>
                }
                span={6}
              >
                {message.level}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      )}

      {message.type === "electronique" && (
        <>
          <Descriptions
            bordered
            title="Options d'envoi"
            size="small"
            style={{ marginTop: 32 }}
            column={4}
            labelStyle={customLabelStyle}
          >
            <Descriptions.Item label="Type d'envoi" span={message.date_type === "now" ? 4 : 2}>
              {message.date_type === "now" ? "Immédiat" : "Différé"}
            </Descriptions.Item>
            {message.date_type !== "now" && message.date && (
              <Descriptions.Item label="Date d'envoi" span={2}>
                <span style={{ textTransform: "capitalize" }}>
                  {moment(message.date.toDate()).format("dddd D MMMM YYYY")}
                </span>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Référence 1" span={2}>
              {message.ref1 || "Aucune"}
            </Descriptions.Item>
            <Descriptions.Item label="Référence 2" span={2}>
              {message.ref2 || "Aucune"}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions style={{ marginTop: 32 }} title="Destinataires" />
          <RecipientsTable data={recipients} />
        </>
      )}

      <Descriptions
        labelStyle={customLabelStyle}
        style={{ marginTop: 32 }}
        title={message.type === "electronique" ? "Pièces jointes" : "Documents"}
      />
      <Card type="inner" style={{ width: "100%" }} bodyStyle={{ padding: "8px 24px 0" }}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={
                message.attachments.length
                  ? message.attachments
                  : [
                      {
                        filename:
                          message.type === "electronique"
                            ? "Aucune pièce jointe"
                            : "Aucun document",
                      },
                    ]
              }
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={message.attachments.length ? getFileIcon(item.mimetype) : null}
                    title={item.filename}
                    description={
                      message.attachments.length ? (
                        <a href={item.url} rel="noopener noreferrer" target="_blank">
                          Ouvrir le fichier
                        </a>
                      ) : null
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>

      {message.type === "electronique" && (
        <Descriptions
          bordered
          title="Contenu du message"
          size="small"
          column={4}
          style={{ marginTop: 32 }}
          labelStyle={customLabelStyle}
        >
          <Descriptions.Item label="Objet" span={4}>
            {message.subject}
          </Descriptions.Item>
          <Descriptions.Item label="Message" span={4}>
            {message.content}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
}
