import { Modal, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import * as firebase from "firebase";
import { shallowEqual, useSelector } from "react-redux";
import { useMemo, useState, useEffect } from "react";

const Dragger = Upload.Dragger;

const ImageUploadModal = ({ visible, onCancel, onOk }) => {
  const user = useSelector((state) => state.user, shallowEqual);
  console.log({ user });
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(0);

  const canConfirm = useMemo(() => {
    return files.length > 0 && uploading === 0;
  }, [files, uploading]);

  useEffect(() => {
    setFiles([]);
  }, [visible]);

  const onFileUpload = async (file) => {
    const name = `${Date.now()}-${file.name}`;
    const storageRef = firebase.storage().ref("attachments/" + user.uid + "/" + name);
    await storageRef.put(file);
    const url = await storageRef.getDownloadURL();
    console.log({ file });
    if (file.type === "application/pdf") {
      const reader = new FileReader();

      reader.readAsBinaryString(file);
      reader.onloadend = () => {
        let count = 1;
        try {
          count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
        } catch {
          // do nothing
        }
        console.log("Number of Pages:", count);

        setFiles((f) => [
          ...f,
          {
            filename: file.name,
            originalFile: {
              name: file.name,
              size: file.size,
              type: file.type,
              pages: count,
            },
            mimetype: file.type,
            pages: count,
            size: file.size,
            url,
          },
        ]);
      };
    } else {
      setFiles((f) => [
        ...f,
        {
          filename: file.name,
          originalFile: {
            name: file.name,
            size: file.size,
            type: file.type,
            pages: 1,
          },
          mimetype: file.type,
          size: file.size,
          url,
          pages: 1,
        },
      ]);
    }
  };
  console.log({ files });
  return (
    <Modal
      title="Ajouter des documents"
      visible={visible}
      onOk={(e) => {
        onOk(files);
      }}
      onCancel={onCancel}
      width="90%"
      okButtonProps={{
        disabled: !canConfirm,
      }}
    >
      <Dragger multiple={true} action={onFileUpload} listType="picture">
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or
          other band files
        </p>
      </Dragger>
    </Modal>
  );
};

export default ImageUploadModal;
