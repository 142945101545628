import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  Col,
  Row,
  Descriptions,
  List,
  PageHeader,
  Tag,
  Button,
  Upload,
  Input,
  Dropdown,
  Select,
  InputNumber,
} from "antd";

// Icons
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  NumberOutlined,
  SignalFilled,
} from "@ant-design/icons";

import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";

// Firebase
import * as firebase from "firebase";
import getFileIcon from "../../../modules/helpers/fileIcon";

import RecipientsTable from "./RecipientsTable";
import {
  AiFillClockCircle,
  AiFillDatabase,
  BiBuildings,
  BsFillPersonFill,
  BsPeopleFill,
  BsTrashFill,
  IoIosColorPalette,
  IoMdMail,
  MdLocalPostOffice,
  RiFileCopy2Line,
  RiFileCopyFill,
  RiFileCopyLine,
} from "react-icons/all";
import ChangeStatusDropdown from "./ChangeStatusDropdown";
import { debounce } from "lodash";

const LabelContainer = ({ ...props }) => {
  return <div style={{ display: "flex", alignItems: "center", gap: "4px" }} {...props} />;
};

function TagStatus({ status }) {
  switch (status) {
    case "scheduled":
      return (
        <Tag icon={<ClockCircleOutlined />} color="processing">
          Programmé
        </Tag>
      );
    default:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Envoyé
        </Tag>
      );
  }
}

export default function Attachments() {
  const history = useHistory();

  const [message, setMessage] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const user = useSelector((state) => state.user, shallowEqual);
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const { id, company } = useParams();

  // Configure Firestore
  const db = firebase.firestore();

  const columnsHead = [
    { title: "Nom", dataIndex: "last_name" },
    { title: "Prénom", dataIndex: "first_name" },
    {
      title: "Type",
      dataIndex: "type",
      render: (type) => (type === "individual" ? "Particulier" : "Professionnel"),
    },
  ];

  const columnsTail = [
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        switch (status) {
          case "prepare":
            return (
              <Tag icon={<ClockCircleOutlined />} color="processing">
                {message.date_type === "now" ? "En cours d'envoi" : "En préparation"}
              </Tag>
            );
          case "scheduled":
            return (
              <Tag icon={<ClockCircleOutlined />} color="processing">
                Programmé
              </Tag>
            );
          default:
            return (
              <Tag icon={<CheckCircleOutlined />} color="success">
                Envoyé
              </Tag>
            );
        }
      },
    },
    {
      title: "Date de status",
      dataIndex: "last_updated",
      render: (lastUpdated) => moment(lastUpdated.toDate()).format("DD/MM/YYYY HH:mm"),
    },
  ];

  useEffect(() => {
    const messageRef = db.collection("companies").doc(company).collection("messages").doc(id);

    messageRef.get().then((snapshot) => {
      const data = snapshot.data();

      if (data) {
        //setMessage({ ...data, id: snapshot.id });

        messageRef
          .collection("recipients")
          .get()
          .then((recipientsSnapshot) => {
            const recipientsObj = {};

            setRecipients(recipientsSnapshot.docs.map((s) => s.data()));
            recipientsSnapshot.docs.forEach((recipientDoc) => {
              const recipient = { ...recipientDoc.data(), key: recipientDoc.id };
              if (recipientsObj[recipient.message.id]) {
                recipientsObj[recipient.message.id].push(recipient);
              } else {
                recipientsObj[recipient.message.id] = [recipient];
              }
            });

            data.user.get().then((userSentSnapshot) => {
              db.collection("companies")
                .doc(company)
                .get()
                .then((companySnap) => {
                  setMessage({
                    id: snapshot.id,
                    key: snapshot.id,
                    ...data,
                    sentBy: userSentSnapshot.data(),
                    userCompany: { ...companySnap.data(), id: companySnap.id },
                    companyId: companySnap.id,
                    recipients: recipientsObj[snapshot.id],
                  });
                });
            });
          });
      } else {
        history.replace("/historique");
      }
    });
  }, [updateTrigger]);

  const updateTrackingNumber = debounce((value) => {
    db.collection("companies")
      .doc(message.userCompany.id)
      .collection("messages")
      .doc(message.id)
      .set({ trackingNumber: value }, { merge: true })
      .then(() => {
        //update locally
      });
  }, 350);

  const updatePagesNumber = debounce((value) => {
    db.collection("companies")
      .doc(message.userCompany.id)
      .collection("messages")
      .doc(message.id)
      .set({ pages: value }, { merge: true })
      .then(() => {
        //update locally
      });
  }, 350);
  const onPageNumberChange = debounce((value) => {
    if (value) {
      try {
        const v = parseInt(value);
        setMessage({
          ...message,
          pages: parseInt(v),
        });
        updatePagesNumber(v);
      } catch (e) {
        console.error(e);
      }
    }
  }, 350);
  const onTrackingNumberUpdate = (e) => {
    console.log(e);
    const value = e.target.value;
    setMessage({
      ...message,
      trackingNumber: value,
    });
    updateTrackingNumber(value);
  };

  const onTrackingTypeUpdate = (type) => {
    console.log({ type });
    db.collection("companies")
      .doc(message.userCompany.id)
      .collection("messages")
      .doc(message.id)
      .set({ trackingType: type }, { merge: true })
      .then(() => {
        //update locally
        setMessage({
          ...message,
          trackingType: type,
        });
      });
  };

  const onTrackingFileUpload = async (file) => {
    const name = `${Date.now()}-${file.name}`;
    const storageRef = firebase.storage().ref("tracking-proofs/" + user.uid + "/" + name);
    await storageRef.put(file);
    const url = await storageRef.getDownloadURL();
    db.collection("companies")
      .doc(message.userCompany.id)
      .collection("messages")
      .doc(message.id)
      .set(
        { trackingFile: url, trackingPath: "tracking-proofs/" + user.uid + "/" + name },
        { merge: true }
      )
      .then(() => {
        //update locally
        setMessage({
          ...message,
          trackingFile: url,
          trackingPath: "tracking-proofs/" + user.uid + "/" + name,
        });
      });
  };
  const onTrackingFileReset = () => {
    db.collection("companies")
      .doc(message.userCompany.id)
      .collection("messages")
      .doc(message.id)
      .set(
        {
          trackingFile: firebase.firestore.FieldValue.delete(),
          trackingPath: firebase.firestore.FieldValue.delete(),
        },
        { merge: true }
      )
      .then(() => {
        //update locally
        const newMessage = { ...message };
        delete newMessage.trackingFile;
        delete newMessage.trackingPath;
        console.log({ newMessage });
        setMessage(newMessage);
        const storageRef = firebase.storage().ref(message.trackingPath);
        return storageRef.delete();
      });
  };

  return message === null ? (
    <div>Loading</div>
  ) : (
    <>
      <PageHeader
        onBack={() => {
          history.push("/admin/sent");
        }}
        style={{ padding: 0, marginBottom: 24 }}
        title={`Détails de l'envoi N°${id.toUpperCase()}`}
      />

      <Descriptions bordered title="Informations sur l'envoi" size="small" column={1}>
        <Descriptions.Item
          label={
            <LabelContainer>
              <NumberOutlined />
              N° d'envoi
            </LabelContainer>
          }
        >
          {id.toUpperCase()}
        </Descriptions.Item>
        <Descriptions.Item
          l
          label={
            <LabelContainer>
              <BsFillPersonFill />
              Déclenché par
            </LabelContainer>
          }
        >
          {message.sentBy &&
            `${message.sentBy.last_name} ${message.sentBy.first_name} (${message.sentBy.email})`}
        </Descriptions.Item>
        {message.userCompany && (
          <Descriptions.Item
            label={
              <LabelContainer>
                <BiBuildings />
                Entité émettrice
              </LabelContainer>
            }
          >
            {message.userCompany.name}
            <br />
            {message.userCompany.address_line_1}
            <br />
            {message.userCompany.address_line_2 && (
              <>
                {message.userCompany.address_line_2}
                <br />
              </>
            )}
            {message.userCompany.postal_code} {message.userCompany.city}
            <br />
          </Descriptions.Item>
        )}
        <Descriptions.Item
          label={
            <LabelContainer>
              <AiFillDatabase />
              Status
            </LabelContainer>
          }
          contentStyle={{
            padding: 0,
          }}
        >
          <ChangeStatusDropdown
            record={message}
            onConfirm={() => {
              setUpdateTrigger(Date.now);
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <LabelContainer>
              <RiFileCopy2Line />
              Pages
            </LabelContainer>
          }
          contentStyle={{
            padding: 0,
          }}
        >
          <InputNumber
            min={1}
            step={1}
            value={message.pages || message.attachments.length}
            onInput={onPageNumberChange}
          />
        </Descriptions.Item>
        {message.type === "recommande" && (
          <Descriptions.Item
            label={
              <LabelContainer>
                <MdLocalPostOffice />
                N° de suivi
              </LabelContainer>
            }
          >
            <Row>
              <div style={{ flex: 1 }}>
                <Input
                  placeholder="Numéro de suivi"
                  style={{ maxWidth: "15rem" }}
                  onInput={onTrackingNumberUpdate}
                  value={message.trackingNumber}
                />
                <Select
                  style={{ width: "10rem" }}
                  onChange={onTrackingTypeUpdate}
                  value={message.trackingType}
                  placeholder="Expéditeur"
                >
                  <Select.Option value="laposte">La Poste</Select.Option>
                  <Select.Option value="solidoc" disabled={true}>
                    Solidoc
                  </Select.Option>
                </Select>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {!message.trackingFile && (
                  <Upload action={onTrackingFileUpload} showUploadList={false}>
                    <Button>Ajouter la preuve de dépôt</Button>
                  </Upload>
                )}
                {message.trackingFile && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <a target="_blank" href={message.trackingFile} style={{ marginRight: "1rem" }}>
                      Preuve de dépôt
                    </a>

                    <Button
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      type="primary"
                      title="Supprimer la preuve de dépôt"
                      size="small"
                      shape="circle"
                      icon={<BsTrashFill />}
                      onClick={onTrackingFileReset}
                    />
                  </div>
                )}
              </div>
            </Row>
          </Descriptions.Item>
        )}
      </Descriptions>

      {(message.type === "simple" || message.type === "recommande") && (
        <Descriptions
          bordered
          title="Options d'impression"
          size="small"
          style={{ marginTop: 32 }}
          column={6}
        >
          <Descriptions.Item
            label={
              <LabelContainer>
                <IoIosColorPalette />
                Paramètre couleur
              </LabelContainer>
            }
            span={3}
          >
            {message.color_option === "black_white" ? "Noir/Blanc" : "Couleur"}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <LabelContainer>
                <RiFileCopyLine />
                Recto ou Recto/Verso
              </LabelContainer>
            }
            span={3}
          >
            {message.face_option === "one_face" ? "Recto seulement" : "Recto/Verso"}
          </Descriptions.Item>
          {message.type === "simple" && (
            <Descriptions.Item
              label={
                <LabelContainer>
                  <BsPeopleFill />
                  Type de destinataires
                </LabelContainer>
              }
              span={3}
            >
              {message.recipients_type === "unique"
                ? "Destinataire unique"
                : "Destinataires multiples"}
            </Descriptions.Item>
          )}
          {message.type === "simple" ? (
            <Descriptions.Item
              label={
                <LabelContainer>
                  <AiFillClockCircle />
                  Délai de distribution
                </LabelContainer>
              }
              span={3}
            >
              {message.envelope}
            </Descriptions.Item>
          ) : (
            <>
              <Descriptions.Item
                label={
                  <LabelContainer>
                    <IoMdMail />
                    Accusé de réception
                  </LabelContainer>
                }
                span={3}
              >
                {message.receipt_aknowledge === "yes"
                  ? "Avec accusé de réception"
                  : "Sans accusé de réception"}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <LabelContainer>
                    <SignalFilled />
                    Niveau de recommandation
                  </LabelContainer>
                }
                span={6}
              >
                {message.level}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      )}

      {message.type === "electronique" && (
        <>
          <Descriptions
            bordered
            title="Options d'envoi"
            size="small"
            style={{ marginTop: 32 }}
            column={4}
          >
            <Descriptions.Item label="Type d'envoi" span={message.date_type === "now" ? 4 : 2}>
              {message.date_type === "now" ? "Immédiat" : "Différé"}
            </Descriptions.Item>
            {message.date_type !== "now" && message.date && (
              <Descriptions.Item label="Date d'envoi" span={2}>
                <span style={{ textTransform: "capitalize" }}>
                  {moment(message.date.toDate()).format("dddd D MMMM YYYY")}
                </span>
              </Descriptions.Item>
            )}
            <Descriptions.Item label="Référence 1" span={2}>
              {message.ref1 || "Aucune"}
            </Descriptions.Item>
            <Descriptions.Item label="Référence 2" span={2}>
              {message.ref2 || "Aucune"}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions style={{ marginTop: 32 }} title="Destinataires" />
          <RecipientsTable data={recipients} />
        </>
      )}

      {message.comment && (
        <>
          <Descriptions style={{ marginTop: 32 }} title="Instructions d’impression" />

          <Card type="inner" style={{ width: "100%" }} bodyStyle={{ padding: "8px 24px 0" }}>
            <Row style={{ opacity: 0.5, fontSize: "0.8em", fontWeight: "bold" }}>
              ex : Agrafer des documents, inclure une référence dans une liasse recommandée, pièce
              jointe à insérer dans chaque pli.
            </Row>
            {message.comment}
          </Card>
        </>
      )}
      <Descriptions
        style={{ marginTop: 32 }}
        title={message.type === "electronique" ? "Pièces jointes" : "Documents"}
      />
      <Card type="inner" style={{ width: "100%" }} bodyStyle={{ padding: "8px 24px 0" }}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={
                message.attachments.length
                  ? message.attachments
                  : [
                      {
                        filename:
                          message.type === "electronique"
                            ? "Aucune pièce jointe"
                            : "Aucun document",
                      },
                    ]
              }
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={message.attachments.length ? getFileIcon(item.mimetype) : null}
                    title={item.filename}
                    description={
                      message.attachments.length ? (
                        <a href={item.url} rel="noopener noreferrer" target="_blank">
                          Ouvrir le fichier
                        </a>
                      ) : null
                    }
                  />

                  <div>
                    {item.completed ? (
                      <Tag icon={<CheckCircleOutlined />} color="cyan">
                        Traité
                      </Tag>
                    ) : (
                      <Button
                        type="primary"
                        title="Marquer comme envoyer"
                        size="small"
                        shape="circle"
                        icon={<CheckCircleOutlined />}
                        onClick={() => {
                          const attachments = JSON.parse(JSON.stringify(message.attachments)); // deep copy
                          attachments.splice(index, 1, {
                            ...attachments[index],
                            completed: true,
                          });

                          return db
                            .collection("companies")
                            .doc(message.userCompany.id)
                            .collection("messages")
                            .doc(message.id)
                            .set({ attachments: attachments }, { merge: true })
                            .then(() => {
                              //update locally
                              setMessage({
                                ...message,
                                attachments,
                              });
                            });
                        }}
                      />
                    )}
                  </div>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </Card>

      {message.type === "electronique" && (
        <Descriptions
          bordered
          title="Contenu du message"
          size="small"
          column={4}
          style={{ marginTop: 32 }}
        >
          <Descriptions.Item label="Objet" span={4}>
            {message.subject}
          </Descriptions.Item>
          <Descriptions.Item label="Message" span={4}>
            {message.content}
          </Descriptions.Item>
        </Descriptions>
      )}
    </>
  );
}
