import React, {useCallback, useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Modal,
  List,
  Popconfirm,
  Radio,
} from 'antd';

import { useHistory } from 'react-router-dom';

import _ from 'lodash';

import ContactComponent from '../../../Contacts/Contacts';
import ListComponent from '../../../Contacts/Lists';

function RecipientsModal({ visible, onOk, onCancel }) {
  const [selectedRowKeys, setSelectedRowKeys] = useState({ contacts: [], lists: [] });
  const [selectedType, setSelectedType] = useState('contacts');
  const formValues = useSelector((state) => state.messagePayload, shallowEqual);

  const dispatch = useDispatch();


  useEffect(() => {
    console.log('...', formValues.recipients);
    setSelectedRowKeys(formValues.recipients || selectedRowKeys);
  }, [formValues.recipients]);

  return (
    <Modal
      title="Ajouter un destinataire"
      visible={visible}
      onOk={(e) => {
        console.log('OK SUBMIT');
        dispatch({ type: 'EMAIL_FORM_SUBMITTED', payload: { recipients: selectedRowKeys } });
        onOk(e);
      }}
      onCancel={onCancel}
      width="90%"
    >
      <div style={{ float: 'left' }}>
        <Radio.Group
          onChange={(e) => setSelectedType(e.target.value)}
          style={{ marginBottom: 16 }}
          value={selectedType}
        >
          <Radio.Button value="contacts">Contacts</Radio.Button>
          <Radio.Button value="lists">Listes&nbsp;de&nbsp;diffusion</Radio.Button>
        </Radio.Group>
      </div>
      {
        selectedType === 'contacts'
          ? (
            <ContactComponent
              selectionMode
              selectedRows={selectedRowKeys[selectedType].map((s) => {
                console.log(s);
                return s && s.key;
              })}
              onChange={(keys, rows) => {
                console.log(keys, rows);
                setSelectedRowKeys({ ...selectedRowKeys, [selectedType]: rows });
              }}
            />
          ) : (
            <ListComponent
              selectionMode
              selectedRows={selectedRowKeys[selectedType].map((s) => s && s.key)}
              onChange={(keys, rows) => {
                console.log(keys, rows);
                setSelectedRowKeys({ ...selectedRowKeys, [selectedType]: rows });
              }}
            />
          )
      }
    </Modal>
  );
}

export default function Recipients({ config }) {
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const formValues = useSelector((state) => state.messagePayload, shallowEqual);

  function clickBack() {
    history.goBack();
  }

  return (
    <>
      <RecipientsModal
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        onCancel={() => setModalVisible(false)}
      />
      <Card
        title="Destinataires"
        type="inner"
        style={{ width: '100%', marginTop: 16 }}
      >
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <List
              locale={{ emptyText: 'Aucun destinataire' }}
              className="demo-loadmore-list"
              itemLayout="horizontal"
              dataSource={[...formValues.recipients.contacts, ...formValues.recipients.lists]}
              renderItem={(item) => (
                <List.Item
                  actions={[(
                    <Popconfirm
                      title={`Retirer ce ${item.list_name ? 'cette liste' : 'ce destinataire'} ?`}
                      okText="Oui"
                      onConfirm={() => {
                        const type = item.list_name ? 'lists' : 'contacts';

                        console.log('...', {
                          ...formValues.recipients,
                          [type]: _.reject(formValues.recipients[type], item),
                        });

                        dispatch({
                          type: 'EMAIL_FORM_SUBMITTED',
                          payload: {
                            recipients: {
                              ...formValues.recipients,
                              [type]: _.reject(formValues.recipients[type], item),
                            },
                          },
                        });
                      }}
                    >
                      <Button danger>
                        Retirer
                      </Button>
                    </Popconfirm>
                  )]}
                >
                  <List.Item.Meta
                    description={<RecipientDetails item={item} type={item.list_name ? 'list' : 'contact'} />}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Button type="primary" onClick={() => setModalVisible(true)}>Ajouter un destinataire</Button>
          </Col>
        </Row>
      </Card>

      <Row gutter={[8, 8]} style={{ marginTop: 8 }}>
        <Col
          span={24}
          style={{
            textAlign: 'right',
          }}
        >
          <Form.Item>
            <Button onClick={clickBack} style={{ marginRight: '8px' }}>
              Précédent
            </Button>
            <Button
              onClick={() => {
                history.push(`/envoi/${config.type}/attachments`);
              }}
              type="primary"
              disabled={!formValues.recipients.contacts.length && !formValues.recipients.lists.length}
              htmlType="submit"
            >
              Suivant
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

function RecipientDetails({ type, item }) {
  return type === 'contact'
    ? (
      <div className="contact_container">
        <div>
          <span style={{ marginBottom: 0, color: '#000', fontWeight: 600 }}>{item.first_name} {item.last_name} </span>
          <span style={{ fontSize: '0.9em' }}>({item.email})</span>
        </div>
        <div>{item.address_line_1}</div>
        {item.address_line_2 && (<div>{item.address_line_2}</div>)}
        <div>{item.city}, {item.postal_code}</div>
      </div>
    )
    : (
      <div className="list_container">
        <h3>{item.list_name}</h3>
        <div>{item.contacts.map((contact) => (<RecipientDetails type="contact" item={contact} key={`l${item.key}c${contact.key}`} />))}</div>
      </div>
    );
}
