import React, { useEffect } from "react";
import { PageHeader, Row, Col } from "antd";
// Icons
import { MailFilled, PrinterFilled, LaptopOutlined } from "@ant-design/icons";
import * as firebase from "firebase";

// React Router
import { Switch, Route, Link, useRouteMatch } from "react-router-dom";

// Lodash
import _ from "lodash";

// Components
import SendingForm from "./Form";

export default function () {
  const { path, url, isExact } = useRouteMatch("/envoi");

  let config = {};
  /*
  useEffect(() => {
    const sendScheduledMessages = firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('sendScheduledMessages');

    sendScheduledMessages()
      .then((r) => {
        console.log('!!', r);
      })
      .catch((e) => {
        console.error('Errr', e);
      });
  }, []);
  */
  if (!isExact) {
    const route = useRouteMatch("/envoi/:type");

    switch (route.params.type) {
      case "electronique":
        config = {
          headerSubtitle: "Recommandé électronique",
          printed: false,
          pickedKeys: [
            "date_type",
            "subject",
            "content",
            "ref1",
            "ref2",
            "date",
            "contacts",
            "attachments",
            "comment",
          ],
          stepVerifications: [
            // Configuration
            {
              path: "configuration",
              fn: (values) => {
                const keys = ["date_type", "subject", "content"];

                if (values && values.date_type === "deferred") {
                  keys.push("date");
                }

                const flat = _.map(_.pick(values, keys));
                const hasAllKeys = _.every(keys, _.partial(_.has, values));

                return hasAllKeys && _.every(flat);
              },
            },
            // Recipients
            {
              path: "recipients",
              fn: (values) =>
                values &&
                values.recipients &&
                ((values.recipients.contacts && values.recipients.contacts.length) ||
                  (values.recipients.lists && values.recipients.lists.length)),
            },
          ],
        };
        break;
      case "simple":
        config = {
          headerSubtitle: "Lettre papier simple",
          printed: true,
          pickedKeys: [
            "date_type",
            "ref1",
            "ref2",
            "date",
            "color_option",
            "face_option",
            "recipients_type",
            "envelope",
            "contacts",
            "attachments",
            "comment",
          ],
          stepVerifications: [
            // Configuration
            {
              path: "configuration",
              fn: (values) => {
                const keys = [
                  "date_type",
                  "color_option",
                  "face_option",
                  "recipients_type",
                  "envelope",
                ];

                if (values && values.date_type === "deferred") {
                  keys.push("date");
                }

                const flat = _.map(_.pick(values, keys));
                const hasAllKeys = _.every(keys, _.partial(_.has, values));

                return hasAllKeys && _.every(flat);
              },
            },
          ],
        };
        break;
      case "recommande":
      default:
        config = {
          headerSubtitle: "Lettre recommandée",
          printed: true,
          pickedKeys: [
            "date_type",
            "ref1",
            "ref2",
            "date",
            "color_option",
            "face_option",
            "receipt_aknowledge",
            "level",
            "contacts",
            "attachments",
            "comment",
          ],
          stepVerifications: [
            // Configuration
            {
              path: "configuration",
              fn: (values) => {
                const keys = [
                  "date_type",
                  "color_option",
                  "face_option",
                  "receipt_aknowledge",
                  "level",
                ];

                if (values && values.date_type === "deferred") {
                  keys.push("date");
                }

                const flat = _.map(_.pick(values, keys));
                const hasAllKeys = _.every(keys, _.partial(_.has, values));

                return hasAllKeys && _.every(flat);
              },
            },
          ],
        };
        break;
    }

    config.type = route.params.type;
  }

  return (
    <>
      <PageHeader
        onBack={
          !isExact
            ? () => {
                window.history.back();
              }
            : null
        }
        style={{ padding: 0, marginBottom: 24 }}
        title="Nouvel envoi"
        subTitle={config.headerSubtitle}
      />

      <Switch>
        {/* Index */}
        <Route exact path={path}>
          {/* <Redirect to="/envoi/electronique/configuration" /> */}
          <Row id="letter-type-selection" gutter={[16, 16]}>
            <Col span={6} offset={0}>
              <Link to={`${url}/simple/configuration`}>
                <div className="card">
                  <MailFilled />
                  <div className="text">
                    <div>Lettre papier simple</div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col span={6} offset={0}>
              <Link to={`${url}/recommande/configuration`}>
                <div>
                  <div className="card">
                    <PrinterFilled />
                    <div className="text">
                      <div>Recommandé papier</div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col span={6} offset={0}>
              <Link to={`${url}/electronique/configuration`}>
                <div>
                  <div className="card">
                    <LaptopOutlined />
                    <div className="text">
                      <div>Lettre recommandée électronique</div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </Route>

        {/* Form */}
        <Route
          path={[`${path}/electronique/:step`, `${path}/simple/:step`, `${path}/recommande/:step`]}
        >
          <SendingForm config={config} />
        </Route>
      </Switch>
    </>
  );
}
