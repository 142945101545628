const useReducer = {
  user: (state = null, { type, payload }) => {
    switch (type) {
      case "LOGIN_USER":
        return payload;
      case "SIGN_OUT":
        return null;
      default:
        return state;
    }
  },
};
export default useReducer;
