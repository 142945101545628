import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Badge,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Table,
  message,
  Checkbox,
  Switch,
} from "antd";

// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import _ from "lodash";
import Title from "antd/es/typography/Title";

function UserForm({ values, form }) {
  const authUser = useSelector((state) => state.user, shallowEqual);
  const canEdit2FA = useMemo(() => {
    return values && values.has2FAActivated;
  }, [values]);
  console.log({ authUser });
  return values ? (
    <Form layout="vertical" form={form} initialValues={values}>
      <Form.Item
        name="last_name"
        label="Nom"
        rules={[{ required: true, message: "Veuillez entrer un nom" }]}
      >
        <Input placeholder="Ex: Dupont" />
      </Form.Item>

      <Form.Item
        name="first_name"
        label="Prénom"
        rules={[{ required: true, message: "Veuillez entrer un prénom" }]}
      >
        <Input placeholder="Ex: Jean" />
      </Form.Item>

      <Form.Item name="address_line_1" label="Adresse ligne 1" rules={[{ required: false }]}>
        <Input placeholder="Adresse postale, boîte postale" />
      </Form.Item>

      <Form.Item name="address_line_2" label="Adresse ligne 2" rules={[{ required: false }]}>
        <Input placeholder="Appartement, suite, unité, immeuble, étage, etc." />
      </Form.Item>

      <Form.Item name="postal_code" label="Code postal" rules={[{ required: false }]}>
        <Input placeholder="Ex: 75008" />
      </Form.Item>

      <Form.Item name="city" label="Ville" rules={[{ required: false }]}>
        <Input placeholder="Ex: Paris" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Adresse email"
        // rules={[{ required: true, message: 'Veuillez entrer un email' }, { type: 'email', message: 'Veuillez entrer un email valide' }]}
      >
        <Input placeholder="Ex: jean.dupont@gmail.com" disabled />
      </Form.Item>

      <Form.Item name="phone" label="Téléphone">
        <Input placeholder="Ex: 0123456789" />
      </Form.Item>

      {authUser.is_company_admin && (
        <Form.Item name="is_account_admin" valuePropName="checked">
          <Checkbox>Administrateur d'équipe</Checkbox>
        </Form.Item>
      )}
      {authUser.is_company_admin && (
        <Form.Item
          name="has2FAActivated"
          valuePropName="checked"
          label="Solidoc Authenticator"
          tooltip={canEdit2FA ? "" : "Seul l'utilisateur peut activer Solidoc Authenticator"}
        >
          <Switch
            disabled={!canEdit2FA}
            checkedChildren={"Activé"}
            unCheckedChildren={"Désactivé"}
          />
        </Form.Item>
      )}
    </Form>
  ) : (
    <Form layout="vertical" form={form} initialValues={values}>
      <Form.Item
        name="last_name"
        label="Nom"
        rules={[{ required: true, message: "Veuillez entrer un nom" }]}
      >
        <Input placeholder="Ex: Dupont" />
      </Form.Item>

      <Form.Item
        name="first_name"
        label="Prénom"
        rules={[{ required: true, message: "Veuillez entrer un prénom" }]}
      >
        <Input placeholder="Ex: Jean" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Adresse email"
        rules={[
          { required: true, message: "Veuillez entrer un email" },
          { type: "email", message: "Veuillez entrer un email valide" },
        ]}
      >
        <Input placeholder="Ex: jean.dupont@gmail.com" />
      </Form.Item>
    </Form>
  );
}

function AuthModal({ visible, ...restProps }) {
  return (
    <Modal visible={visible} {...restProps} title="Activer Solidoc Authenticator">
      <div style={{ textAlign: "center", marginBottom: "1rem" }}>
        Cette action rendra obligatoire l’authentification à double facteur « Solidoc
        Authenticator » via un numéro de téléphone pour l’ensemble de votre équipe.
      </div>
    </Modal>
  );
}
function RemoveAuthModal({ visible, ...restProps }) {
  return (
    <Modal visible={visible} {...restProps} title="Désactiver Solidoc Authenticator">
      <div style={{ textAlign: "center", marginBottom: "1rem" }}>
        Cette action ne rendra plus obligatoire l’authentification à double facteur « Solidoc
        Authenticator » via un numéro de téléphone pour l’ensemble de votre équipe.
      </div>
    </Modal>
  );
}

function UserModal({ visible, user, onSubmit, onCancel, isFormLoading, setIsFormLoading }) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, user]);

  useEffect(() => {
    if (visible === true) {
      setIsFormLoading(false);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title={user ? "Modifier un utilisateur" : "Inviter un nouvel utilisateur"}
      okText={user ? "Modifier" : "Inviter"}
      canceltext="Annuler"
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsFormLoading(true);
            onSubmit(values, form.resetFields);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ loading: isFormLoading }}
    >
      <UserForm form={form} values={user} />
    </Modal>
  );
}

export default function () {
  const db = firebase.firestore();
  const inviteUser = firebase.app().functions("europe-west1").httpsCallable("inviteUser");
  const authUser = useSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [removeAuthModalVisible, setRemoveAuthModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(new Date().getTime());

  const [company, setCompany] = useState(null);
  useEffect(async () => {
    const company = await authUser.company.get();
    console.log(company);
    setCompany({ ...company.data(), id: company.id });
  }, [authUser]);

  const columns = [
    {
      title: "Nom",
      dataIndex: "last_name",
    },
    {
      title: "Prénom",
      dataIndex: "first_name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Téléphone",
      dataIndex: "phone",
    },
    {
      title: "Statut",
      key: "status",
      render: (text, record) => (
        <Space size="small">
          {record.isActive ? (
            <Badge status="success" text="Actif" />
          ) : (
            <Badge status="warning" text="En attente" />
          )}
        </Space>
      ),
    },
  ];

  if (authUser.is_company_admin) {
    columns.push({
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="small">
          <Button
            onClick={() => {
              setSelectedUser(record);
              setModalVisible(true);
            }}
            type="primary"
            title="Modifier"
            size="small"
            shape="circle"
            icon={<EditOutlined />}
          />
        </Space>
      ),
    });
  }

  useEffect(() => {
    setUsers([]);
    db.collection("users")
      .doc(authUser.uid)
      .get()
      .then((userSnapshot) => {
        const userData = userSnapshot.data();

        if (userData.company) {
          db.collection("users")
            .where("company", "==", userData.company)
            .get()
            .then((snapshot) => {
              const r = [];
              snapshot.docs.forEach((doc, index) => {
                const data = doc.data();

                if (!data.disabled) {
                  r.push({
                    ...data,
                    uid: doc.id,
                    key: doc.id,
                    index,
                  });
                }
              });

              setUsers(r);
            });
        } else {
          setUsers([userData]);
        }
      });
  }, [lastUpdate]);

  return (
    <>
      <h1>Mon équipe</h1>

      {authUser.is_company_admin && (
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedUser(null);
                setModalVisible(true);
              }}
              style={{ marginRight: "1rem" }}
            >
              Inviter un utilisateur
            </Button>
            {company && company.force2FA ? (
              <Button
                type="primary"
                onClick={() => {
                  setRemoveAuthModalVisible(true);
                }}
              >
                Désactiver Solidoc Authenticator
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  setAuthModalVisible(true);
                }}
              >
                Activer Solidoc Authenticator
              </Button>
            )}
          </Col>
        </Row>
      )}

      <Table
        bordered
        style={{ marginTop: 16 }}
        columns={columns}
        dataSource={users}
        size="small"
        scroll={{ x: true }}
        loading={!users.length}
        pagination={false}
      />

      {/* Modal*/}
      <AuthModal
        visible={authModalVisible}
        onCancel={() => setAuthModalVisible(false)}
        onOk={async () => {
          await db.collection("companies").doc(company.id).set({ force2FA: true }, { merge: true });

          const data = {
            type: "LOGIN_USER",
            payload: {
              ...authUser,
              companyData: {
                ...company,
                force2FA: true,
              },
            },
          };
          dispatch(data);

          setAuthModalVisible(false);
        }}
      />
      <RemoveAuthModal
        visible={removeAuthModalVisible}
        onCancel={() => setRemoveAuthModalVisible(false)}
        onOk={async () => {
          await db
            .collection("companies")
            .doc(company.id)
            .set({ force2FA: false }, { merge: true });

          const data = {
            type: "LOGIN_USER",
            payload: {
              ...authUser,
              companyData: {
                ...company,
                force2FA: false,
              },
            },
          };
          dispatch(data);

          setRemoveAuthModalVisible(false);
        }}
      />
      <UserModal
        visible={modalVisible}
        user={selectedUser}
        onCancel={() => {
          setModalVisible(false);
        }}
        isFormLoading={isFormLoading}
        setIsFormLoading={setIsFormLoading}
        onSubmit={(values, resetFields) => {
          if (selectedUser) {
            db.collection("users")
              .doc(selectedUser.uid)
              .set(
                { ...values, phone2Fa: values.has2FAActivated ? values.phone2Fa : "" },
                { merge: true }
              )
              .then(() => {
                /*
                  const clone = users;

                  clone.splice(selectedUser.index, 1, {
                    ...selectedUser,
                    ...values,
                  });
                  setUsers([...clone]);
                */
                setLastUpdate(new Date().getTime());
                resetFields();
                setModalVisible(false);
                message.success("Modifications enregistrées");
              })
              .catch((e) => {
                setIsFormLoading(false);
                message.error("Une erreur est survenue. Veuillez réessayer.");
                console.error(e);
              });
          } else {
            console.log("Inviting user...", values);
            inviteUser(values).then((response) => {
              const { data } = response;
              console.log("Response", data);

              if (data.error) {
                const errorObj = data.data && data.data.errorInfo;

                const displayMessage =
                  errorObj && errorObj.code === "auth/email-already-exists"
                    ? "Cette addresse email est déjà utilisée."
                    : "Une erreur est survenue. Veuillez réessayer.";

                setIsFormLoading(false);

                message.error(displayMessage);
              } else {
                resetFields();
                setModalVisible(false);
                setLastUpdate(new Date().getTime());
                message.success("Invitation envoyée");
              }
            });
          }
        }}
      />
    </>
  );
}
