import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Form, Row, List, Popconfirm } from "antd";

import getFileIcon from "../../../../modules/helpers/fileIcon";

import { useHistory } from "react-router-dom";
import _ from "lodash";
import ImageUploadModal from "./Modals/ImageUploadModal";

export default function Attachments({ config }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const formValues = useSelector((state) => state.messagePayload, shallowEqual);

  const [showUploadModal, setShowUploadModal] = useState(false);

  function clickBack() {
    history.goBack();
  }

  return (
    <div
      style={{
        minHeight: "20rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
        <Button type="primary" onClick={() => setShowUploadModal(true)}>
          Ajouter des documents
        </Button>
        <ImageUploadModal
          visible={showUploadModal}
          onCancel={() => {
            setShowUploadModal(false);
          }}
          onOk={(files) => {
            console.log({ files });
            if (files.length) {
              dispatch({
                type: "EMAIL_FORM_SUBMITTED",
                payload: {
                  attachments: [...formValues.attachments, ...files],
                },
              });
            }
            setShowUploadModal(false);
          }}
        />
      </div>

      {
        /* Upload list */
        formValues.attachments.length > 0 && (
          <Card
            type="inner"
            title={`Liste des ${config.printed ? "documents" : "pièces jointes"}`}
            style={{ width: "100%", marginTop: 16 }}
            bodyStyle={{ padding: "8px 24px 0" }}
          >
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <List
                  className="demo-loadmore-list"
                  itemLayout="horizontal"
                  dataSource={formValues.attachments}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Popconfirm
                          title={`Retirer ${
                            config.printed ? "ce document" : "cette pièce jointe"
                          } ?`}
                          okText="Oui"
                          onConfirm={() => {
                            dispatch({
                              type: "EMAIL_FORM_SUBMITTED",
                              payload: {
                                attachments: _.reject(formValues.attachments, item),
                              },
                            });
                          }}
                        >
                          <Button danger>Retirer</Button>
                        </Popconfirm>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={getFileIcon(item.mimetype)}
                        title={item.filename}
                        description={
                          <a href={item.url} rel="noopener noreferrer" target="_blank">
                            Ouvrir le fichier
                          </a>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Card>
        )
      }
      <Row gutter={[8, 8]} style={{ marginTop: 8 }}>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Form.Item>
            <Button onClick={clickBack} style={{ marginRight: "8px" }}>
              Précédent
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={formValues.attachments.length <= 0 && config.printed}
              onClick={() => {
                history.push(`/envoi/${config.type}/summary`);
              }}
            >
              {formValues.attachments.length === 0 && !config.printed
                ? `Continuer sans ${config.printed ? "documents" : "pièces jointes"}`
                : "Suivant"}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
