import React, { useEffect, useState } from 'react';
import { Col, Input, Row } from 'antd';

// Firebase
import * as firebase from 'firebase/app';
import 'firebase/auth';

import _ from 'lodash';
import moment from 'moment';

import UsersTable from '../Accounts/UsersTable';

const { Search } = Input;

export default function () {
  const db = firebase.firestore();
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date().getTime());
  const [searchTerms, setSearchTerms] = useState('');
  const [searchArr, setSearchArr] = useState([]);

  useEffect(() => {
    if (searchTerms === '') {
      setDisplayedUsers([...users]);
    } else {
      const r = searchArr
        .filter((a) => new RegExp(searchTerms, 'ig').test(a.key))
        .map((a) => a.value);

      setDisplayedUsers(
        _.filter(users, (user) => r.indexOf(user.uid) !== -1),
      );
    }
  }, [searchTerms, searchArr]);

  useEffect(() => {
    db
      .collection('users')
      .get()
      .then((usersSnapshot) => {
        const search = [];

        if (usersSnapshot.docs.length) {
          db
            .collection('companies')
            .get()
            .then((companiesSnapshot) => {
              // const r = _.keyBy(companiesSnapshot.docs.map((snap) => snap.data()), 'id');
              const r = companiesSnapshot.docs.map((snap) => {
                return {
                  ...snap.data(),
                  ref: snap.ref,
                  key: snap.id,
                  id: snap.id,
                };
              });

              const u = usersSnapshot.docs.map((userSnap) => {
                const userData = userSnap.data();

                search.push({
                  key: `${userData.email} ${userData.first_name} ${userData.last_name}`,
                  value: userSnap.id,
                });

                userData.last_invite = userData.last_invite || userData.created_at || moment();

                return {
                  ...userData,
                  company: userData.company.id,
                  uid: userSnap.id,
                  key: userSnap.id,
                  has_connected: !!userData.last_connected,
                  last_connected: userData.last_connected
                    ? moment(userData.last_connected.toDate())
                    : moment(0),
                  last_invite: userData.isActive
                    ? moment()
                    : moment((userData.last_invite || userData.created_at).toDate()),
                };
              });

              setUsers(u);
              setDisplayedUsers(u);
              setCompanies(r);
              setSearchArr(search);
            });
        }
      });
  }, [lastUpdate]);

  return (
    <>
      <h1>Liste des utilisateurs</h1>

      <Row>
        <Col span={24} style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>
            <Search
              placeholder="Recherche"
              onSearch={(value) => { setSearchTerms(value); }}
              onChange={(e) => { if (e.target.value === '') setSearchTerms(''); }}
              style={{ paddingRight: 32, maxWidth: 500 }}
              enterButton
              allowClear
            />
          </div>
        </Col>
      </Row>

      <UsersTable
        company={{ users: displayedUsers, name: '' }}
        companies={companies}
        setLastUpdate={setLastUpdate}
        searchTerms={searchTerms}
        allUsersView
      />
    </>
  );
}
