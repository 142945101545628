import { Alert, Dropdown, Menu, Modal, Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import React, { useMemo } from "react";
import * as firebase from "firebase";

const ChangeStatusDropdown = ({ record, onConfirm, ...restProps }) => {
  const message = record.recipients[0];
  const db = firebase.firestore();
  const updateRecord = (type) => {
    return db
      .collection("companies")
      .doc(record.companyId)
      .collection("messages")
      .doc(record.key)
      .collection("recipients")
      .doc(record.recipients[0].key)
      .set(
        {
          status: type,
          send_date: type === "sent" ? firebase.firestore.FieldValue.serverTimestamp() : null,
        },
        { merge: true }
      )
      .then(() => {
        if (onConfirm)
          onConfirm({
            status: type,
          }); // emails are sent using cloud functions
      });
  };
  const onMenuItemClick = (menuInfo) => {
    switch (menuInfo.key) {
      case "sent":
        Modal.confirm({
          title: "Marquer comme envoyé",
          icon: <ExclamationCircleOutlined />,
          width: "650px",
          content: <div>Êtes-vous sûr de vouloir marquer l'envoi comme étant "Envoyé" ?</div>,
          okText: "Oui",
          okType: "primary",
          onOk: () => {
            return updateRecord("sent");
          },
        });
        break;
      case "prepare":
        Modal.confirm({
          title: "Marquer en prépartion",
          icon: <ExclamationCircleOutlined />,
          width: "650px",
          content: (
            <div>Êtes-vous sûr de vouloir marquer l'envoi comme étant "En préparation" ?</div>
          ),
          okText: "Oui",
          okType: "primary",
          onOk: () => {
            return updateRecord("prepare");
          },
        });
        break;
      case "error_address":
        Modal.confirm({
          title: "Marquer comme Erreur d'adressage",
          icon: <ExclamationCircleOutlined />,
          width: "650px",
          content: (
            <div>
              <div style={{ marginBottom: "1rem" }}>
                Êtes-vous sûr de vouloir marquer l'envoi comme étant "Erreur d'adressage" ?
              </div>
              <Alert type="warning" message="Cette action enverra un mail à l'utilisateur!" />
            </div>
          ),
          okText: "Oui",
          okType: "primary",
          onOk: () => {
            return updateRecord("error_address");
          },
        });
        break;
      case "error_file":
        Modal.confirm({
          title: "Marquer comme Erreur fichier",
          icon: <ExclamationCircleOutlined />,
          width: "650px",
          content: (
            <div>
              <div style={{ marginBottom: "1rem" }}>
                Êtes-vous sûr de vouloir marquer l'envoi comme étant "Erreur fichier" ?
              </div>
              <Alert type="warning" message="Cette action enverra un mail à l'utilisateur!" />
            </div>
          ),
          okText: "Oui",
          okType: "primary",
          onOk: () => {
            return updateRecord("error_file");
          },
        });
        break;
      case "error_print":
        Modal.confirm({
          title: "Marquer comme Erreur d'impression",
          icon: <ExclamationCircleOutlined />,
          width: "650px",
          content: (
            <div>
              <div style={{ marginBottom: "1rem" }}>
                Êtes-vous sûr de vouloir marquer l'envoi comme étant "Erreur d'impression" ?
              </div>
              <Alert type="warning" message="Cette action enverra un mail à l'utilisateur!" />
            </div>
          ),
          okText: "Oui",
          okType: "primary",
          onOk: () => {
            return updateRecord("error_print");
          },
        });
        break;
      case "error_print_param":
        Modal.confirm({
          title: "Marquer comme envoyé",
          icon: <ExclamationCircleOutlined />,
          width: "650px",
          content: (
            <div>
              <div style={{ marginBottom: "1rem" }}>
                Êtes-vous sûr de vouloir marquer l'envoi comme étant "Erreur paramètres
                d'impression" ?
              </div>
              <Alert type="warning" message="Cette action enverra un mail à l'utilisateur!" />
            </div>
          ),
          okText: "Oui",
          okType: "primary",
          onOk: () => {
            return updateRecord("error_print_param");
          },
        });
        break;
    }
  };
  const menu = (
    <Menu onClick={onMenuItemClick}>
      <Menu.Item key="sent">
        <Tag icon={<CheckCircleOutlined />} color="cyan">
          Envoyé
        </Tag>
      </Menu.Item>
      <Menu.Item key="prepare">
        <Tag icon={<SyncOutlined />} color="processing">
          En préparation
        </Tag>
      </Menu.Item>
      <Menu.Item key="error_address">
        <Tag icon={<CloseCircleOutlined />} color="red">
          Erreur d'adressage
        </Tag>
      </Menu.Item>
      <Menu.Item key="error_file">
        <Tag icon={<CloseCircleOutlined />} color="red">
          Erreur fichier
        </Tag>
      </Menu.Item>
      <Menu.Item key="error_print">
        <Tag icon={<CloseCircleOutlined />} color="red">
          Erreur d'impression
        </Tag>
      </Menu.Item>
      <Menu.Item key="error_print_param">
        <Tag icon={<CloseCircleOutlined />} color="red">
          Erreur paramètres d’impression
        </Tag>
      </Menu.Item>
    </Menu>
  );

  const TagMessage = useMemo(() => {
    if (message) {
      switch (message.status) {
        case "sent":
          return (
            <Tag icon={<CheckCircleOutlined />} color="cyan">
              Envoyé
            </Tag>
          );
        case "error_address":
          return (
            <Tag icon={<CloseCircleOutlined />} color="red">
              Erreur d'adressage
            </Tag>
          );
        case "error_file":
          return (
            <Tag icon={<CloseCircleOutlined />} color="red">
              Erreur fichier
            </Tag>
          );
        case "error_print":
          return (
            <Tag icon={<CloseCircleOutlined />} color="red">
              Erreur d'impression
            </Tag>
          );
        case "error_print_param":
          return (
            <Tag icon={<CloseCircleOutlined />} color="red">
              Erreur paramètres d’impression
            </Tag>
          );
        case "prepare":
        default:
          return (
            <Tag icon={<SyncOutlined />} color="processing">
              En préparation
            </Tag>
          );
      }
    }
  }, [message]);
  return (
    <Dropdown.Button
      overlay={menu}
      icon={<DownOutlined />}
      trigger="click"
      className="table-dropdown-status"
      {...restProps}
    >
      {TagMessage}
    </Dropdown.Button>
  );
};

export default ChangeStatusDropdown;
