import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Tag, Table, Button } from "antd";

// Icons
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

// Moment
import moment from "moment";

// Firebase
import "firebase/firestore";
import * as firebase from "firebase";

const STATUSES = (type) => ({
  prepare: {
    tag: (
      <Tag icon={<SyncOutlined />} color="processing">
        {type === "electronique" ? "En cours d'envoi" : "En préparation"}
      </Tag>
    ),
  },
  scheduled: {
    tag: (
      <Tag icon={<ClockCircleOutlined />} color="default">
        Programmé
      </Tag>
    ),
  },
  sent: {
    downloadText: "Preuve de dépôt",
    tag: (
      <Tag icon={<CheckCircleOutlined />} color="cyan">
        Envoyé
      </Tag>
    ),
  },
  accepted: {
    downloadText: "Preuve d'acceptation",
    tag: (
      <Tag icon={<CheckCircleOutlined />} color="success">
        Accepté
      </Tag>
    ),
  },
  refused: {
    downloadText: "Preuve de refus",
    tag: (
      <Tag icon={<CloseCircleOutlined />} color="error">
        Refusé
      </Tag>
    ),
  },
  neglected: {
    downloadText: "Preuve de négligence",
    tag: (
      <Tag icon={<CloseCircleOutlined />} color="error">
        Négligé
      </Tag>
    ),
  },
});

export default function RecipientsTable({ data, recipients }) {
  const { type } = useParams();
  let showTitle = false;
  let arr;

  if (recipients) {
    arr = recipients;
    showTitle = true;
  } else {
    arr = data;
  }

  // const arr = recipients || data;
  const dispatch = useDispatch();

  const columnsHead = [
    { title: "Nom", dataIndex: "last_name" },
    { title: "Prénom", dataIndex: "first_name" },
    {
      title: "Type",
      dataIndex: "type",
      render: (type) => (type === "individual" ? "Particulier" : "Professionnel"),
    },
    { title: "Email", dataIndex: "email" },
  ];

  const columnsTail = [
    {
      title: "Dernier status",
      dataIndex: "status",
      render: (status) => STATUSES(type)[status] && STATUSES(type)[status].tag,
    },
    {
      title: "Date de status",
      dataIndex: "last_updated",
      render: (lastUpdated) =>
        lastUpdated && moment(lastUpdated.toDate()).format("DD/MM/YYYY HH:mm"),
    },
  ];

  return (
    <Table
      title={showTitle ? () => <h4>Liste des destinataires</h4> : null}
      columns={[
        ...columnsHead,
        /* (
          data.type === 'electronique'
            ? { title: 'Email', dataIndex: 'email' }
            : {
              title: 'Adresse',
              key: 'address',
              render: (recipient) => (
                <>
                  <div>{recipient.address_line_1}</div>
                  {recipient.address_line_2 && <div>{recipient.address_line_2}</div>}
                  <div>{recipient.city}, {recipient.postal_code}</div>
                </>
              ),
            }
        ), */
        ...columnsTail,
      ]}
      dataSource={arr}
      pagination={false}
      size="small"
      loading={!arr}
      locale={{ emptyText: "Récupération des données" }}
      bordered
      expandable={{
        expandedRowRender: proofsTable(
          () => {
            dispatch({ type: "SHOW_LOADER", payload: "Récupération de la preuve..." });
          },
          () => {
            dispatch({ type: "HIDE_LOADER" });
          }
        ),
        expandIcon: ({ expanded, onExpand, record }) => (
          <Button
            size="small"
            style={{ fontSize: 12, fontWeight: 600 }}
            onClick={(e) => onExpand(record, e)}
          >
            {type === "electronique" ? "Status et preuves" : "Status"}
          </Button>
        ),
      }}
    />
  );
}

function proofsTable(showLoader, hideLoader) {
  return function TableComponent(data) {
    const { type } = useParams();

    const columns = [
      {
        title: "Nom de status",
        dataIndex: "type",
        render: (status) => STATUSES(type)[status].tag,
      },
      {
        title: "Date",
        dataIndex: "date",
        render: (timestamp) => moment(timestamp.toDate()).format("DD/MM/YYYY à HH:mm"),
      },
    ];

    if (type === "electronique") {
      const linkUrl =
        process.env.REACT_APP_PROJECT_ID === "solidoc"
          ? "https://storage.googleapis.com/solidoc-proofs/"
          : "https://storage.googleapis.com/solidoc-proofs-dev/";
      columns.push({
        title: "Preuve",
        dataIndex: "path",
        render: (path, record) =>
          path ? (
            <a href={`${linkUrl}${path}`} target="_blank" rel="noopener noreferrer">
              {STATUSES(type)[record.type].downloadText}
            </a>
          ) : (
            ""
          ),
      });
    }

    const proofs = data.proofs
      ? data.proofs.map((proof) => ({
          ...proof,
          key: proof.date.toDate().getTime(),
        }))
      : [];

    return (
      <Table
        title={() => <h4>{type === "electronique" ? "Status et preuves" : "Status"}</h4>}
        columns={columns}
        dataSource={proofs}
        pagination={false}
        size="small"
      />
    );
  };
}
