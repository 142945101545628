import React from "react";
import { Layout } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

const { Header: LayoutHeader } = Layout;

const Header = () => {
  const sideMenuCollapsed = useSelector((state) => state.sideMenuCollapsed);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <LayoutHeader
      className="site-layout-background"
      id="header"
      style={{ padding: 0, background: "#fff" }}
    >
      {React.createElement(sideMenuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: () => dispatch({ type: "TOGGLE_SIDE_MENU_VISIBILITY" }),
      })}
      <h3 style={{ float: "right", paddingRight: 24 }}>
        {user.first_name} {user.last_name} ({user.email}) | {user.companyName}
      </h3>
    </LayoutHeader>
  );
};

export default Header;
