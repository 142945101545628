import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Row, Col } from 'antd';

import { PaperClipOutlined } from '@ant-design/icons';

// Firebase
import * as firebase from 'firebase/app';
import 'firebase/auth';

export default function ({ setLoader }) {
  const { id, token } = useParams();

  const [status, setStatus] = useState(null);
  const refuseMessage = firebase.app().functions('europe-west1').httpsCallable('refuseMessage');

  useEffect(() => {
    console.log('Reading message...');

    refuseMessage({ recipientId: id, token })
      .then((response) => {
        setLoader(false);
        setStatus(response.data);
        console.log(response);
      });
  }, []);

  return (
    <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
      {
        // eslint-disable-next-line no-nested-ternary
        status
        && (status === 'accepted' || status === 'refused')
          ? (
            status === 'accepted'
              ? (<h2>Ce recommandé à déjà été accepté</h2>)
              : (<h2>Votre recommandé à bien été refusé</h2>)
          )
          : ''
      }
    </div>
  );
}
