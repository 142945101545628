import React, { useEffect, useState } from 'react';
import {
  Form,
  Modal,
  Radio,
  Table,
  Input,
  Space,
  Button,
  Row,
  Col,
  Select,
} from 'antd';

// React-Redux
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// Icons
import {
  EditOutlined, DeleteOutlined, ExclamationCircleOutlined, SearchOutlined,
} from '@ant-design/icons';

// Firebase
import * as firebase from 'firebase/app';
import 'firebase/firestore';

// Lodash
import _ from 'lodash';

const { Search } = Input;
const { confirm } = Modal;
const { Option } = Select;

function sorterFn(property) {
  return (a, b) => (`${a[property]}`).toLowerCase().localeCompare((`${b[property]}`).toLowerCase());
}

function ContactForm({ values, form }) {
  const [contactType, setContactType] = useState(null);
  return (
    <Form layout="vertical" form={form} initialValues={values}>
      <Form.Item
        name="last_name"
        label="Nom"
        rules={[{ required: true, message: 'Veuillez entrer un nom' }]}
      >
        <Input placeholder="Ex: Dupont" />
      </Form.Item>

      <Form.Item
        name="first_name"
        label="Prénom"
        rules={[{ required: true, message: 'Veuillez entrer un prénom' }]}
      >
        <Input placeholder="Ex: Jean" />
      </Form.Item>

      <Form.Item
        name="address_line_1"
        label="Adresse ligne 1"
        rules={[{ required: true, message: 'Veuillez entrer une adresse' }]}
      >
        <Input placeholder="Adresse postale, boîte postale, nom de la société" />
      </Form.Item>

      <Form.Item
        name="address_line_2"
        label="Adresse ligne 2"
        rules={[{ required: false }]}
      >
        <Input placeholder="Appartement, suite, unité, immeuble, étage, etc." />
      </Form.Item>

      <Form.Item
        name="postal_code"
        label="Code postal"
        rules={[{ required: true, message: 'Veuillez entrer un code postal' }]}
      >
        <Input placeholder="Ex: 75008" />
      </Form.Item>

      <Form.Item
        name="city"
        label="Ville"
        rules={[{ required: true, message: 'Veuillez entrer une ville' }]}
      >
        <Input placeholder="Ex: Paris" />
      </Form.Item>

      <Form.Item
        name="type"
        label="Type"
        rules={[{ required: true, message: 'Veuillez sélectionner un type' }]}
      >
        <Select placeholder="Type" onChange={(value) => setContactType(value)}>
          <Option value="individual">Particulier</Option>
          <Option value="professional">Professionnel</Option>
        </Select>
      </Form.Item>

      {
        contactType && contactType === 'professional' && (
          <Form.Item
            name="company"
            label="Entreprise"
          >
            <Input />
          </Form.Item>
        )
      }

      <Form.Item
        name="email"
        label="Adresse email"
        rules={[{ required: true, message: 'Veuillez entrer un email' }, { type: 'email', message: 'Veuillez entrer un email valide' }]}
      >
        <Input placeholder="Ex: jean.dupont@gmail.com" />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Téléphone"
      >
        <Input placeholder="Ex: 0123456789" />
      </Form.Item>
    </Form>
  );
}

function ContactModal({ visible, contact, onSubmit, onCancel }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [form, contact]);

  useEffect(() => {
    if (visible === true) {
      setIsLoading(false);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title={`${contact ? 'Modifier' : 'Ajouter'} un contact`}
      okText={contact ? 'Modifier' : 'Ajouter'}
      canceltext="Annuler"
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsLoading(true);
            form.resetFields();
            onSubmit(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      okButtonProps={{ loading: isLoading }}
    >
      <ContactForm form={form} values={contact} />
    </Modal>
  );
}

const deleteContactModal = function (contact, onSubmit) {
  confirm({
    title: 'Supprimer un contact',
    icon: <ExclamationCircleOutlined />,
    content: (
      <div>
        Êtes-vous sûr de vouloir supprimer <strong>{contact.email}</strong> du carnet d&apos;adresses ?
      </div>
    ),
    okText: 'Supprimer',
    okType: 'danger',
    onOk: onSubmit,
  });
};

export default function ({ selectionMode, selectedRows, onChange }) {
  const [visible, setVisible] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [selectedContact, setSelectedContact] = useState(null);

  const user = useSelector((state) => state.user, shallowEqual);
  const contacts = useSelector((state) => state.contacts, shallowEqual);

  const dispatch = useDispatch();

  // Get contacts
  const db = firebase.firestore();

  // Search
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder="Recherche"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={confirm}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Chercher
            </Button>
            <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
              Réinitialiser
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : ''),
    onFilterDropdownVisibleChange(v) {
      if (v && this && this.searchInput) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });

  useEffect(() => {
    user
      .company
      .collection('contacts')
      .get()
      .then((snapshot) => {
        setLoadingTable(false);

        dispatch({
          type: 'UPDATE_CONTACTS',
          payload: snapshot.docs.map((contact) => ({ ...contact.data(), key: contact.id })),
        });
      });
  }, []);

  const columns = [
    {
      title: 'Nom',
      dataIndex: 'last_name',
      sorter: sorterFn('last_name'),
      ...getColumnSearchProps('last_name'),
    },
    {
      title: 'Prénom',
      dataIndex: 'first_name',
      sorter: sorterFn('first_name'),
      ...getColumnSearchProps('first_name'),
    },
    {
      title: 'Adresse',
      key: 'address',
      render: (text, record) => (
        <>
          <div>{record.address_line_1}</div>
          {record.address_line_2 && <div>{record.address_line_2}</div>}
          <div>{record.city}, {record.postal_code}</div>
        </>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      // sorter: sorterFn('type'),
      render: (text) => (text === 'professional' ? 'Professionnel' : 'Particulier'),
      filters: [
        {
          text: 'Particulier',
          value: 'individual',
        },
        {
          text: 'Professionnel',
          value: 'professional',
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.type === value,
    },
    {
      title: 'Entreprise',
      dataIndex: 'company',
      sorter: sorterFn('company'),
      ...getColumnSearchProps('company'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: sorterFn('email'),
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Téléphone',
      dataIndex: 'phone',
      // sorter: sorterFn('phone'),
      ...getColumnSearchProps('phone'),
    },
  ];

  if (!selectionMode) {
    columns.push({
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="small">
          <Button
            onClick={() => {
              setSelectedContact(record);
              setVisible(true);
            }}
            type="primary"
            title="Modifier"
            size="small"
            shape="circle"
            icon={<EditOutlined />}
          />
          <Button
            onClick={() => {
              deleteContactModal(record, () => {
                setLoadingTable(true);

                // Clone & Mutate
                const contactsClone = _.clone(contacts.contacts);
                _.remove(contactsClone, { key: record.key });
                dispatch({ type: 'UPDATE_CONTACTS', payload: contactsClone });

                user
                  .company
                  .collection('contacts')
                  .doc(record.key)
                  .delete()
                  .then(() => {
                    setLoadingTable(false);
                  });
              });
            }}
            type="danger"
            title="Supprimer"
            size="small"
            shape="circle"
            icon={<DeleteOutlined />}
          />
        </Space>
      ),
    });
  }

  return (
    <>
      {!selectionMode && <h1>Carnet d&apos;adresses</h1>}

      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            onClick={() => {
              setSelectedContact(null);
              setVisible(true);
            }}
          >
            Ajouter un contact
          </Button>
        </Col>
      </Row>

      <Table
        bordered
        style={{ marginTop: 16 }}
        columns={columns}
        dataSource={contacts.contacts}
        size="small"
        scroll={{ x: true }}
        loading={loadingTable}
        rowSelection={
          selectionMode
            ? {
              selectedRowKeys: selectedRows,
              onChange,
            }
            : null
        }
      />

      <ContactModal
        visible={visible}
        contact={selectedContact}
        onCancel={() => { setVisible(false); }}
        onSubmit={(values) => {
          const dbCollection = user.company.collection('contacts');

          if (selectedContact) {
            // Clone & Mutate
            const contactsClone = _.clone(contacts.contacts);
            _.remove(contactsClone, { key: selectedContact.key });
            dispatch({ type: 'UPDATE_CONTACTS', payload: [...contactsClone, { ...selectedContact, ...values }] });

            dbCollection
              .doc(selectedContact.key)
              .set({ ...values }, { merge: true })
              .then(() => { setVisible(false); });
          } else {
            dispatch({ type: 'ADD_CONTACT', payload: { ...values, key: _.uniqueId() } });

            dbCollection.add({ ...values }).then(() => { setVisible(false); });
          }
        }}
      />
    </>
  );
}
