import React, { useEffect, useState } from 'react';
import { Button, Col, Descriptions, Form, Input, message, Modal, Row } from 'antd';

// Firebase
import * as firebase from 'firebase/app';
import 'firebase/auth';

import { shallowEqual, useSelector } from 'react-redux';

function CompanyForm({ values, form }) {
  return (
      <Form layout="vertical" form={form} initialValues={values}>
        <Form.Item
          name="name"
          label="Nom"
          rules={[{ required: true, message: 'Veuillez entrer un nom' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email de contact"
          rules={[{ required: true, message: 'Veuillez entrer un email' }, { type: 'email', message: 'Veuillez entrer un email valide' }]}
        >
          <Input placeholder="Ex: entreprise@email.com" />
        </Form.Item>

        <Form.Item
          name="address_line_1"
          label="Adresse ligne 1"
          rules={[{ required: true, message: 'Veuillez entrer une adresse valide' }]}
        >
          <Input placeholder="Adresse postale, boîte postale..." />
        </Form.Item>

        <Form.Item
          name="address_line_2"
          label="Adresse ligne 2"
          rules={[{ required: false }]}
        >
          <Input placeholder="Appartement, suite, unité, immeuble, étage, etc." />
        </Form.Item>

        <Form.Item
          name="postal_code"
          label="Code postal"
          rules={[{ required: true, message: 'Veuillez entrer un code postal' }]}
        >
          <Input placeholder="Ex: 75008" />
        </Form.Item>

        <Form.Item
          name="city"
          label="Ville"
          rules={[{ required: true, message: 'Veuillez entrer une ville' }]}
        >
          <Input placeholder="Ex: Paris" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Téléphone"
        >
          <Input placeholder="Ex: 0123456789" />
        </Form.Item>
      </Form>
    );
}

function EditModal({ visible, company, onSubmit, onCancel, isFormLoading, setIsFormLoading }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible === true) {
      setIsFormLoading(false);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title="Modifier les informations"
      okText="Modifier"
      canceltext="Annuler"
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsFormLoading(true);
            onSubmit(values, form.resetFields);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      okButtonProps={{ loading: isFormLoading }}
    >
      <CompanyForm form={form} values={company} />
    </Modal>
  );
}

export default function () {
  const db = firebase.firestore();
  const authUser = useSelector((state) => state.user, shallowEqual);
  const [company, setCompany] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  useEffect(() => {
    db
      .collection('users')
      .doc(authUser.uid)
      .get()
      .then((snapshot) => {
        const userData = snapshot.data();

        if (userData.company) {
          userData.company.get().then((doc) => {
            const companyData = doc.data();
            setCompany(companyData);
          });
        }
      });
  }, []);

  return (
    <>
      <h1>Mon entreprise</h1>

      {/*
        // TODO: Disabling for now
        authUser.is_company_admin && (
          <Row>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                Modifier les informations
              </Button>
            </Col>
          </Row>
        )
      */}

      { company
        && (
          <Descriptions bordered column={1} style={{ marginTop: 16 }}>
            <Descriptions.Item label="Nom">{company.name}</Descriptions.Item>
            <Descriptions.Item label="Téléphone">{company.phone}</Descriptions.Item>
            <Descriptions.Item label="Email de contact">{company.email}</Descriptions.Item>
            <Descriptions.Item label="Adresse">
              {company.address_line_1}<br />
              {company.address_line_2}
            </Descriptions.Item>
            <Descriptions.Item label="Ville">{company.city}</Descriptions.Item>
            <Descriptions.Item label="Code postal">{company.postal_code}</Descriptions.Item>
          </Descriptions>
        )}

      {/* Modal */}
      <EditModal
        visible={modalVisible}
        company={company}
        onCancel={() => { setModalVisible(false); }}
        isFormLoading={isFormLoading}
        setIsFormLoading={setIsFormLoading}
        onSubmit={(values, resetFields) => {
          db
            .collection('companies')
            .doc(authUser.company.id)
            .set(
              { ...values },
              { merge: true },
            )
            .then(() => {
              resetFields();
              setModalVisible(false);
              message.success('Modifications enregistrées');
              setCompany(values);
            })
            .catch((e) => {
              setIsFormLoading(false);
              message.error('Une erreur est survenue. Veuillez réessayer.');
              console.error(e);
            });
        }}
      />
    </>
  );
}
