import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row, message, Select, Switch } from "antd";

// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";

import _ from "lodash";

import CompaniesTable from "./CompaniesTable";
import moment from "moment";

const { Search } = Input;

function UserForm({ form, companies }) {
  return (
    <Form layout="vertical" form={form}>
      <Form.Item
        name="company"
        label="Compte / Entreprise"
        rules={[{ required: true, message: "Veuillez selectionner un compte" }]}
      >
        <Select
          showSearch
          placeholder="Sélectionnez un compte"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {_.sortBy(companies, "name").map((company) => (
            <Select.Option value={company.id} key={company.key}>
              {company.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="last_name"
        label="Nom"
        rules={[{ required: true, message: "Veuillez entrer un nom" }]}
      >
        <Input placeholder="Ex: Dupont" />
      </Form.Item>

      <Form.Item
        name="first_name"
        label="Prénom"
        rules={[{ required: true, message: "Veuillez entrer un prénom" }]}
      >
        <Input placeholder="Ex: Jean" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Adresse email"
        rules={[
          { required: true, message: "Veuillez entrer un email" },
          { type: "email", message: "Veuillez entrer un email valide" },
        ]}
      >
        <Input placeholder="Ex: jean.dupont@gmail.com" />
      </Form.Item>
    </Form>
  );
}

function UserModal({ visible, onSubmit, onCancel, isFormLoading, setIsFormLoading, companies }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible === true) {
      setIsFormLoading(false);
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title="Inviter un nouvel utilisateur"
      okText="Inviter"
      canceltext="Annuler"
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            setIsFormLoading(true);
            onSubmit(values, form.resetFields);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ loading: isFormLoading }}
    >
      <UserForm form={form} companies={companies} />
    </Modal>
  );
}

function CompanyModal({ visible, onSubmit, onCancel, isFormLoading, setIsFormLoading, values }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible === true) {
      setIsFormLoading(false);
    }
  }, [visible]);

  useEffect(() => {
    form.resetFields();
  }, [form, values]);

  useEffect(() => {
    console.log("SELECTED COMPANY => ", values);
  }, [values]);

  return (
    <Modal
      visible={visible}
      title={values ? "Modifier un groupe" : "Créer un groupe"}
      okText={values ? "Modifier" : "Créer"}
      canceltext="Annuler"
      maskClosable={false}
      onCancel={() => {
        onCancel();
      }}
      onOk={() => {
        form
          .validateFields()
          .then((formValues) => {
            setIsFormLoading(true);
            onSubmit(formValues, form.resetFields);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
      okButtonProps={{ loading: isFormLoading }}
    >
      <Form layout="vertical" form={form} initialValues={values}>
        <Form.Item
          name="name"
          label="Nom"
          rules={[{ required: true, message: "Veuillez entrer un nom" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email de contact"
          rules={[
            { required: true, message: "Veuillez entrer un email" },
            { type: "email", message: "Veuillez entrer un email valide" },
          ]}
        >
          <Input placeholder="Ex: entreprise@email.com" />
        </Form.Item>

        <Form.Item
          name="address_line_1"
          label="Adresse ligne 1"
          rules={[{ required: true, message: "Veuillez entrer une adresse valide" }]}
        >
          <Input placeholder="Adresse postale, boîte postale..." />
        </Form.Item>

        <Form.Item name="address_line_2" label="Adresse ligne 2" rules={[{ required: false }]}>
          <Input placeholder="Appartement, suite, unité, immeuble, étage, etc." />
        </Form.Item>

        <Form.Item
          name="postal_code"
          label="Code postal"
          rules={[{ required: true, message: "Veuillez entrer un code postal" }]}
        >
          <Input placeholder="Ex: 75008" />
        </Form.Item>

        <Form.Item
          name="city"
          label="Ville"
          rules={[{ required: true, message: "Veuillez entrer une ville" }]}
        >
          <Input placeholder="Ex: Paris" />
        </Form.Item>

        <Form.Item name="phone" label="Téléphone">
          <Input placeholder="Ex: 0123456789" />
        </Form.Item>
        <Form.Item name="force2FA" label="Forcer Solidoc Authenticator" valuePropName="checked">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default function () {
  const db = firebase.firestore();
  const inviteUser = firebase.app().functions("europe-west1").httpsCallable("inviteUser");

  const [companies, setCompanies] = useState([]);
  const [displayedCompanies, setDisplayedCompanies] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [userModalVisible, setUserModalVisible] = useState(false);
  const [companyModalVisible, setCompanyModalVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(new Date().getTime());
  const [searchTerms, setSearchTerms] = useState("");
  const [searchArr, setSearchArr] = useState([]);

  useEffect(() => {
    if (searchTerms === "") {
      setDisplayedCompanies([...companies]);
    } else {
      const r = searchArr
        .filter((a) => new RegExp(searchTerms, "ig").test(a.key))
        .map((a) => a.value);

      setDisplayedCompanies(_.filter(companies, (company) => r.indexOf(company.id) !== -1));
    }
  }, [searchTerms, searchArr]);

  useEffect(() => {
    db.collection("users")
      .get()
      .then((usersSnapshot) => {
        const companiesObj = {};
        const search = [];

        if (usersSnapshot.docs.length) {
          usersSnapshot.docs.forEach((userSnap) => {
            const userData = userSnap.data();
            userData.last_invite = userData.last_invite || userData.created_at || moment();
            const user = {
              ...userData,
              company: userData.company.id,
              uid: userSnap.id,
              key: userSnap.id,
              last_invite: userData.isActive
                ? moment()
                : moment((userData.last_invite || userData.created_at).toDate()),
            };

            search.push({
              key: `${userData.email} ${userData.first_name} ${userData.last_name}`,
              value: userData.company.id,
            });

            if (companiesObj[user.company]) {
              companiesObj[user.company].push(user);
            } else {
              companiesObj[user.company] = [user];
            }
          });

          db.collection("companies")
            .get()
            .then((companiesSnapshot) => {
              const r = companiesSnapshot.docs.map((snap) => {
                const company = snap.data();

                search.push({
                  key: `${company.email} ${company.name}`,
                  value: snap.id,
                });

                return {
                  ...company,
                  ref: snap.ref,
                  users: companiesObj[snap.id],
                  key: snap.id,
                  id: snap.id,
                };
              });

              setCompanies(r);
              setDisplayedCompanies(r);

              setSearchArr(search);
            });
        }
      });
  }, [lastUpdate]);

  return (
    <>
      <h1>Comptes utilisateurs</h1>

      <Row>
        <Col span={24} style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <Search
              placeholder="Recherche"
              onSearch={(value) => {
                setSearchTerms(value);
              }}
              onChange={(e) => {
                if (e.target.value === "") setSearchTerms("");
              }}
              style={{ paddingRight: 32, maxWidth: 500 }}
              enterButton
              allowClear
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                setSelectedCompany(null);
                setCompanyModalVisible(true);
              }}
              style={{ marginRight: 8 }}
            >
              Créer un groupe
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setUserModalVisible(true);
              }}
            >
              Inviter un utilisateur
            </Button>
          </div>
        </Col>
      </Row>

      <CompaniesTable
        searchTerms={searchTerms}
        setLastUpdate={setLastUpdate}
        allCompanies={companies}
        companies={displayedCompanies}
        setSelectedCompany={setSelectedCompany}
        setCompanyModalVisible={setCompanyModalVisible}
      />

      <CompanyModal
        values={selectedCompany}
        visible={companyModalVisible}
        onCancel={() => {
          setCompanyModalVisible(false);
        }}
        isFormLoading={isFormLoading}
        setIsFormLoading={setIsFormLoading}
        onSubmit={(values, resetFields) => {
          console.log({ selectedCompany });
          if (selectedCompany) {
            console.log("Editing account...", values);
            db.collection("companies")
              .doc(selectedCompany.id)
              .set({ ...values }, { merge: true })
              .then(() => {
                console.log({ values });
                const clonedUsers = companies;
                clonedUsers.splice(_.findIndex(companies, { id: selectedCompany.id }), 1, {
                  ...selectedCompany,
                  ...values,
                });

                setCompanies([...clonedUsers]);
                setDisplayedCompanies([...clonedUsers]);

                resetFields();
                setCompanyModalVisible(false);
                message.success("Modifications enregistrées");
              })
              .catch((e) => {
                setIsFormLoading(false);
                message.error("Une erreur est survenue. Veuillez réessayer.");
                console.error(e);
              });
          } else {
            console.log("Creating account...", values);
            db.collection("companies")
              .add({ ...values })
              .then(() => {
                setLastUpdate(new Date().getTime());
                resetFields();
                setCompanyModalVisible(false);
                message.success("Compte ajouté");
              })
              .catch((e) => {
                setIsFormLoading(false);
                message.error("Une erreur est survenue. Veuillez réessayer.");
                console.error(e);
              });
          }
        }}
      />

      <UserModal
        companies={companies}
        visible={userModalVisible}
        onCancel={() => {
          setUserModalVisible(false);
        }}
        isFormLoading={isFormLoading}
        setIsFormLoading={setIsFormLoading}
        onSubmit={(values, resetFields) => {
          console.log("Inviting user...", values);
          inviteUser(values).then((response) => {
            const { data } = response;
            console.log("Response", data);

            if (data.error) {
              const errorObj = data.data && data.data.errorInfo;

              const displayMessage =
                errorObj && errorObj.code === "auth/email-already-exists"
                  ? "Cette addresse email est déjà utilisée."
                  : "Une erreur est survenue. Veuillez réessayer.";

              setIsFormLoading(false);
              message.error(displayMessage);
            } else {
              resetFields();
              setUserModalVisible(false);
              setLastUpdate(new Date().getTime());
              message.success("Invitation envoyée");
            }
          });
        }}
      />
    </>
  );
}
