import { Button } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import Activate2FAModal from "../Settings/Account/Activate2FAModal";
import { Redirect, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

const SolidocSecure = () => {
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const history = useHistory();
  const currentUser = useSelector((state) => state.user, shallowEqual);

  if (currentUser && currentUser.has2FAActivated) {
    return <Redirect to="" />;
  }

  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      <Title>Connexion Solidoc Authenticator requise</Title>
      <div style={{ opacity: 0.75, marginBottom: "1rem" }}>
        Vous devez activer Solidoc Authenticator pour continuer d’utiliser notre solution solidoc.fr
      </div>
      <div>
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setAuthModalVisible(true);
          }}
        >
          Activer Solidoc Authenticator
        </Button>
      </div>
      <Activate2FAModal
        visible={authModalVisible}
        onCancel={() => {
          setAuthModalVisible(false);
        }}
        onEnrollment={() => {
          setAuthModalVisible(false);
          history.push("/");
        }}
      />
    </div>
  );
};

export default SolidocSecure;
