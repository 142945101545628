import React, { useEffect, useState } from "react";
import { Provider, shallowEqual, useDispatch, useSelector } from "react-redux";
import "moment/locale/fr";
import moment from "moment";
import { BrowserRouter as Router, Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { ConfigProvider, Layout, Spin } from "antd";
import frFR from "antd/es/locale/fr_FR";

// Loading icon
import { LoadingOutlined } from "@ant-design/icons";

// Firebase
import * as firebase from "firebase/app";
import "firebase/auth";

// Redux store
import store from "../modules/store";

// Layout components
import DisplayMessage from "./DisplayMessage";
import Login from "./Login";
import Reset from "./Reset";
import SideMenu from "./layout/SideMenu";
import Header from "./layout/Header";
import Body from "./layout/Body";
import Footer from "./layout/Footer";

// Set moment locale
moment.locale("fr");

function RedirectIfLogged() {
  const user = useSelector((state) => state.user, shallowEqual);

  return user ? <Redirect to="/" /> : null;
}

function RedirectIfNotLogged() {
  const user = useSelector((state) => state.user, shallowEqual);
  const generalLoading = useSelector((state) => state.generalLoading, shallowEqual);

  return user ? (
    <Spin
      tip={generalLoading.message}
      spinning={generalLoading.isLoading}
      wrapperClassName="general-loading"
    >
      <Layout id="main-layout">
        {/* Left-side menu */}
        <SideMenu />
        {/* Main body layout */}
        <Layout>
          {/* Top header */}
          <Header />
          {/* Content container */}
          <Body />
          {/* Footer */}
          <Footer />
        </Layout>
      </Layout>
    </Spin>
  ) : (
    <Redirect to="/login" />
  );
}

function SessionCheck() {
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user, shallowEqual);
  const isSolidocSecurePage = useRouteMatch("/solidoc-secure");

  // Get contacts
  const db = firebase.firestore();

  // Check if user is signed in
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userDoc = db.collection("users").doc(user.uid);

        userDoc.get().then((userSnapshot) => {
          const userData = userSnapshot.data();
          console.log(userData);

          userData.company.get().then((companySnapshot) => {
            const companyData = companySnapshot.data();
            console.log("Company", companyData);
            const data = {
              type: "LOGIN_USER",
              payload: {
                ...userData,
                companyName: companyData.name,
                companyData: {
                  ...companyData,
                  id: companySnapshot.id,
                },
                uid: user.uid,
                userRef: userSnapshot.ref,
              },
            };

            const payload = {
              last_connected: firebase.firestore.FieldValue.serverTimestamp(),
            };

            if (!userData.isActive) {
              payload.isActive = true;
            }

            userDoc.set(payload, { merge: true }).then(() => {
              dispatch(data);
              setChecked(true);
            });
          });
        });
      } else {
        dispatch({ type: "SIGN_OUT" });
        setChecked(true);
      }
    });
  }, []);

  console.log({ currentUser });
  if (
    !isSolidocSecurePage &&
    currentUser &&
    currentUser.companyData.force2FA &&
    !currentUser.has2FAActivated
  ) {
    return <Redirect to="/solidoc-secure" />;
  }

  return checked ? (
    <Switch>
      <Route exact path="/login">
        <RedirectIfLogged />
        <Login />
      </Route>
      <Route exact path="/password_reset">
        <Reset />
      </Route>
      <Route path="/recommande/:id/:token/:action">
        <DisplayMessage />
      </Route>
      <Route>
        <RedirectIfNotLogged />
      </Route>
    </Switch>
  ) : (
    <div id="app-loader">
      <LoadingOutlined style={{ fontSize: 50 }} />
    </div>
  );
}

export default function () {
  return (
    <Router>
      <Provider store={store}>
        <ConfigProvider locale={frFR}>
          <SessionCheck />
        </ConfigProvider>
      </Provider>
    </Router>
  );
}
